import { useMemo } from 'react';
import { getUserLevels } from '../../../enums/userLevel';

export const useUserTypeOptions = (
    roles: string[],
    orgId?: number,
    siteId?: number,
    dealerId?: number
) => {
    let userType: 'site' | 'org' | 'dealer' | 'global';
    if (siteId) {
        userType = 'site';
    } else if (orgId) {
        userType = 'org';
    } else if (dealerId) {
        userType = 'dealer';
    } else {
        userType = 'global';
    }
    const userLevelOptions = useMemo(
        () =>
            getUserLevels(roles, userType).map((l) => ({ label: l as string, value: l as string })),
        [userType, roles]
    );
    return userLevelOptions;
};
