import { AxiosApi, ApiResult } from './axiosApi';
import { LogFilterRequest } from './requestObjects/eventLogRequest';
import { Event, AcknowledgeSiteEventsDto } from '../dtos/event';
import { ExportResult } from '../dtos/export';

export class EventApi extends AxiosApi<Event> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, 'events', logout, enqueueSnackbar);
    }

    public getEvents = (request: LogFilterRequest, abortController?: AbortController) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            abortController,
        }) as Promise<ApiResult<{ numberOfPages: number; events: Event[] }>>;

    public getEventsForDealer = (
        dealerId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `dealers/${dealerId}/${this._resourceName}`,
            abortController,
        }) as Promise<ApiResult<{ numberOfPages: number; events: Event[] }>>;

    public getEventsForOrg = (
        orgId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `organizations/${orgId}/${this._resourceName}`,
            abortController,
        }) as Promise<ApiResult<{ numberOfPages: number; events: Event[] }>>;

    public getEventsForSite = (
        orgId: number,
        siteId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `organizations/${orgId}/sites/${siteId}/${this._resourceName}`,
            abortController,
        }) as Promise<ApiResult<{ numberOfPages: number; events: Event[] }>>;

    public getEventReport = (request: LogFilterRequest, abortController?: AbortController) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `eventreport`,
            abortController,
        }) as Promise<ApiResult<ExportResult>>;

    public getEventReportForDealer = (
        dealerId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `dealers/${dealerId}/eventreport`,
            abortController,
        }) as Promise<ApiResult<ExportResult>>;

    public getEventReportForOrganization = (
        orgId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `organizations/${orgId}/eventreport`,
            abortController,
        }) as Promise<ApiResult<ExportResult>>;

    public getEventReportForSite = (
        orgId: number,
        siteId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `organizations/${orgId}/sites/${siteId}/eventreport`,
            abortController,
        }) as Promise<ApiResult<ExportResult>>;

    public getAlarms = (request: LogFilterRequest, abortController?: AbortController) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: 'alarms',
            abortController,
        }) as Promise<ApiResult<{ numberOfPages: number; events: Event[] }>>;

    public updateAlarmNote = (
        request: { id: number, notes: string },
        abortController?: AbortController,
    ) =>
        this.genericRequest({
            method: 'PUT',
            data: request,
            overridePath: 'alarms/updatenotes',
            abortController,
        }) as Promise<ApiResult<unknown>>;

    public getAlarmsForDealer = (
        dealerId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `dealers/${dealerId}/alarms`,
            abortController,
        }) as Promise<ApiResult<{ numberOfPages: number; events: Event[] }>>;

    public getAlarmsForOrg = (
        orgId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `organizations/${orgId}/alarms`,
            abortController,
        }) as Promise<ApiResult<{ numberOfPages: number; events: Event[] }>>;

    public getAlarmsForSite = (
        orgId: number,
        siteId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `organizations/${orgId}/sites/${siteId}/alarms`,
            abortController,
        }) as Promise<ApiResult<{ numberOfPages: number; events: Event[] }>>;

    public getAlarmReport = (request: LogFilterRequest, abortController?: AbortController) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `alarmreport`,
            abortController,
        }) as Promise<ApiResult<ExportResult>>;

    public getAlarmReportForDealer = (
        dealerId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `dealers/${dealerId}/alarmreport`,
            abortController,
        }) as Promise<ApiResult<ExportResult>>;

    public getAlarmReportForOrganization = (
        orgId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `organizations/${orgId}/alarmreport`,
            abortController,
        }) as Promise<ApiResult<ExportResult>>;

    public getAlarmReportForSite = (
        orgId: number,
        siteId: number,
        request: LogFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `organizations/${orgId}/sites/${siteId}/alarmreport`,
            abortController,
        }) as Promise<ApiResult<ExportResult>>;

    public acknowledgeEvent = (event: Event) =>
        this.genericRequest({
            method: 'PUT',
            data: event,
            overridePath: 'acknowledge',
        }) as Promise<ApiResult<any>>;

    public acknowledgeEventsForSite = (siteEventsDto: AcknowledgeSiteEventsDto) =>
        this.genericRequest({
            method: 'PUT',
            data: siteEventsDto,
            overridePath: 'acknowledgeall',
        }) as Promise<ApiResult<any>>;
}
