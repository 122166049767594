import { FoundSiteDto } from "../../dtos/search";

export function isArrayOfFoundSites(obj: unknown): obj is FoundSiteDto[] {
    if (!Array.isArray(obj)) return false;
    if (obj.length === 0) return true;
    if (obj.length > 0) {
        const item = obj[0];
        return (
            item &&
            "uniqueSiteId" in item &&
            "siteName" in item &&
            "organizationName" in item &&
            "dealerName" in item &&
            "siteId" in item &&
            "organizationId" in item &&
            "dealerId" in item
        );
    }
    return false;
}

export const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    let timeoutId: NodeJS.Timeout;

    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
        const context = this as ThisParameterType<F>;

        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
};
