import { EmployeeForView } from '../dtos/employee';
import { AxiosApi, ApiResult } from './axiosApi';

export class AuthApi extends AxiosApi<{}> {
    constructor(baseUri: string, enqueueSnackbar?: any) {
        super(baseUri, 'auth', undefined, enqueueSnackbar);
    }

    public getToken = (
        email: string,
        password: string
    ): Promise<
        ApiResult<{
            sessionId: string;
            username: string;
            accessToken: string;
            refreshToken: string;
            refreshTokenExpiration: string;
        }>
    > =>
        this.genericRequest({
            data: { email, password },
            extraPath: 'token',
            method: 'POST',
            skipTokenRefresh: true,
        });

    public refreshToken = (
        accessToken: string,
        refreshToken: string
    ): Promise<
        ApiResult<{
            accessToken: string;
            refreshToken: string;
            refreshTokenExpiration: string;
        }>
    > =>
        this.genericRequest({
            data: { accessToken, refreshToken },
            extraPath: 'refreshToken',
            method: 'POST',
            skipTokenRefresh: true,
        });

    public sendPasswordResetEmail = (email: string) =>
        this.genericRequest({
            data: { email },
            method: 'POST',
            extraPath: 'sendPasswordResetEmail',
            skipTokenRefresh: true,
        });

    public resetPassword = (email: string, code: string, newPassword: string) =>
        this.genericRequest({
            method: 'POST',
            extraPath: 'resetPassword',
            data: { email, code, newPassword },
        });

    public changePassword = (email: string, password: string, newPassword: string) =>
        this.genericRequest({
            data: { email, password, newPassword },
            method: 'POST',
            extraPath: 'changePassword',
        });

    public getUserInfo = (): Promise<ApiResult<EmployeeForView>> =>
        this.genericRequest({
            method: 'GET',
            extraPath: 'userInfo',
        });

    public regenerateUserPinCode = (): Promise<ApiResult<EmployeeForView>> =>
        this.genericRequest({
            method: 'POST',
            extraPath: 'regeneratePinCode',
        });
}
