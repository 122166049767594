import { useCallback } from "react";
import useRouter from "use-react-router";

export function useSiteChangeCallback<TRouteState>(uriResource: string, stateToSave?: TRouteState) {
    const { history } = useRouter();

    return useCallback(
        (dealerId?: number | null, orgId?: number | null, siteId?: number | null) => {
            let newUrl = "/";
            if (dealerId) {
                newUrl += `dealers/${dealerId}/`;
            }
            if (orgId) {
                newUrl += `organizations/${orgId}/`;
            }
            if (siteId) {
                newUrl += `sites/${siteId}/`;
            }
            newUrl += uriResource;
            history.push(newUrl, stateToSave);
        },
        [history, uriResource, stateToSave]
    );
}
