import { Button, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { CheckCircleRounded } from "@material-ui/icons";
import React from "react";

import { DialogContentsError } from "./DialogContentsError";
import { PinCodePreview } from "../../PinCodePreview";

const useStyles = makeStyles(() => ({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
    },
    pinContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
    },
}));


interface Props {
    onClose: () => void;
    code: string | undefined;
}

export function DialogContentsConnectionEstablished({
    onClose,
    code,
}: Props) {
    const classes = useStyles();

    if (!code) {
        return (
            <DialogContentsError
                onClose={onClose}
                error="Something went wrong"
            />
        );
    }

    return (
        <>
            <DialogTitle style={{ textAlign: "center" }}>
                Connection Established
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <CheckCircleRounded fontSize="large" style={{ color: "green" }} />

                <div className={classes.pinContainer}>
                    PIN:
                    <PinCodePreview code={code} />
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
}