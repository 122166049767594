import { createContext } from 'react';
import { SearchApi } from '../rest/searchApi';
import { BoxApi } from '../rest/boxApi';
import { CustomerApi } from '../rest/customerApi';
import { EmployeeApi } from '../rest/employeeApi';
import { LeaseApi } from '../rest/leaseApi';
import { EventApi } from '../rest/eventApi';
import { OrgApi } from '../rest/orgApi';
import { SiteApi } from '../rest/siteApi';
import { BASE_SERVER_URL } from '../constants';
import { DealerApi } from '../rest/dealerApi';
import { ImportApi } from '../rest/importApi';
import { AuthApi } from '../rest/authApi';
import { ScheduledJobsApi } from '../rest/scheduledJobsApi';

export const ApiContext = createContext<{
    searchApi: SearchApi;
    boxApi: BoxApi;
    customerApi: CustomerApi;
    dealerApi: DealerApi;
    employeeApi: EmployeeApi;
    leaseApi: LeaseApi;
    eventApi: EventApi;
    orgApi: OrgApi;
    siteApi: SiteApi;
    importApi: ImportApi;
    authApi: AuthApi;
    scheduledJobsApi: ScheduledJobsApi;
}>({
    searchApi: new SearchApi(BASE_SERVER_URL),
    boxApi: new BoxApi(BASE_SERVER_URL),
    customerApi: new CustomerApi(BASE_SERVER_URL),
    employeeApi: new EmployeeApi(BASE_SERVER_URL),
    eventApi: new EventApi(BASE_SERVER_URL),
    leaseApi: new LeaseApi(BASE_SERVER_URL),
    orgApi: new OrgApi(BASE_SERVER_URL),
    siteApi: new SiteApi(BASE_SERVER_URL),
    dealerApi: new DealerApi(BASE_SERVER_URL),
    importApi: new ImportApi(BASE_SERVER_URL),
    authApi: new AuthApi(BASE_SERVER_URL),
    scheduledJobsApi: new ScheduledJobsApi(BASE_SERVER_URL),
});

export const buildApiContextValue = (logout: () => void, enqueueSnackbar: any) => {
    const serverUri = BASE_SERVER_URL;
    const searchApi = new SearchApi(serverUri, logout, enqueueSnackbar);
    const boxApi = new BoxApi(serverUri, logout, enqueueSnackbar);
    const customerApi = new CustomerApi(serverUri, logout, enqueueSnackbar);
    const employeeApi = new EmployeeApi(serverUri, logout, enqueueSnackbar);
    const leaseApi = new LeaseApi(serverUri, logout, enqueueSnackbar);
    const eventApi = new EventApi(serverUri, logout, enqueueSnackbar);
    const orgApi = new OrgApi(serverUri, logout, enqueueSnackbar);
    const siteApi = new SiteApi(serverUri, logout, enqueueSnackbar);
    const dealerApi = new DealerApi(serverUri, logout, enqueueSnackbar);
    const importApi = new ImportApi(serverUri, enqueueSnackbar);
    const authApi = new AuthApi(serverUri, logout);
    const scheduledJobsApi = new ScheduledJobsApi(serverUri, logout, enqueueSnackbar);
    return {
        searchApi,
        boxApi,
        customerApi,
        employeeApi,
        leaseApi,
        eventApi,
        orgApi,
        siteApi,
        dealerApi,
        importApi,
        authApi,
        scheduledJobsApi,
    };
};
