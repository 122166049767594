import { Dialog } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { ProcessState } from "../../../enums/ProcessState";
import SignalRContext from "../../../signalr/context";
import {
    DialogContentsError,
    DialogContentsConnectionEstablished,
    DialogContentsWaitingResponse,
} from "../SiteCommandProcessingDialog"

import { DialogContentsSendingRequest } from "./DialogContentsSendingRequest";

interface Props {
    user: { id: number; activeLeaseSiteIds?: number[] } | undefined;
    orgId: number | undefined;
    siteId: number | undefined;
    onClose: () => void;
}

export function EnrollBiometricsDialog({ user, orgId, siteId, onClose }: Props) {
    const { enrollmentAcceptedData } = useContext(SignalRContext);
    const [enrollmentState, setEnrollmentState] = useState<ProcessState>(ProcessState.SendingRequest);
    const [enrollmentRequestReference, setEnrollmentRequestReference] = useState<string>();
    const [enrollmentCode, setEnrollmentCode] = useState<string>();

    return (
        <Dialog
            open={Boolean(user)}
            PaperProps={{ style: { minWidth: "300px", minHeight: "220px" } }}
        >
            {enrollmentState === ProcessState.SendingRequest && (
                <DialogContentsSendingRequest
                    user={user}
                    orgId={orgId}
                    siteId={siteId}
                    onClose={onClose}
                    onEnrollRequestSuccess={(requestReference) => {
                        setEnrollmentState(ProcessState.WaitingResponse);
                        setEnrollmentRequestReference(requestReference);
                    }}
                    onCodeReceived={(code) => {
                        setEnrollmentState(ProcessState.ConnectionEstablished);
                        setEnrollmentCode(code);
                    }}
                    onEnrollRequestFail={() => setEnrollmentState(ProcessState.ConnectionFailure)}
                />
            )}

            {enrollmentState === ProcessState.WaitingResponse && (
                <DialogContentsWaitingResponse
                    onClose={onClose}
                    onAcceptSuccess={(code) => {
                        setEnrollmentState(ProcessState.ConnectionEstablished);
                        setEnrollmentCode(code);
                    }}
                    onAcceptFail={() => setEnrollmentState(ProcessState.ConnectionFailure)}
                    requestReference={enrollmentRequestReference}
                    acceptedData={enrollmentAcceptedData}
                />
            )}

            {enrollmentState === ProcessState.ConnectionEstablished && (
                <DialogContentsConnectionEstablished
                    onClose={onClose}
                    code={enrollmentCode}
                />
            )}

            {enrollmentState === ProcessState.ConnectionFailure && (
                <DialogContentsError
                    onClose={onClose}
                    error="Failed to complete request: The site is currently offline. Please try again later."
                />
            )}
        </Dialog>
    );
}
