import { Dialog } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { ManualOverrideRequestData } from "../../../../dtos/manualOverride";
import { ProcessState } from "../../../../enums/ProcessState";
import SignalRContext from "../../../../signalr/context";
import {
    DialogContentsError, DialogContentsConnectionEstablished, DialogContentsWaitingResponse,
} from "../../../shared/SiteCommandProcessingDialog"

import { DialogContentsSendingRequest } from "./DialogContentsSendingRequest";


interface Props {
    requestData: ManualOverrideRequestData | undefined;
    onClose: () => void;
}

export function ManualOverrideDialog({ requestData, onClose }: Props) {
    const { manualOverrideAcceptedData } = useContext(SignalRContext);
    const [processState, setProcessState] = useState<ProcessState>(ProcessState.SendingRequest);
    const [requestReference, setRequestReference] = useState<string>();
    const [processCode, setProcessCode] = useState<string>();

    return (
        <Dialog
            open={Boolean(requestData)}
            PaperProps={{ style: { minWidth: "300px", minHeight: "220px"}}}
        >
            {processState === ProcessState.SendingRequest && (
                <DialogContentsSendingRequest
                    requestData={requestData}
                    onClose={onClose}
                    onManualOverrideRequestSuccess={(requestReference) => {
                        setProcessState(ProcessState.WaitingResponse);
                        setRequestReference(requestReference);
                    }}
                    onCodeReceived={(code) => {
                        setProcessState(ProcessState.ConnectionEstablished);
                        setProcessCode(code);
                    }}
                    onManualOverrideRequestFail={() => setProcessState(ProcessState.ConnectionFailure)}
                />
            )}

            {processState === ProcessState.WaitingResponse && (
                <DialogContentsWaitingResponse
                    onClose={onClose}
                    onAcceptSuccess={(code: string) => {
                        setProcessState(ProcessState.ConnectionEstablished);
                        setProcessCode(code);
                    }}
                    onAcceptFail={() => setProcessState(ProcessState.ConnectionFailure)}
                    requestReference={requestReference}
                    acceptedData={manualOverrideAcceptedData}
                />
            )}

            {processState === ProcessState.ConnectionEstablished && (
                <DialogContentsConnectionEstablished
                    onClose={onClose}
                    code={processCode}
                />
            )}

            {processState === ProcessState.ConnectionFailure && (
                <DialogContentsError
                    onClose={onClose}
                    error="Failed to complete request: The site is currently offline. Please try again later."
                />
            )}
        </Dialog>
    );
}
