import { useMemo } from 'react';
import * as linq from 'linq';
import { Customer } from '../../../../dtos/customer';

export const useOwners = (allCustomers: Customer[], ownerIds: number[]) =>
    useMemo(
        () =>
            linq
                .from(allCustomers)
                .join(ownerIds, c => c.id, o => o, c => c)
                .toArray(),
        [ownerIds, allCustomers]
    );

export const useCustomerOptions = (allCustomers: Customer[], owners: Customer[]) =>
    useMemo(
        () =>
            linq
                .from(allCustomers)
                .except(owners, c => c.id)
                .orderBy(c => c.firstName)
                .toArray(),
        [allCustomers, owners]
    );
