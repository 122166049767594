import React, { useEffect } from "react";
import { ValueType } from "react-select/src/types";

import {
    SCHEDULED_JOB_TYPES,
    SCHEDULED_JOB_TYPE_LABELS,
    ScheduledJobType,
} from "../../../../enums/ScheduledJobType";
import { DialogSelect } from "../../../shared/dialogSelect";

interface Option { value: string; label: string; }

interface Props {
    selectedType: ScheduledJobType;
    onTypeChange: (type: ScheduledJobType) => void;
    addedTypes: ScheduledJobType[];
}

export function ScheduledJobTypeSelector({ selectedType, onTypeChange, addedTypes }: Props) {
    const availableTypes = SCHEDULED_JOB_TYPES.filter(it => !addedTypes.includes(it));
    const options = getJobTypeOptions(availableTypes);

    const handleChange = (
        selected: ValueType<Option>
    ) => {
        const option = selected as Option;
        if (!option) return;
        onTypeChange(parseInt(option.value))
    }

    useEffect(() => {
        if (!availableTypes.includes(selectedType) && availableTypes.length > 0) {
            onTypeChange(availableTypes[0]);
        }
    }, [availableTypes, selectedType, onTypeChange]);

    return (
        <DialogSelect
            options={options}
            value={options.find(it => it.value === selectedType.toString())}
            onChange={handleChange}
        />
    );
}


function getJobTypeOptions(types: ScheduledJobType[]): Option[] {
    return types.map(it => ({
        label: SCHEDULED_JOB_TYPE_LABELS[it],
        value: it.toString(),
    }));
}
