import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../../auth/authContext";
import { AjaxResult } from "../../../../enums/ajaxResult";
import { useApi } from "../../../useApi";

export function useUserOrganizationOption() {
    const [option, setOption] = useState<{ label: string; value: number }>();

    const { orgId } = useContext(AuthContext);
    const { orgApi } = useApi();

    useEffect(() => {
        if (!orgId) return;
        let cancel = false;
        orgApi.getById(orgId).then((r) => {
            if (!cancel && r.result === AjaxResult.Success && r.data) {
                setOption({ label: r.data.name, value: r.data.id });
            }
        });
        return () => {
            cancel = true;
        };
    }, [orgId, orgApi]);


    return option;
}
