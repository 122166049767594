import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Customer, CustomerWithBoxes } from '../../../dtos/customer';
import { AjaxResult } from '../../../enums/ajaxResult';
import { CustomersPage } from './page';
import { OrgSelect } from '../../shared/OrgSelect';
import { useApi } from '../../useApi';
import { AuthContext } from '../../../auth/authContext';
import { useOrgSiteContext } from '../../../hooks/useOrgSiteContext';
import { PageHeader } from '../../PageHeader';
import { CustomersRequest } from '../../../rest/requestObjects/customersRequest';
import { UserLevel } from '../../../enums/userLevel';
import { HelpVideo } from '../../../enums/helpVideo';

export const CustomersPageAdmin = () => {
    const { roles, isGlobalAdmin, isDealerAdmin } = useContext(AuthContext);
    const deassocciateSiteFromContext =
        roles.indexOf(UserLevel.SiteAdmin) !== -1 ||
        roles.indexOf(UserLevel.SiteEmployee) !== -1 ||
        roles.indexOf(UserLevel.OrgAdmin) !== -1;
    const importAvailable =
        roles.indexOf(UserLevel.SiteAdmin) === -1 && roles.indexOf(UserLevel.SiteEmployee) === -1;
    const { customerApi } = useApi();
    const { dealerId, orgId, siteId, siteChangeCallback } = useOrgSiteContext('customers');
    const [pageSiteId, setPageSiteId] = useState<number | undefined>(siteId);
    const effectiveSiteId = useMemo(
        () => (deassocciateSiteFromContext ? pageSiteId : siteId),
        [deassocciateSiteFromContext, siteId, pageSiteId]
    );
    const [customers, setCustomers] = useState<Array<Customer | CustomerWithBoxes>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [searchText, setSearchText] = useState<string>('');
    const search = useCallback((text: string) => setSearchText(text), []);
    const options = useMemo(
        () =>
        ({
            itemsPerPage,
            page: page + 1,
            searchText: searchText,
        } as CustomersRequest),
        [page, itemsPerPage, searchText]
    );

    useEffect(() => {
        const apiMethod =
            effectiveSiteId && orgId
                ? () => customerApi.getCustomersForSite(orgId, effectiveSiteId, options)
                : orgId
                    ? () => customerApi.getCustomersForOrganization(orgId, options)
                    : undefined;
        if (apiMethod === undefined) {
            return;
        }
        setIsLoading(true);
        apiMethod().then((r) => {
            setIsLoading(false);
            if (r.result === AjaxResult.Success && r.data) {
                setCustomers(r.data.customers);
                setNumberOfPages(r.data.numberOfPages);
            }
        });
    }, [orgId, customerApi, dealerId, effectiveSiteId, options]);

    const addCustomer = (customer: Customer) => setCustomers((c) => [...c, customer]);
    const editCustomer = (editedCustomer: Customer) => setCustomers((prev) => (
        prev.map((prevCustomer) => (
            prevCustomer.id === editedCustomer.id
                ? { ...prevCustomer, ...editedCustomer }
                : prevCustomer
        ))
    ));

    return (
        <>
            <PageHeader helpVideoNum={HelpVideo.Customer}>Customers</PageHeader>

            <OrgSelect
                selectedDealerId={dealerId}
                selectedOrgId={orgId}
                selectedSiteId={effectiveSiteId || 0}
                dealerSelectIsEnabled={isGlobalAdmin}
                orgSelectIsEnabled={isGlobalAdmin || isDealerAdmin}
                includeAllSites
                bypassSiteContext={deassocciateSiteFromContext}
                onChange={(val: {
                    dealerId?: number | null;
                    orgId?: number | null;
                    siteId?: number | null;
                }) => {
                    if (deassocciateSiteFromContext) {
                        setPageSiteId(val.siteId || undefined);
                    } else {
                        siteChangeCallback(
                            val.dealerId || null,
                            val.orgId || null,
                            val.siteId || null
                        );
                    }
                }}
            />
            <CustomersPage
                {...{
                    customers,
                    setCustomers,
                    orgId,
                    siteId: effectiveSiteId,
                    page,
                    numberOfPages,
                    itemsPerPage,
                    searchText,
                    isLoading,
                    importAvailable,
                    addCustomer,
                    editCustomer,
                    setPage,
                    setItemsPerPage,
                    setSearchText,
                    search,
                }}
            ></CustomersPage>
        </>
    );
};
