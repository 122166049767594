import { makeStyles } from "@material-ui/core";
import React from "react";

import { FullSite } from "../../../../dtos/site";
import { StandardTable } from "../../../shared/table";

import { SiteRoomAlarmIndicator } from "./SiteRoomAlarmIndicator";
import { SiteRoomStatusIndicator } from "./SiteRoomStatusIndicator";
import { SiteServiceStatusIndicator } from "./SiteServiceStatusIndicator";

const useStyles = makeStyles(() => ({
    table: {
        width: "60%",
    },
    siteNameTh: {
        width: "60%",
    },
}));

interface Props {
    sites: FullSite[];
    dealerId: number | null;
}

export function SitesTable({ sites, dealerId }: Props) {
    const classes = useStyles();

    return (
        <StandardTable className={classes.table}>
            <thead>
                <tr>
                    <th>Id</th>
                    <th className={classes.siteNameTh}>Site Name</th>
                    <th>Version</th>
                    <th>Status</th>
                    <th>Room</th>
                    <th>Alarms</th>
                </tr>
            </thead>

            <tbody>
                {sites.map((site) => (
                    <tr key={site.id}>
                        <td>{site.uniqueSiteId}</td>
                        <td>{site.name}</td>
                        <td>{site.acsVersion}</td>
                        <td><SiteServiceStatusIndicator siteId={site.id} /></td>
                        <td><SiteRoomStatusIndicator siteId={site.id} /></td>
                        <td><SiteRoomAlarmIndicator site={site} dealerId={dealerId} /></td>
                    </tr>
                ))}
            </tbody>
        </StandardTable>
    );
}
