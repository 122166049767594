import { Link } from "@material-ui/core";
import { CloudDone, CloudOff } from "@material-ui/icons";
import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom"

import { AuthContext } from "../../../auth/authContext";
import { SiteAggregate } from "../../../dtos/siteAggregate";
import { TimeZone, TIME_ZONE_LABELS } from "../../../enums/timeZone";
import SignalRContext from "../../../signalr/context";
import { navLinkUrl } from "../../../utils";
import { StandardTable } from "../../shared/table";

import { DeleteSiteButton } from "./DeleteSiteButton";
import { ActivationButton } from "./ActivationButton";

interface Props {
    sites: SiteAggregate[];
    sitesAreLoading: boolean;
    contextDealerId: number | undefined;
    contextOrgId: number | undefined;
    onDeleteSite: (id: number) => void;
    onSiteActivationChange: () => void;
}

export function SitesTable({
    sites,
    sitesAreLoading,
    contextDealerId,
    contextOrgId,
    onDeleteSite,
    onSiteActivationChange,
}: Props) {
    const { serviceStatus } = useContext(SignalRContext);
    const { isGlobalAdmin, isSiteEmployee, isDealerAdmin, isOrgAdmin, siteId } = useContext(AuthContext);

    const showDeleteButton = isGlobalAdmin;
    const showActivationButton = isGlobalAdmin || isDealerAdmin || isOrgAdmin;

    return (
        <StandardTable>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Total Box Count</th>
                    <th>Total Boxes Used</th>
                    <th>Box Type Availability</th>
                    <th>Reference Site ID</th>
                    <th>Time Zone</th>
                    <th></th>
                    {showDeleteButton && (
                        <th></th> // column with delete buttons
                    )}
                    {showActivationButton && (
                        <th></th> // column with activation buttons
                    )}
                </tr>
            </thead>
            <tbody>
                {sites.map((s) => (
                    <tr key={s.id}>
                        <td>{s.uniqueSiteId}</td>
                        <td>{s.name}</td>
                        <td>{serviceStatus.get(s.id) ? <CloudDone /> : <CloudOff />}</td>
                        <td>{s.totalNumberOfBoxes}</td>
                        <td>{s.totalNumberOfBoxesUsed}</td>
                        <td>
                            <ul>
                                {s.boxTypeAvailability.map((a) => (
                                    <li key={a.size}>
                                        {a.size}: {a.count}
                                    </li>
                                ))}
                            </ul>
                        </td>
                        <td>{s.referenceSiteId}</td>
                        <td>{TIME_ZONE_LABELS[s.timeZone as TimeZone]}</td>
                        <td>
                            {!isSiteEmployee && (!siteId || siteId === s.id) && (
                                <Link
                                    component={RouterLink}
                                    to={navLinkUrl("config", contextDealerId, contextOrgId, s.id)}>
                                    Configure
                                </Link>
                            )}
                        </td>
                        {showDeleteButton && (
                            <td>
                                <DeleteSiteButton
                                    site={s}
                                    onDeleteSite={onDeleteSite}
                                />
                            </td>
                        )}
                        {showActivationButton && (
                            <td>
                                <ActivationButton
                                    site={s}
                                    sitesAreLoading={sitesAreLoading}
                                    onSiteActivationChange={onSiteActivationChange}
                                />
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </StandardTable>
    );
}