import { Button } from "@material-ui/core";
import React, { useState } from "react";

import { Dealer } from "../../../dtos/dealer";

import { DealerEditForm } from "./DealerEditForm";

interface Props {
    dealer: Dealer;
    onLoadDealers: () => void;
    dealersAreLoading: boolean;
}

export function EditDealerButton({
    dealer,
    onLoadDealers,
    dealersAreLoading,
}: Props) {
    const [showEditForm, setShowEditForm] = useState(false);

    return (
        <>
            <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowEditForm(true)}
                disabled={dealersAreLoading}
            >
                Edit
            </Button>

            {showEditForm && (
                <DealerEditForm
                    dealer={dealer}
                    onClose={() => setShowEditForm(false)}
                    onLoadDealers={onLoadDealers}
                />
            )}
        </>
    );
}