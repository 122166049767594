import React from 'react';
import { styled } from '@material-ui/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const ValidationErrors = ({ errors }: { errors: string[] }) => (
    <ErrorsStyle>
        {errors.map(e => (
            <li key={e}>{e}</li>
        ))}
    </ErrorsStyle>
);

const ErrorsStyle = styled('ul')(({ theme }: { theme: ThemeOptions }) => ({
    listStyleType: 'none',
    padding: 0,

    '& li': {
        color: theme.dangerColor
    }
}));
