import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import { ScheduledJob } from "../../../dtos/scheduledJob";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useApi } from "../../useApi";

export function useLoadScheduledJobs(): {
    jobs: ScheduledJob[];
    load: () => void;
    loading: boolean;
    setJobs: (jobs: ScheduledJob[]) => void;
} {
    const { scheduledJobsApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const [jobs, setJobs] = useState<ScheduledJob[]>([]);
    const [loading, setLoading] = useState(false);
    
    const load = useCallback(() => {
        setLoading(true);
        scheduledJobsApi.getAll().then(({ result, data: loadedJobs }) => {
            if (result === AjaxResult.Success && loadedJobs) {
                setJobs(loadedJobs);
            } else {
                enqueueSnackbar("Failed to get scheduled jobs", { variant: "error" });
            }
            setLoading(false);
        });
    }, [enqueueSnackbar, scheduledJobsApi]);

    useEffect(() => {
        load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        jobs,
        setJobs,
        load,
        loading,
    };
}
