import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";

import { AuthContext } from "../../../auth/authContext";
import { OrganizationAggregate } from "../../../dtos/organizationAggregate";
import { ActiveStatus } from "../../../enums/ActiveStatus";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { checkIfUserCanActivate } from "../../../utils/activation";
import { useApi } from "../../useApi";

interface Props {
    org: OrganizationAggregate;
    orgsAreLoading: boolean;
    onOrgActivationChange: () => void;
}

export function ActivationButton({ org, onOrgActivationChange, orgsAreLoading }: Props) {
    const { orgApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const { showConfirmDialog } = useConfirmDialog();
    const auth = useContext(AuthContext);

    const activateOrg = () => {
        showConfirmDialog(`Are you sure you want do activate organization "${org.name}"?`).then(
            (confirmed) => {
                if (confirmed) {
                    setIsLoading(true);
                    orgApi.activate(org.id).then(r => {
                        if (r.result === AjaxResult.Success) {
                            onOrgActivationChange()
                        } else {
                            enqueueSnackbar(`Failed to activate organization "${org.name}"`, { variant: "error" })
                        }
                        setIsLoading(false);
                    });
                }
            }
        );
    }

    const deactivateOrg = () => {
        showConfirmDialog(`Are you sure you want do deactivate organization "${org.name}"?`).then(
            (confirmed) => {
                if (confirmed) {
                    setIsLoading(true);
                    orgApi.deactivate(org.id).then(r => {
                        if (r.result === AjaxResult.Success) {
                            onOrgActivationChange()
                        } else {
                            enqueueSnackbar(`Failed to deactivate organization "${org.name}"`, { variant: "error" })
                        }
                        setIsLoading(false);
                    });
                }
            }
        );
    }

    const isUsersDealerDeactivated = auth.dealerId !== null && !auth.isDealerActive;
    const isButtonDisabled = isLoading || orgsAreLoading || isUsersDealerDeactivated

    if (org.activeStatus === ActiveStatus.Active) {
        return (
            <Button
                disabled={isButtonDisabled}
                onClick={deactivateOrg}
                variant="outlined"
                color="secondary"
            >
                Deactivate
            </Button>
        );
    }

    const userCanActivate = checkIfUserCanActivate(
        org.activeStatus,
        auth.isDealerAdmin,
        auth.isGlobalAdmin,
        auth.isOrgAdmin,
    );

    return (
        <Button
            disabled={isButtonDisabled || !userCanActivate}
            onClick={activateOrg}
            variant="outlined"
            color="primary"
        >
            Activate
        </Button>
    );
}
