import { styled } from '@material-ui/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const StandardTable = styled('table')(({ theme }: { theme: ThemeOptions }) => ({
    borderCollapse: 'collapse',
    width: '100%',

    '& th': {
        color: theme.fontThemeColor1,
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'left'
    },

    '& th,td': {
        backgroundColor: theme.backgroundThemeColor1,
        padding: '20px'
    },

    '& tr td': {
        borderBottom: `1px solid ${theme.backgroundThemeColor2}`,
        color: theme.fontThemeColor2,

        '&:first-child': {
            color: theme.fontThemeColor3,
            fontWeight: 'bold'
        }
    }
}));
