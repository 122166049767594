import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import { AjaxResult } from "../../../../enums/ajaxResult";
import { useApi } from "../../../useApi";
import { ManualOverrideRequestData } from "../../../../dtos/manualOverride";

interface Props {
    requestData: ManualOverrideRequestData | undefined;
    onClose: () => void;
    onManualOverrideRequestSuccess: (requestReference: string) => void;
    onManualOverrideRequestFail: () => void;
    onCodeReceived: (code: string) => void;
}

export function DialogContentsSendingRequest({
    requestData,
    onClose,
    onManualOverrideRequestSuccess,
    onManualOverrideRequestFail,
    onCodeReceived,
}: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const { siteApi } = useApi();
    const [sendingRequest, setSendingRequest] = useState<boolean>(false);

    useEffect(() => {
        if (!requestData) return;
        setSendingRequest(true);
        siteApi.manualOverride(requestData.siteId, requestData.customerId).then((r) => {
            if (r.result === AjaxResult.Success && r.data) {
                if (r.data.requestReference) {
                    onManualOverrideRequestSuccess(r.data.requestReference);
                } else if (r.data.temporaryCode) {
                    onCodeReceived(r.data.temporaryCode);
                }
            } else {
                enqueueSnackbar("Failed to request enrollment", { variant: "error" });
                onManualOverrideRequestFail()
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData]);

    if (sendingRequest) {
        return (
            <>
                <DialogTitle style={{ textAlign: "center" }}>
                    Sending request to site
                </DialogTitle>

                <DialogContent style={{ textAlign: "center" }}>
                    <CircularProgress />
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </>
        );
    }

    return <></>
}