import { Button } from "@material-ui/core";
import React, { useState } from "react";

import { ScheduledJob } from "../../../../dtos/scheduledJob";
import { ScheduledJobType } from "../../../../enums/ScheduledJobType";

import { EditScheduledJobDialog } from "./Dialog";

interface Props {
    job: ScheduledJob;
    onJobSaved: (job: ScheduledJob) => void;
    addedJobTypes: ScheduledJobType[];
}

export function EditScheduledJobButton({ job, onJobSaved, addedJobTypes }: Props) {
    const [openDialog, setOpenDialog] = useState(false);

    return <>
        <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenDialog(true)}
        >
            Edit
        </Button>

        <EditScheduledJobDialog
            job={job}
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            onJobSaved={(job) => {
                setOpenDialog(false);
                onJobSaved(job);
            }}
            addedJobTypes={addedJobTypes.filter(it => it !== job.type)}
        />
    </>;
}