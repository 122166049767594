import React, { useMemo, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    InputLabel,
    CircularProgress,
    Stepper,
    StepLabel,
    Step,
    TextareaAutosize,
    styled,
} from '@material-ui/core';
import { useApi } from '../../useApi';
import { AjaxResult } from '../../../enums/ajaxResult';

enum ImportSteps {
    start = 0,
    reviewInProgress = 1,
    review = 2,
    executing = 3,
    result = 4,
}

function getSteps() {
    return ['Select file', 'Review Changes', 'Execute Import'];
}

export const BulkImport = ({
    orgId,
    siteId,
    closeForm,
}: {
    orgId?: number;
    siteId?: number;
    closeForm: () => void;
}) => {
    const fileRef: React.RefObject<HTMLInputElement> = React.createRef();
    const fileInputId = 'fileInputElement';
    const { importApi } = useApi();

    const [message, setMessage] = useState<string>();

    const [activeStep, setActiveStep] = useState<ImportSteps>(ImportSteps.start);
    const [errors, setErrors] = useState<string>('');
    const [changes, setChanges] = useState<string>('');

    const submitForImport = () => {
        // setFileChosen(false);

        if (fileRef.current && fileRef.current.files && fileRef.current.files[0]) {
            const data = new FormData();
            data.append('file', fileRef.current.files[0]);
            setMessage('');
            // if (fileRef.current.files[0].type !== 'text/csv'){
            //     setMessage("Error: import file must be of type 'csv'");
            //     setFileOperationInProcess(false);
            //     return;
            // }
            if (orgId === undefined) {
                return;
            }

            setActiveStep(ImportSteps.executing);

            importApi.importCustomersForOrg(orgId, data, false).then((r) => {
                if (r.result === AjaxResult.Success) {
                    if (r.data && r.data.errors) {
                        setMessage('Customer import was not successful');
                        setErrors(r.data.errors);
                    } else if (r.data) {
                        setMessage('Customers imported successfully');
                        setChanges(r.data.changes);
                    } else {
                        setMessage('Invalid response from server');
                    }

                    setActiveStep(ImportSteps.result);
                }
            });
        }
    };

    const submitForReview = () => {
        // setFileChosen(false);

        if (fileRef.current && fileRef.current.files && fileRef.current.files[0]) {
            const data = new FormData();
            data.append('file', fileRef.current.files[0]);

            // if (fileRef.current.files[0].type !== 'text/csv'){
            //     setMessage("Error: import file must be of type 'csv'");
            //     setFileOperationInProcess(false);
            //     return;
            // }
            if (orgId === undefined) {
                return;
            }

            setActiveStep(ImportSteps.reviewInProgress);

            // submit for review
            importApi.importCustomersForOrg(orgId, data, true).then((r) => {
                if (r.result === AjaxResult.Success) {
                    if (r.data && r.data.errors) {
                        setMessage('Invalid Import File');
                        setErrors(r.data.errors);
                    } else if (r.data && r.data.changes) {
                        setMessage('Import File Review Success');
                        setChanges(r.data.changes);
                    } else {
                        setMessage('Invalid response from server');
                    }
                }

                setActiveStep(ImportSteps.review);
            });
        }
    };

    const buttonForStep = useMemo(() => {
        let text = '';
        let action = () => {};
        switch (activeStep) {
            case ImportSteps.start:
                text = 'Submit For Review';
                action = submitForReview;
                break;
            case ImportSteps.reviewInProgress:
                return null;
            case ImportSteps.review:
                if (errors !== '' && errors !== null && errors !== undefined) {
                    return null;
                }
                text = 'Execute Import';
                action = submitForImport;
                break;
            case ImportSteps.executing:
                return null;
            case ImportSteps.result:
                text = 'Done';
                action = closeForm;
                break;
            default:
                break;
        }

        return <Button onClick={action}>{text}</Button>;
        // eslint-disable-next-line
    }, [activeStep, errors]); // All of the actions are const, fine with ignoring right now. Probably a better way to do it

    const cancelButtonForStep = useMemo(() => {
        let action = () => {};
        switch (activeStep) {
            case ImportSteps.result:
                return null;
            default:
                action = closeForm;
                break;
        }

        return <Button onClick={action}>Cancel</Button>;
        // eslint-disable-next-line
    }, [activeStep]); // All of the actions are const, fine with ignoring right now. Probably a better way to do it

    const contentForStep = useMemo(() => {
        switch (activeStep) {
            case ImportSteps.start:
                return (
                    <div>
                        <InputLabel>Select CSV File</InputLabel>
                    </div>
                );
            case ImportSteps.reviewInProgress:
                return <CircularProgress />;
            case ImportSteps.review:
                return (
                    <div>
                        {errors && <ChangesTextArea>{errors}</ChangesTextArea>}
                        {changes && <ChangesTextArea>{changes}</ChangesTextArea>}
                    </div>
                );
            case ImportSteps.executing:
                return <CircularProgress />;
            case ImportSteps.result:
                return (
                    <div>
                        {errors && <ChangesTextArea>{errors}</ChangesTextArea>}
                        {changes && <ChangesTextArea>{changes}</ChangesTextArea>}
                    </div>
                );
            default:
                break;
        }

        return <div />;
    }, [activeStep, changes, errors]);

    const steps = getSteps();

    return (
        <Dialog open={true}>
            <DialogContent>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {message}
                {contentForStep}
                <input
                    id={fileInputId}
                    type="file"
                    ref={fileRef}
                    hidden={activeStep !== ImportSteps.start}
                />
            </DialogContent>
            <DialogActions>
                {buttonForStep}
                {cancelButtonForStep}
            </DialogActions>
        </Dialog>
    );
};

const ChangesTextArea = styled(TextareaAutosize)({
    width: '100%',
});
