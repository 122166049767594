import { useCallback, useState } from "react";

import { Customer } from "../../../dtos/customer";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useApi } from "../../useApi";
import { useSnackbar } from "notistack";

export function useDeleteCustomer() {
    const [isDeleting, setIsDeleting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { customerApi } = useApi();

    const deleteCustomer = useCallback((customer: Customer, onSuccess?: () => void) => {
        setIsDeleting(true);
        customerApi.delete(customer.id).then((r) => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar(
                    `The customer (${customer.fullName}) has been deleted`,
                    { variant: "success" },
                );
                if (onSuccess) onSuccess();
            } else {
                switch (r.result) {
                    case AjaxResult.Forbidden:
                        enqueueSnackbar(
                            "You do not have permission to delete this customer",
                            { variant: "error" },
                        );
                        break;
                    default:
                        enqueueSnackbar(
                            "Failed to delete customer",
                            { variant: "error" },
                        );
                }
            }
            setIsDeleting(false);
        });
    }, [customerApi, enqueueSnackbar]);

    return { deleteCustomer, isDeleting }
}
