import useRouter from 'use-react-router';

export const useRouterWithTypedParams = <TParams>() => {
    const { match, history, location, staticContext } = useRouter();
    const typedParams = getTypedRouteParams(match.params) as TParams;
    return {
        match: { ...match, params: typedParams },
        history,
        location,
        staticContext
    };
};

const getTypedRouteParams = (params: Object) => {
    let typedParams = { ...params };
    Object.keys(params).forEach(key => {
        const val = (params as any)[key];
        if (typeof val === 'string') {
            const parsedAsInt = parseInt(val, 10);
            if (!isNaN(parsedAsInt)) {
                (typedParams as any)[key] = parsedAsInt;
            }
        }
    });
    return typedParams;
};
