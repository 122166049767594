import React from 'react';
import MaskedInput from 'react-text-mask';

export const PhoneNumberInput = (props: any) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={false}
            mask={[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/
            ]}
            placeholder={'(123) 456-7890'}
            placeholderChar={'\u2000'}
            showMask
        />
    );
};
