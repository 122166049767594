import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { Site } from "../../../../../dtos/site";
import { AjaxResult } from "../../../../../enums/ajaxResult";
import { RequestState } from "../../../../../enums/requestState";
import SignalRContext from "../../../../../signalr/context";
import { ButtonWithConfirm } from "../../../../ButtonWithConfirm";
import { useApi } from "../../../../useApi";

const buttonLabels: { [key in RequestState]: string; } = {
    [RequestState.Idle]: "Sensor reset",
    [RequestState.Requesting]: "Requesting...",
    [RequestState.Processing]: "Resetting Sensors...",
}

interface Props {
    site: Site;
}

export function SensorResetButton({ site }: Props) {
    const { siteApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { sensorResetCompleted } = useContext(SignalRContext);

    const [requestState, setRequestState] = useState<RequestState>(RequestState.Idle);

    const handleSensorReset = useCallback(() => {
        setRequestState(RequestState.Requesting);
        siteApi.resetSensor(site.id).then((r) => {
            if (r.result !== AjaxResult.Success) {
                setRequestState(RequestState.Idle);
                enqueueSnackbar("ERROR: Failed to request sensor reset", { variant: "error" });
            } else {
                setRequestState(RequestState.Processing);
            }
        });
    }, [siteApi, site.id, enqueueSnackbar]);

    useEffect(() => {
        if (requestState === RequestState.Processing && sensorResetCompleted === site.id) {
            setRequestState(RequestState.Idle);
            enqueueSnackbar("Sensor reset completed", { variant: "success" });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sensorResetCompleted]);

    return (
        <ButtonWithConfirm
            confirmDialogContent="Are you sure to perform the sensor reset?"
            confirmedCallback={handleSensorReset}
            disabled={requestState !== RequestState.Idle}
        >
            {buttonLabels[requestState]}
        </ButtonWithConfirm>
    );
}