import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { CancelRounded } from "@material-ui/icons";
import React from "react";


interface Props {
    onClose: () => void;
    error: string;
}

export function DialogContentsError({ onClose, error }: Props) {
    return (
        <>
            <DialogTitle style={{ textAlign: "center" }}>
                {error}
            </DialogTitle>

            <DialogContent style={{ textAlign: "center" }}>
                <CancelRounded fontSize="large" style={{ color: "red" }} />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
}