import { Button, Dialog, DialogActions, DialogContent, FormControl, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../auth/authContext";
import { Service } from "../../../dtos/service";
import { AjaxResult } from "../../../enums/ajaxResult";
import { TimeZone } from "../../../enums/timeZone";
import { FormSection } from "../../shared/formSection";
import { FormField } from "../../shared/formField";
import { useApi } from "../../useApi";
import { ValidationErrors } from "../../shared/validationErrors";

import { TimeZoneSelect } from "./TimeZoneSelect";

const incorrectGuidError = "Invalid Unique Id. There is no service matching this Service Id";
const missingOrgIdError = "Missing Organization ID";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSiteAdded: () => void;
    serviceGuidFromParams: string | undefined;
    orgId: number | undefined
}

export function AddSiteDialog({
    isOpen,
    onClose,
    onSiteAdded,
    serviceGuidFromParams,
    orgId,
    children,
}: React.PropsWithChildren<Props>) {
    const { siteApi } = useApi();
    const { isGlobalAdmin, isDealerAdmin } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const [siteName, setSiteName] = useState("");
    const [serviceGuid, setServiceGuid] = useState<string>("");
    const [timeZone, setTimeZone] = useState<TimeZone>(TimeZone.UTC);
    const [selectedService, setSelectedService] = useState<Service | undefined>(undefined);
    const [availableServices, setAvailableServices] = useState<Service[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (!isOpen) return;

        // only global admins can get these services, so we don't try to make this api call unless user is global admin
        if (!isGlobalAdmin && !isDealerAdmin) return;

        siteApi.getAvailableServices().then((r) => {
            setAvailableServices(r.data || []);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGlobalAdmin, isDealerAdmin, isOpen]);

    const handleSiteNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSiteName(event.currentTarget.value)
    }, []);

    const toggleError = useCallback((message: string, add: boolean) => {
        setErrors(prev => {
            if (prev.find(it => it === message)) {
                return add ? prev : prev.filter(it => it !== message);
            } else {
                return add ? [...prev, message] : prev;
            }
        });
    }, []);

    const handleSiteUniqueIdChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceGuid(event.currentTarget.value);
    }, []);

    function saveNewSite() {
        if (!orgId) return;
        setLoading(true);
        siteApi
            .post({
                organizationId: orgId,
                name: siteName,
                emailAddresses: "",
                serviceId: selectedService ? selectedService.id : undefined,
                emailAlertsEnabled: true,
                referenceSiteId: "",
                timeZone: timeZone,
                maintenanceWindow: null,
            })
            .then((r) => {
                if (r.result === AjaxResult.Success) {
                    onSiteAdded();
                    setSiteName("");
                    setServiceGuid("");
                    onClose();
                    enqueueSnackbar("Site created");
                } else if (r.messages) {
                    setErrors(r.messages);
                } else {
                    enqueueSnackbar("ERROR: Failed to create site");
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        if (serviceGuidFromParams) {
            setServiceGuid(serviceGuidFromParams);
        }
    }, [serviceGuidFromParams]);

    useEffect(() => {
        const foundService = availableServices.find(it => it.serviceGuid === serviceGuid)
        setSelectedService(foundService);
        toggleError(incorrectGuidError, serviceGuid !== "" && !foundService);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceGuid, availableServices]);

    useEffect(() => {
        toggleError(missingOrgIdError, orgId === undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);

    return (
        <Dialog open={isOpen} disablePortal>
            <DialogContent style={{ minWidth: "400px" }}>
                <FormSection headerText="Create New Site">
                    {children}

                    <FormField label="Site Name" required={true}>
                        <FormControl style={{ flexGrow: 1 }}>
                            <TextField
                                value={siteName}
                                onChange={handleSiteNameChange}
                                variant="outlined"
                                InputProps={{ inputProps: { style: { padding: "8px" } } }}
                            />
                        </FormControl>
                    </FormField>

                    <FormField label="Unique Id" required={true}>
                        <FormControl style={{ flexGrow: 1 }}>
                            <TextField
                                value={serviceGuid}
                                disabled={Boolean(serviceGuidFromParams)}
                                onChange={handleSiteUniqueIdChange}
                                variant="outlined"
                                InputProps={{ inputProps: { style: { padding: "8px" } } }}
                            />
                        </FormControl>
                    </FormField>

                    <FormField label="Time zone" required={true}>
                        <FormControl style={{ flexGrow: 1 }}>
                            <TimeZoneSelect
                                value={timeZone}
                                onChange={setTimeZone}
                            />
                        </FormControl>
                    </FormField>

                    <ValidationErrors errors={errors} />
                </FormSection>
            </DialogContent>

            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={saveNewSite}
                    disabled={loading || !siteName || !selectedService}
                >
                    {loading ? "Saving..." : "Save"}
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
