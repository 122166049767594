import { useState, useRef, useContext } from 'react';
import { ConfirmDialogContext } from '../components/confirmContext';

function defer() {
    var deferred = {
        promise: null as Promise<boolean> | null,
        resolve: null as ((value?: boolean) => void) | null,
        reject: null as ((reason?: any) => void) | null
    };

    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });

    return deferred;
}

export const useConfirmDialogGlobal = () => {
    const [confirmDialogIsVisible, setConfirmDialogIsVisible] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const promiseRef = useRef<ReturnType<typeof defer> | null>(null);
    const showConfirmDialog = (message: string) => {
        setConfirmMessage(message);
        setConfirmDialogIsVisible(true);
        promiseRef.current = defer();
        return promiseRef.current.promise as Promise<boolean>;
    };
    const resolveWithTrue = () => {
        if (promiseRef.current && promiseRef.current.resolve) {
            promiseRef.current.resolve(true);
        }
    };
    const resolveWithFalse = () => {
        if (promiseRef.current && promiseRef.current.resolve) {
            promiseRef.current.resolve(false);
        }
    };
    return {
        confirmMessage,
        showConfirmDialog,
        resolveWithTrue,
        resolveWithFalse,
        confirmDialogIsVisible,
        setConfirmDialogIsVisible
    };
};

export const useConfirmDialog = () => {
    const { showConfirmDialog } = useContext(ConfirmDialogContext);
    return { showConfirmDialog };
};
