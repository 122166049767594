import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";

import { Customer } from "../../../dtos/customer";
import { useSitesWithUserActiveLeases } from "../../../hooks/useSitesWithUserActiveLeases";

import { useDeleteCustomer } from "./useDeleteCustomer";

interface Props {
    customer: Customer | undefined;
    orgId: number | undefined;
    onSuccess: (id: number) => void;
    onClose: () => void;
}

export function DeleteCustomerDialog({ customer, orgId, onClose, onSuccess }: Props) {
    const { loading: checking, sites } = useSitesWithUserActiveLeases(customer, orgId);
    const { deleteCustomer, isDeleting } = useDeleteCustomer();

    const canBeDeleted = !checking && sites.length === 0;

    function handleDeleteCustomer() {
        if (!customer) return;
        deleteCustomer(customer, () => {
            onSuccess(customer.id);
            onClose();
        });
    }

    function handleCancel() {
        onClose();
    }

    const name = customer ? customer.fullName : "";
    return (
        <Dialog
            open={Boolean(customer)}
            PaperProps={{ style: { minWidth: "300px" } }}
        >
            <DialogContent style={{ textAlign: "center" }}>
                {(checking || isDeleting) && <CircularProgress size={20} />}
                {canBeDeleted && !isDeleting && `Are you sure you want to delete this customer (${name})?`}
                {!checking && !canBeDeleted && `Customer (${name}) with an active lease cannot be deleted`}
            </DialogContent>

            <DialogActions>
                {canBeDeleted && (
                    <Button
                        onClick={handleDeleteCustomer}
                        disabled={isDeleting}
                    >
                        Confirm
                    </Button>
                )}
                <Button
                    onClick={handleCancel}
                    disabled={isDeleting}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
