import { ApiResult, AxiosApi } from './axiosApi';
import { Dealer, DealerPostDto } from '../dtos/dealer';

export class DealerApi extends AxiosApi<Dealer> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, 'dealers', logout, enqueueSnackbar);
    }

    public createDealer = (dealer: DealerPostDto) =>
        this.genericRequest({ method: 'POST', data: dealer });

    public updateDealer = (dealerId: number, dealerDto: DealerPostDto) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${dealerId}`,
            data: dealerDto,
        }) as Promise<ApiResult<any>>;

    public activate = (dealerId: number) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${dealerId}/activate`,
        }) as Promise<ApiResult<unknown>>;

    public deactivate = (dealerId: number) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${dealerId}/deactivate`,
        }) as Promise<ApiResult<unknown>>;
}
