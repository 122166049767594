import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { SiteAggregate } from "../../../dtos/siteAggregate";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useApi } from "../../useApi";

interface Props {
    onDeleteSite: (id: number) => void;
    site: SiteAggregate;
}

export function DeleteSiteButton({ site, onDeleteSite }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showConfirmDialog } = useConfirmDialog();
    const { enqueueSnackbar } = useSnackbar();
    const { siteApi } = useApi();

    const deleteSite = () => {
        showConfirmDialog(`Are you sure you want to delete ${site.name} site?`).then((confirmed) => {
            if (confirmed) {
                setIsLoading(true);
                siteApi.delete(site.id).then((r) => {
                    if (r.result === AjaxResult.Success) {
                        enqueueSnackbar("Site has been archived");
                        onDeleteSite(site.id);
                    } else {
                        enqueueSnackbar("Failed to archive site", { variant: "error" });
                    }
                    setIsLoading(false);
                });
            }
        });
    };

    return (
        <Button
            color="secondary"
            variant="outlined"
            disabled={isLoading}
            onClick={deleteSite}
        >
            Delete
        </Button>
    );
}