import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { ValueType } from "react-select/src/types";

import { Customer } from "../../../dtos/customer";

const useStyles = makeStyles({
    customerSelect: {
        marginBottom: "20px",
        marginTop: "5px",
    },
});

interface OptionType { value: number; label: string };

interface Props {
    disabled: boolean;
    customers: Customer[]; 
    onCustomerSelect: (customer: Customer | null) => void;
}

export function CustomerSelect({ disabled, customers, onCustomerSelect }: Props) {
    const classes = useStyles();
    const [value, setValue] = useState<ValueType<OptionType>>();

    const options = useMemo(() => {
        return customers.map(it => ({ label: it.fullName, value: it.id }));
    }, [customers]);

    const handleChange = useCallback((value: ValueType<OptionType>) => {
        setValue(value);
        if (value) {
            const customer = customers.find((it) => it.id === (value as OptionType).value);
            onCustomerSelect(customer || null);
        }
    }, [customers, onCustomerSelect]);

    useEffect(() => {
        if (value && !options.find(it => it.value === (value as OptionType).value)) {
            setValue(null);
            onCustomerSelect(null);
        }
    // eslint-disable-next-line
    }, [options]);

    return (
        <>
            <div>Select Customer:</div>
            <div>
                <Select
                    value={value}
                    className={classes.customerSelect}
                    isDisabled={disabled}
                    options={options}
                    isClearable={true}
                    onChange={handleChange}
                    placeholder="Select customer..."
                />
            </div>
        </>
    );
}