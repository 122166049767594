import { Button, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect, useCallback } from "react";

import { OrganizationAggregate } from "../../../dtos/organizationAggregate";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useOrgSiteContext } from "../../../hooks/useOrgSiteContext";
import { OrgSelect } from "../../shared/OrgSelect";
import { PageHeader } from "../../PageHeader";
import { useApi } from "../../useApi";

import { OrgEditForm } from "./OrgEditForm";
import { OrgsTable } from "./table";

export const OrganizationsPage = () => {
    const classes = useStyles();
    const { orgApi } = useApi();
    const [orgs, setOrgs] = useState<OrganizationAggregate[]>([]);
    const [orgsAreLoading, setOrgsAreLoading] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { dealerId, orgId, siteChangeCallback } = useOrgSiteContext("organizations");

    const loadOrgs = useCallback(() => {
        setOrgsAreLoading(true);
        const apiMethod = orgId
            ? () => orgApi.getForOrgIdAggregate(orgId)
            : dealerId
                ? () => orgApi.getForDealerAggregate(dealerId)
                : orgApi.getAggregate;
        apiMethod().then((r) => {
            if (r.result === AjaxResult.Success && r.data) {
                setOrgs(r.data);
            } else {
                enqueueSnackbar("ERROR: Failed to get organizations");
            }
            setOrgsAreLoading(false);
        });
    }, [enqueueSnackbar, orgApi, dealerId, orgId]);

    useEffect(() => {
        loadOrgs();
    }, [loadOrgs, dealerId, orgId]);

    const handleOrgDelete = useCallback((id: number) => {
        setOrgs((o) => o.filter((org) => org.id !== id));
    }, []);

    return (
        <>
            <PageHeader>Organizations</PageHeader>
            <OrgSelect
                orgSelectIsHidden
                siteSelectIsHidden
                selectedDealerId={dealerId}
                selectedOrgId={orgId}
                onChange={(val: { dealerId?: number | null; orgId?: number | null }) =>
                    siteChangeCallback(val.dealerId, val.orgId, null)
                }
            />
            <div>
                <Button
                    className={classes.addButton}
                    variant="contained"
                    color="primary"
                    disabled={!dealerId}
                    onClick={() => setShowCreateForm(true)}
                >
                    Add New
                </Button>
            </div>

            {showCreateForm && (
                <OrgEditForm
                    dealerId={dealerId}
                    onClose={() => setShowCreateForm(false)}
                    onLoadOrgs={loadOrgs}
                />
            )}

            <OrgsTable
                orgs={orgs}
                orgsAreLoading={orgsAreLoading}
                dealerId={dealerId}
                onDeleteOrg={handleOrgDelete}
                onLoadOrgs={loadOrgs}
            />
        </>
    );
};

const useStyles = makeStyles({
    addButton: {
        display: "flex",
        marginBottom: "20px",
        marginTop: "20px",
    },
});
