import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';

interface Props {
    label?: string;
    required?: boolean;
}

export const FormField = ({ children, label, required = false }: PropsWithChildren<Props>) => {
    const classes = useStyles();

    if (!label) {
        return <>{children}</>
    }

    return (
        <div className={classes.formField}>
            <label className={classes.label}>
                {`${label}:${required ? " *" : ""}`}
            </label>
            {children}
        </div>
    );
};

const useStyles = makeStyles({
    formField: {
        display: "flex",
        alignItems: "center",
        margin: "5px 0"
    },
    label: {
        marginRight: "5px",
    },
});
