import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

import { ScheduledJob, ScheduledJobBase } from "../../../../dtos/scheduledJob";
import { useApi } from "../../../useApi";
import { AjaxResult } from "../../../../enums/ajaxResult";

export function useEditScheduledJob(
    job: ScheduledJob,
    onJobSaved: (job: ScheduledJob) => void,
): {
    save: () => void;
    loading: boolean;
    jobBase: ScheduledJobBase;
    setJobBase: (jobBase: ScheduledJobBase) => void;
} {
    const { scheduledJobsApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const [jobBase, setJobBase] = useState<ScheduledJobBase>({
        name: job.name,
        type: job.type,
        targets: job.targets,
    });

    const [loading, setLoading] = useState(false);

    const save = useCallback(() => {
        setLoading(true);
        scheduledJobsApi.put(job.id, jobBase).then(({ result }) => {
            if (result === AjaxResult.Success) {
                onJobSaved({ ...job, ...jobBase });
            } else {
                enqueueSnackbar("Failed to save edited job", { variant: "error" });
            }
            setLoading(false);
        });
    }, [enqueueSnackbar, scheduledJobsApi, jobBase, job, onJobSaved]);

    return { save, loading, jobBase, setJobBase };
}