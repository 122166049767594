import { makeStyles } from "@material-ui/styles";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { EmployeeForView } from "../../../../dtos/employee";
import { AjaxResult } from "../../../../enums/ajaxResult";
import { PinCodePreview } from "../../../PinCodePreview";
import { FormField } from "../../../shared/formField";
import { FormSection } from "../../../shared/formSection";
import { useApi } from "../../../useApi";

import { RefreshPinCodeButton } from "./RefreshPinCodeButton";

interface Props {
    open: boolean;
    onClose: () => void;
    employeeId?: number | null;
    forCurrentUser?: boolean;
}
export const ViewEmployeeDialog = ({ open, employeeId, onClose, forCurrentUser = false }: Props) => {
    const classes = useStyles();
    const { employeeApi, authApi } = useApi();
    const [employee, setEmployee] = useState<EmployeeForView>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (!employeeId) return;
        setIsLoading(true);
        employeeApi.getEmployee(employeeId).then((r) => {
            if (r.result === AjaxResult.Success) {
                if (r.data) {
                    setEmployee(r.data);
                }
            } else {
                setError("Failed to load Employee Info");
            }
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeId]);

    useEffect(() => {
        if (!forCurrentUser || !open) return;
        setIsLoading(true);
        authApi.getUserInfo().then((r) => {
            if (r.result === AjaxResult.Success) {
                if (r.data) {
                    setEmployee(r.data);
                }
            } else {
                setError("Failed to load Current User Profile");
            }
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forCurrentUser, open]);

    const handleClose = () => {
        setError("");
        setEmployee(undefined);
        onClose();
    };

    return (
        <Dialog
            className={classes.employeeDialog}
            fullWidth={true}
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
        >
            <DialogContent className={classes.employeeDialogContent}>
                <FormSection headerText={"Employee Info"} labelMaxWidth={100}>
                    {isLoading && "Loading..."}
                    {employee && (
                        <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
                            <FormField label="Email">
                                {employee.email}
                            </FormField>

                            {employee.pinCode && (
                                <FormField label="Pin Code">
                                    <PinCodePreview code={employee.pinCode} />
                                </FormField>
                            )}

                            {employee.pinCode && (
                                <FormField label="Refresh Pin Code">
                                    <RefreshPinCodeButton
                                        setEmployee={setEmployee}
                                        setError={setError}
                                    />
                                </FormField>
                            )}

                            <FormField label="First Name">
                                {employee.firstName}
                            </FormField>

                            <FormField label="Last Name">
                                {employee.lastName}
                            </FormField>

                            <FormField label="Phone">
                                {employee.phoneNumber}
                            </FormField>

                            <FormField label="Address">
                                {employee.address}
                            </FormField>

                            <FormField label="Account">
                                {employee.roles ? employee.roles[0] : ""}
                            </FormField>

                            {!forCurrentUser && (
                                <>
                                    <FormField label="Dealer">
                                        {employee.dealer ? employee.dealer.name : ""}
                                    </FormField>

                                    <FormField label="Organization">
                                        {employee.organization ? employee.organization.name : ""}
                                    </FormField>

                                    <FormField label="Site">
                                        {employee.site ? employee.site.name : ""}
                                    </FormField>
                                </>
                            )}

                            <FormField label="Last Login">
                                {employee.lastLoginDate
                                    ? moment(employee.lastLoginDate).format("M/DD/YYYY h:mm:ss A")
                                    : ""
                                }
                            </FormField>
                        </div>
                    )}
                    {error && (
                        <Typography color="error">
                            {error}
                        </Typography>
                    )}
                </FormSection>
            </DialogContent>

            <DialogActions>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = makeStyles({
    employeeDialog: {
        maxWidth: "750px",
        margin: "auto",
    },
    employeeDialogContent: {
        maxWidth: "700px",
        minHeight: "400px",
    },
});
