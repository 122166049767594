// WARNING: the order of these values is important, partly due to what's happening in getUserLevels() below

export enum UserLevel {
    SiteEmployee = 'Site Employee',
    SiteAdmin = 'Site Admin',
    OrgAdmin = 'Org Admin',
    DealerAdmin = 'Dealer Admin',
    GlobalAdmin = 'Global Admin'
}

export const getUserLevels = (
    loggedInUserRoles: string[],
    userType: 'site' | 'org' | 'dealer' | 'global'
) => {
    const levelsByType = getUserLevelByType(userType);
    const levels = [] as UserLevel[];
    let reachedLoggedInLevel = false;
    levelsByType.forEach(l => {
        if (!reachedLoggedInLevel) {
            levels.push(l);
        }
        if (loggedInUserRoles.indexOf(l) !== -1) {
            reachedLoggedInLevel = true;
        }
    });
    return levels;
};

const getUserLevelByType = (userType: 'site' | 'org' | 'dealer' | 'global') => {
    switch (userType) {
        case 'site':
            return [UserLevel.SiteEmployee, UserLevel.SiteAdmin];
        case 'org':
            return [UserLevel.OrgAdmin];
        case 'dealer':
            return [UserLevel.DealerAdmin];
        case 'global':
        default:
            return [UserLevel.GlobalAdmin];
    }
};
