import React from 'react';
import Select from 'react-select';
import { Props } from 'react-select/src/Select';

export const DialogSelect = <TOptionType extends unknown>(props: Props<TOptionType & { fullWidth?: boolean }>) => (
    <Select
        {...props}
        menuPortalTarget={document.body}
        styles={{
            menuPortal: base => ({ ...base, zIndex: 10000 }),
            container: base => ({ ...base, flexGrow: props.fullWidth ? 1 : undefined}),
        }}
    />
);
