import { ScheduledJob, ScheduledJobBase } from "../dtos/scheduledJob";

import { AxiosApi, ApiResult } from "./axiosApi";

export class ScheduledJobsApi extends AxiosApi<ScheduledJob> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, "ScheduledJobs", logout, enqueueSnackbar);
    }

    public getAll = () => this.genericRequest({
        method: "GET",
    }) as Promise<ApiResult<ScheduledJob[]>>;

    public getById = (id: number) => this.genericRequest({
        method: "GET",
        extraPath: `${id}`,
    }) as Promise<ApiResult<ScheduledJob>>;

    public post = (job: ScheduledJobBase) => this.genericRequest({
        method: "POST",
        data: job,
    }) as Promise<ApiResult<ScheduledJob>>;

    public put = (id: number, job: ScheduledJobBase) => this.genericRequest({
        method: "PUT",
        extraPath: `${id}`,
        data: job,
    }) as Promise<ApiResult<unknown>>;

    public delete = (id: number) => this.genericRequest({
        method: "DELETE",
        extraPath: `${id}`,
    }) as Promise<ApiResult<null>>;
}
