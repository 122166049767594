import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { Dealer } from "../../../dtos/dealer";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useApi } from "../../useApi";

interface Props {
    dealer: Dealer;
    onDealerDelete: () => void;
    dealersAreLoading: boolean;
}

export function DeleteDealerButton({
    dealer,
    onDealerDelete,
    dealersAreLoading,
}: Props) {
    const { showConfirmDialog } = useConfirmDialog();
    const { dealerApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const deleteDealer = () => {
        showConfirmDialog(`Are you sure you want to delete dealer "${dealer.name}"?`).then(confirmed => {
            if (confirmed) {
                setIsLoading(true);
                dealerApi.delete(dealer.id).then(r => {
                    if (r.result === AjaxResult.Success) {
                        enqueueSnackbar(`Dealer "${dealer.name}" deleted successfully`);
                        onDealerDelete();
                    } else if (r.messages) {
                        enqueueSnackbar(r.messages.join(`\r\n`));
                    } else {
                        enqueueSnackbar(`Failed to delete dealer "${dealer.name}"!`, { variant: "error" });
                    }
                    setIsLoading(false);
                });
            }
        });
    };

    return (
        <Button
            color="secondary"
            variant="outlined"
            onClick={deleteDealer}
            disabled={isLoading || dealersAreLoading}
        >
            Delete
        </Button>
    );
}