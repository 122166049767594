import React, { useState, useEffect } from 'react';
import { FormGroup, TextField, Paper, Chip } from '@material-ui/core';

export const EmailListEntry = ({
    emailList,
    setEmails,
}: {
    emailList: string;
    setEmails: (emailList: string) => void;
}) => {
    const [inputValue, setInputValue] = useState('');
    const [values, setValues] = useState<string[]>(emailList === '' ? [] : emailList.split(';'));
    const addValue = (val: string) => {
        const newValuesArray = [...values, val.replace(';', '')];
        setValues((v) => newValuesArray);
        setEmails(newValuesArray.join(';'));
        setInputValue('');
    };
    const deleteItem = (val: string) => {
        const newValuesArray = values.filter((i) => i !== val);
        setValues(newValuesArray);
        setEmails(newValuesArray.join(';'));
    };
    useEffect(() => {
        if (emailList !== '') {
            setValues(emailList.split(';'));
        }
    }, [emailList]);
    return (
        <>
            <FormGroup>
                <TextField
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.currentTarget.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            addValue(inputValue);
                        }
                    }}
                    variant="outlined"
                    color="primary"
                />
                <Paper>
                    {values.map((value, index) => (
                        <Chip key={index} label={value} onDelete={() => deleteItem(value)} />
                    ))}
                </Paper>
            </FormGroup>
        </>
    );
};
