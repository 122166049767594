import React, { useState, useContext } from 'react';
import { AuthContext } from './authContext';
import { makeStyles, styled } from '@material-ui/styles';
import logo from '../images/paznic-logo.png';
import * as Material from '@material-ui/core';
import { AccountCircle, Lock } from '@material-ui/icons';
import { TextField } from '@material-ui/core';
import { ValidationErrors } from '../components/shared/validationErrors';
import { AjaxResult } from '../enums/ajaxResult';
import HelpModal from '../components/pages/help/helpModal';
import { HelpVideo } from '../enums/helpVideo';
import { Copyright } from '../components/Copyright';

export const LoginForm = () => {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login, loginInProgress, loginErrors, sendPasswordResetEmail } = useContext(AuthContext);
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        login({ username, password });
    };

    const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);
    const [passwordResetSucceeded, setPasswordResetSucceeded] = useState(false);
    const [resetErrors, setResetErrors] = useState<string[]>([]);
    const [resetSubmitDisabled, setResetSubmitDisabled] = useState<boolean>(false);
    const resetSubmitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        setResetErrors([]);
        setResetSubmitDisabled(true);
        sendPasswordResetEmail(username).then((r) => {
            if (r.result === AjaxResult.Success) {
                setPasswordResetSucceeded(true);
            } else if (r.result === AjaxResult.Failure && r.messages) {
                setResetErrors(r.messages);
            } else {
                setResetErrors(['Reset password failed']);
            }
            setResetSubmitDisabled(false);
        });
    };
    return (
        <Material.Dialog open={true} fullWidth={true} maxWidth="sm">
            <Material.DialogTitle>
                <LogoImage src={logo} alt="Paznic logo" />
            </Material.DialogTitle>

            <StyledDialogContent>
                {!showPasswordResetForm ? (
                    <StyledForm onSubmit={submitHandler}>
                        <div className={classes.helpLinkDiv}>
                            <div>LOGIN </div>
                            <div>
                                <HelpModal videoToFocus={HelpVideo.Login} />
                            </div>
                        </div>

                        <TextField
                            placeholder="Email Address"
                            value={username}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setUsername(e.currentTarget.value)
                            }
                            variant="outlined"
                            fullWidth={true}
                            InputProps={{
                                startAdornment: (
                                    <Material.InputAdornment position="start">
                                        <AccountCircle />
                                    </Material.InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            placeholder="Password"
                            variant="outlined"
                            type="password"
                            value={password}
                            fullWidth={true}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setPassword(e.currentTarget.value)
                            }
                            InputProps={{
                                startAdornment: (
                                    <Material.InputAdornment position="start">
                                        <Lock />
                                    </Material.InputAdornment>
                                ),
                            }}
                        />

                        {loginErrors.length > 0 && <ValidationErrors errors={loginErrors} />}

                        <Material.Button
                            disabled={loginInProgress}
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            {loginInProgress ? 'Logging in...' : 'Log In'}
                        </Material.Button>
                        <Material.Link
                            className={classes.resetPasswordLink}
                            onClick={() => {
                                setShowPasswordResetForm(true);
                            }}
                        >
                            Reset password
                        </Material.Link>
                    </StyledForm>
                ) : null}
                {showPasswordResetForm ? (
                    <>
                        {!passwordResetSucceeded ? (
                            <StyledForm onSubmit={resetSubmitHandler}>
                                <div>RESET PASSWORD</div>
                                <TextField
                                    placeholder="Email Address"
                                    value={username}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setUsername(e.currentTarget.value)
                                    }
                                    variant="outlined"
                                    fullWidth={true}
                                    InputProps={{
                                        startAdornment: (
                                            <Material.InputAdornment position="start">
                                                <AccountCircle />
                                            </Material.InputAdornment>
                                        ),
                                    }}
                                />

                                {resetErrors.length > 0 && <ValidationErrors errors={resetErrors} />}

                                <Material.Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={resetSubmitDisabled}
                                >
                                    Reset
                                </Material.Button>
                                <Material.Link
                                    className={classes.resetPasswordLink}
                                    onClick={() => {
                                        setShowPasswordResetForm(false);
                                    }}
                                >
                                    Back to login
                                </Material.Link>
                            </StyledForm>
                        ) : (
                            <div>
                                An email has been sent to {username} with a temporary password
                                you can use to log in to your account
                                <Material.Link
                                    className={classes.resetPasswordLink}
                                    onClick={() => {
                                        setShowPasswordResetForm(false);
                                    }}
                                >
                                    Back to login
                                </Material.Link>
                            </div>
                        )}
                    </>
                ) : null}
            </StyledDialogContent>

            <CopyrightContainer>
                <Copyright />
            </CopyrightContainer>
        </Material.Dialog>
    );
};

const LogoImage = styled('img')({
    display: 'block',
    margin: 'auto',
    width: '200px',
});

const StyledDialogContent = styled(Material.DialogContent)({
    margin: 'auto',
    maxWidth: '300px',
    overflowY: 'unset',
    width: '80%',
    marginBottom: "20px",
});

const StyledForm = styled("form")({
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    "& > ul": {
        margin: "0",
    }
});

const CopyrightContainer = styled("div")({
    textAlign: "center",
    fontSize: "11px",
});

const useStyles = makeStyles({
    resetPasswordLink: {
        cursor: 'pointer',
        display: 'block',
    },
    helpLinkDiv: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
    },
});
