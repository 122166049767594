import { AxiosApi, ApiResult } from './axiosApi';
import { CreateBiometricsEnrollmentDto } from '../dtos/biometrics';
import { Customer, CustomerPostDto } from '../dtos/customer';
import { CustomersRequest } from './requestObjects/customersRequest';

export class CustomerApi extends AxiosApi<Customer> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, 'customers', logout, enqueueSnackbar);
    }

    // public getCustomersForOrgBox = (orgId: number) =>
    //     this.genericRequest({
    //         method: 'GET',
    //         overridePath: `organizations/${orgId}/customers`,
    //     }) as Promise<ApiResult<Customer[]>>;

    public getCustomersForSiteBox = (orgId: number, siteId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/sites/${siteId}/customers`,
        }) as Promise<ApiResult<Customer[]>>;

    public listCustomersForOrg = (orgId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/listCustomers`,
        }) as Promise<ApiResult<Customer[]>>;

    public listCustomersForSite = (orgId: number, siteId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/sites/${siteId}/listCustomers`,
        }) as Promise<ApiResult<Customer[]>>;

    public getCustomersForOrganization = (orgId: number, options: CustomersRequest) =>
        this.genericRequest({
            method: 'POST',
            overridePath: `organizations/${orgId}/customers`,
            data: options,
        }) as Promise<ApiResult<{ customers: Customer[]; numberOfPages: number }>>;

    public getCustomersForSite = (orgId: number, siteId: number, options: CustomersRequest) =>
        this.genericRequest({
            method: 'POST',
            overridePath: `organizations/${orgId}/sites/${siteId}/customers`,
            data: options,
        }) as Promise<ApiResult<{ customers: Customer[]; numberOfPages: number }>>;

    // public getActiveForSite = (orgId: number, siteId: number) =>
    //     this.genericRequest({
    //         method: 'GET',
    //         overridePath: `organizations/${orgId}/sites/${siteId}/activeCustomers`,
    //     }) as Promise<ApiResult<Customer[]>>;

    public post = (customer: CustomerPostDto) =>
        this.genericRequest({ method: 'POST', data: customer }) as Promise<ApiResult<Customer>>;

    public put = (customerId: number, customer: CustomerPostDto) =>
        this.genericRequest({
            extraPath: `${customerId}`,
            method: 'PUT',
            data: customer,
        }) as Promise<ApiResult<Customer>>;

    public createFingerprintEnrollment = (
        customerId: number,
        biometricsEnrollmentDto: CreateBiometricsEnrollmentDto
    ) =>
        this.genericRequest({
            method: 'POST',
            overridePath: `customers/${customerId}/fingerprint`,
            data: biometricsEnrollmentDto,
        }) as Promise<ApiResult<{ requestReference?: string; temporaryCode?: string; }>>;

    public getNameMatches = (searchText: string, orgId?: number, siteId?: number) => {
        const overridePath = `${orgId ? `organizations/${orgId}/` : ''}${siteId ? `sites/${siteId}/` : ''
            }customers/filter/${searchText}`;
        return this.genericRequest({ method: 'GET', overridePath }) as Promise<ApiResult<string[]>>;
    };
}
