import { AxiosApi, ApiResult } from './axiosApi';
import { Organization } from '../dtos/organization';
import { OrganizationAggregate } from '../dtos/organizationAggregate';

export class OrgApi extends AxiosApi<Organization> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, 'organization', logout, enqueueSnackbar);
    }

    public updateOrg = (orgId: number, ordDto: { name: string; }) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${orgId}`,
            data: ordDto,
        }) as Promise<ApiResult<any>>;

    public getForDealer = (dealerId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `dealers/${dealerId}/organizations`,
        }) as Promise<ApiResult<Organization[]>>;

    public getForDealerAggregate = (dealerId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `dealers/${dealerId}/organizations/aggregate`,
        }) as Promise<ApiResult<OrganizationAggregate[]>>;

    public getAggregate = () =>
        this.genericRequest({
            method: 'GET',
            extraPath: 'aggregate',
        }) as Promise<ApiResult<OrganizationAggregate[]>>;

    public getForOrgIdAggregate = (orgId: number) =>
        this.genericRequest({
            method: 'GET',
            extraPath: `${orgId}/organizations/aggregate`,
        }) as Promise<ApiResult<OrganizationAggregate[]>>;

    public activate = (orgId: number) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${orgId}/activate`,
        }) as Promise<ApiResult<unknown>>;

    public deactivate = (orgId: number) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${orgId}/deactivate`,
        }) as Promise<ApiResult<unknown>>;
}
