import React, { useState, ChangeEvent, useCallback } from 'react';
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const SearchBar = ({
    defaultSearchText,
    search,
    labelText,
    onReset,
}: {
    defaultSearchText: string;
    search: (text: string) => void;
    labelText?: string;
    onReset?: () => void;
}) => {
    const classes = useStyles();

    const [searchText, setSearchText] = useState(defaultSearchText);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    }, []);

    const handleSubmit = useCallback((e: React.FormEvent) => {
        e.preventDefault();
        search(searchText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const handleReset = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setSearchText("");
        search("");
        if (onReset) onReset();
    }, [search, onReset]);

    return (
        <div>
            <form onSubmit={handleSubmit} className={classes.searchBar}>
                <TextField
                    label={labelText || "Search Log..."}
                    value={searchText}
                    onChange={handleChange}
                    variant="outlined"
                />

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Search
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={handleReset}
                >
                    Reset
                </Button>
            </form>
        </div>
    );
};

const useStyles = makeStyles({
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '10px',
    },
});
