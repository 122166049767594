import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { Dealer } from "../../../dtos/dealer";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useApi } from "../../useApi";

interface Props {
    dealer: Dealer;
    onDealerActivationChange: () => void;
    dealersAreLoading: boolean;
}

export function ActivationButton({ dealer, onDealerActivationChange, dealersAreLoading }: Props) {
    const { dealerApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const { showConfirmDialog } = useConfirmDialog();

    const activateDealer = () => {
        showConfirmDialog(`Are you sure you want do activate dealer "${dealer.name}"?`).then(
            (confirmed) => {
                if (confirmed) {
                    setIsLoading(true);
                    dealerApi.activate(dealer.id).then(r => {
                        if (r.result === AjaxResult.Success) {
                            onDealerActivationChange()
                        } else {
                            enqueueSnackbar(`Failed to activate dealer "${dealer.name}"`, { variant: "error" })
                        }
                        setIsLoading(false);
                    });
                }
            }
        );
    }

    const deactivateDealer = () => {
        showConfirmDialog(`Are you sure you want do deactivate dealer "${dealer.name}"?`).then(
            (confirmed) => {
                if (confirmed) {
                    setIsLoading(true);
                    dealerApi.deactivate(dealer.id).then(r => {
                        if (r.result === AjaxResult.Success) {
                            onDealerActivationChange()
                        } else {
                            enqueueSnackbar(`Failed to deactivate dealer "${dealer.name}"`, { variant: "error" })
                        }
                        setIsLoading(false);
                    });
                }
            }
        );
    }

    if (dealer.isInactive === false) {
        return (
            <Button
                disabled={isLoading || dealersAreLoading}
                onClick={deactivateDealer}
                variant="outlined"
                color="secondary"
            >
                Deactivate
            </Button>
        );
    }

    return (
        <Button
            disabled={isLoading || dealersAreLoading}
            onClick={activateDealer}
            variant="outlined"
            color="primary"
        >
            Activate
        </Button>
    );
}
