import { useEffect, useMemo, useState } from "react";

import { AjaxResult } from "../enums/ajaxResult";
import { Site } from "../dtos/site";
import { useApi } from "../components/useApi";

export function useSitesWithUserActiveLeases(
    user: { id: number; activeLeaseSiteIds?: number[] } | undefined,
    orgId: number | undefined,
) {
    const { siteApi } = useApi();
    const [orgSites, setOrgSites] = useState<Site[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (user && orgId) {
            setLoading(true);
            siteApi.getForOrg(orgId).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    setOrgSites(r.data);
                }
                setLoading(false)
            });
        }
    }, [orgId, siteApi, user]);

    const sites = useMemo(() => {
        return orgSites.filter(s => (
            user !== undefined && user.activeLeaseSiteIds
                ? user.activeLeaseSiteIds.findIndex(sId => sId === s.id) !== -1
                : true
        ));
    }, [orgSites, user]);

    return { loading, sites };
}
