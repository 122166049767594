import React, { useState, useEffect, useContext } from 'react';
import { PageHeader } from '../../../PageHeader';
import { Button, Checkbox, TextField, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Site, SiteDto } from '../../../../dtos/site';
import { useApi } from '../../../useApi';
import { useRouterWithTypedParams } from '../../../../hooks/useRouterWithTypedParams';
import { AjaxResult } from '../../../../enums/ajaxResult';
import { useSnackbar } from 'notistack';
import { ValidationErrors } from '../../../shared/validationErrors';
import { EmailListEntry } from '../emailListEntry';
import { FormField } from '../../../shared/formField';
import { TimeZone } from '../../../../enums/timeZone';
import { AuthContext } from '../../../../auth/authContext';
import { TimeZoneSelect } from '../TimeZoneSelect';

import { MaintenanceWindowConfiguration } from './MaintenanceWindowConfiguration';
import { TroubleshootSection } from './TroubleshootSection';

export const SiteConfigPage = () => {
    const classes = useStyles();
    const { isGlobalAdmin, isDealerAdmin } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const { match } = useRouterWithTypedParams<{ siteId?: number }>();
    const siteId = match.params.siteId;
    const { siteApi } = useApi();
    const [site, setSite] = useState<Site>();
    useEffect(() => {
        if (!siteId) {
            return;
        }
        siteApi.getById(siteId).then((r) => {
            if (r.result === AjaxResult.Success && r.data) {
                setSite(r.data);
            }
        });
    }, [setSite, siteApi, siteId]);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const updateSite = () => {
        if (!siteId) {
            return;
        }
        siteApi.updateSite(siteId, { ...site } as SiteDto).then((r) => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar("Successfully updated site configuration", { variant: "success" });
                setValidationErrors([]);
            } else if (r.result === AjaxResult.Duplicate) {
                enqueueSnackbar("Cannot use that Reference Site ID for this site, already used", { variant: "error" });
            } else if (r.messages) {
                setValidationErrors(r.messages);
            } else {
                enqueueSnackbar("Failed to update configuration for site", { variant: "error" });
            }
        });
    };

    const showTroubleshootSection = isGlobalAdmin || isDealerAdmin;

    return (
        <>
            <PageHeader>Site Configuration: {site && site.name}</PageHeader>
            <div className={classes.emailFieldContainer}>
                <FormField label="Site Name">
                    <FormControl>
                        <TextField
                            value={site ? site.name : ''}
                            onChange={(event) => {
                                if (site !== undefined) {
                                    setSite({ ...site, name: event.currentTarget.value });
                                }
                            }}
                            InputProps={{
                                inputProps: { className: classes.input },
                            }}
                        />
                    </FormControl>
                </FormField>
                <FormField label="Reference Site ID">
                    <FormControl className={classes.uniqueIdContainer}>
                        <TextField
                            value={site ? site.referenceSiteId : ''}
                            onChange={(event) => {
                                if (site !== undefined) {
                                    setSite({ ...site, referenceSiteId: event.currentTarget.value });
                                }
                            }}
                            InputProps={{
                                inputProps: { className: classes.input },
                            }}
                        />
                    </FormControl>
                </FormField>
                <FormField label="Email Addresses">
                    <EmailListEntry
                        emailList={site && site.emailAddresses ? site.emailAddresses : ''}
                        setEmails={(emailList: string) => {
                            if (site !== undefined) {
                                setSite({ ...site, emailAddresses: emailList });
                            }
                        }}
                    />
                </FormField>
                <FormField label="Time zone">
                    <FormControl className={classes.timeZoneSelect}>
                        <TimeZoneSelect
                            value={site ? site.timeZone as TimeZone : TimeZone.UTC}
                            onChange={(timeZone) => {
                                if (site === undefined) return;
                                setSite({ ...site, timeZone });
                            }}
                            inputProps={{ className: classes.input }}
                        />
                    </FormControl>
                </FormField>
                <FormField label="Email Alerts Enabled">
                    <Checkbox
                        checked={site ? site.emailAlertsEnabled : false}
                        color={'primary'}
                        onChange={(event) => {
                            if (site !== undefined) {
                                setSite({ ...site, emailAlertsEnabled: event.target.checked });
                            }
                        }}
                    />
                </FormField>
            </div>

            {site && isGlobalAdmin && (
                <>
                    <p>Maintenance Window:</p>
                    <MaintenanceWindowConfiguration
                        configuration={site.maintenanceWindow ? site.maintenanceWindow : undefined}
                        setConfiguration={(conf) => setSite({ ...site, maintenanceWindow: conf })}
                    />
                </>
            )}

            <Button color="primary" variant="contained" onClick={updateSite}>
                Save
            </Button>
            <div>{validationErrors ? <ValidationErrors errors={validationErrors} /> : null}</div>

            {showTroubleshootSection && site && <TroubleshootSection site={site} />}
        </>
    );
};

const useStyles = makeStyles({
    emailFieldContainer: {
        maxWidth: '800px',
        width: '50%',
    },
    emailTextBox: {
        width: '100%',
    },
    input: {
        padding: '5px',
    },
    uniqueIdContainer: {
        width: '50%',
    },
    timeZoneSelect: {
        width: '50%',
        flexGrow: 2,
        marginLeft: '10px',
    },
});
