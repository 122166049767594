import { makeStyles } from "@material-ui/styles";
import { Dialog, DialogContent, DialogActions, Button, TextField, FormControl } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useContext } from "react";

import { FormSection } from "../../components/shared/formSection";
import { FormField } from "../../components/shared/formField";
import { ValidationErrors } from "../../components/shared/validationErrors";
import { AjaxResult } from "../../enums/ajaxResult";
import { AuthContext } from "../authContext";

import { PasswordComplexityLevel, validatePasswordComplexity } from "./PasswordComplexityLevel";
import { PasswordComplexityHint } from "./PasswordComplexityHint";


export const ChangePasswordDialog = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [failedComplexityLevel, setFailedComplexityLevel] = useState<PasswordComplexityLevel>();
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        changePasswordDialogIsVisible,
        setChangePasswordDialogIsVisible,
        authApi,
        email: emailFromContext
    } = useContext(AuthContext);

    useEffect(() => {
        if (emailFromContext) {
            setEmail(emailFromContext);
        }
    }, [emailFromContext]);

    const closeForm = () => setChangePasswordDialogIsVisible(false);

    const validate = async (): Promise<boolean> => {
        const complexityLevel = validatePasswordComplexity(newPassword);
        if (complexityLevel) {
            setErrors(["Insufficient password complexity"]);
            setFailedComplexityLevel(complexityLevel);
            return false;
        } else {
            setFailedComplexityLevel(undefined);
        }

        if (newPassword !== confirmPassword) {
            setErrors(['New password and "confirm" password don\'t match']);
            return false;
        }
        setErrors([]);
        return true;
    };

    useEffect(() => {
        if (newPassword.length > 0 || confirmPassword.length > 0) {
            validate();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPassword, confirmPassword]); 

    const save = () => {
        if (!validate()) return;

        setIsLoading(true);
        authApi.changePassword(email, password, newPassword).then(r => {
            if (r.result === AjaxResult.Success) {
                setErrors([]);
                enqueueSnackbar("Successfully changed password!");
                setPassword("");
                setConfirmPassword("");
                setNewPassword("");
                closeForm();
            } else if (r.messages) {
                setErrors(r.messages);
            } else {
                enqueueSnackbar("ERROR: Failed to change password");
            }
            setIsLoading(false);
        });
    };

    return (
        <Dialog open={changePasswordDialogIsVisible} onClose={closeForm}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                }}
            >
                <DialogContent className={classes.dialogContent}>
                    <FormSection headerText={"Change Password"} labelMinWidth={200}>
                            <FormField label="Email">
                                <FormControl>
                                    <TextField
                                        value={email}
                                        onChange={e => setEmail(e.currentTarget.value)}
                                        variant="outlined"
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                    />
                                </FormControl>
                            </FormField>

                            <FormField label="Current Password">
                                <FormControl>
                                    <TextField
                                        type="password"
                                        value={password}
                                        onChange={e => setPassword(e.currentTarget.value)}
                                        variant="outlined"
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                        autoFocus
                                    />
                                </FormControl>
                            </FormField>

                            <FormField label="New Password">
                                <FormControl>
                                    <TextField
                                        type="password"
                                        value={newPassword}
                                        onChange={e => setNewPassword(e.currentTarget.value)}
                                        variant="outlined"
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                    />
                                </FormControl>
                            </FormField>

                            <PasswordComplexityHint
                                failedComplexityLevel={failedComplexityLevel}
                                password={newPassword}
                            />

                            <FormField label="Confirm New Password">
                                <FormControl>
                                    <TextField
                                        type="password"
                                        value={confirmPassword}
                                        onChange={e => setConfirmPassword(e.currentTarget.value)}
                                        variant="outlined"
                                        InputProps={{
                                            inputProps: { className: classes.input },
                                        }}
                                    />
                                </FormControl>
                            </FormField>
                    </FormSection>
                    <ValidationErrors errors={errors} />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={isLoading || errors.length > 0}
                        onClick={save}
                        type="submit"
                    >
                        Change Password
                    </Button>
                    <Button color="secondary" variant="contained" onClick={closeForm}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const useStyles = makeStyles({
    dialogContent: {
        minWidth: "700px",
    },
    input: {
        padding: "5px",
    },
});
