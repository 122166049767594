import { Button } from "@material-ui/core";
import React, { useState } from "react";

import { Organization } from "../../../dtos/organization";

import { OrgEditForm } from "./OrgEditForm";

interface Props {
    org: Organization;
    onLoadOrgs: () => void;
    orgsAreLoading: boolean;
}

export function EditOrgButton({ org, onLoadOrgs, orgsAreLoading }: Props) {
    const [showEditForm, setShowEditForm] = useState(false);

    return (
        <>
            <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowEditForm(true)}
                disabled={orgsAreLoading}
            >
                Edit
            </Button>

            {showEditForm && (
                <OrgEditForm
                    org={org}
                    dealerId={org.dealerId}
                    onClose={() => setShowEditForm(false)}
                    onLoadOrgs={onLoadOrgs}
                />
            )}
        </>
    );
}
