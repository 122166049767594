export enum RoomStatusAlarmName {
    UnauthorizedBoxOpening = 0,
    MotionStoppedDuringSession = 10,
    DoorLeftOpen = 20,
    UnauthorizedDoorOpening = 30,
    NoBoxOpened = 40,
    BoxLeftOpen = 41,
    UnauthorizedMotionDetected = 50,
}

export const RoomStatusAlarmNameDisplay = new Map([
    [RoomStatusAlarmName.UnauthorizedBoxOpening, 'Unauthorized Box Opening'],
    [RoomStatusAlarmName.MotionStoppedDuringSession, 'Motion Stopped During Session'],
    [RoomStatusAlarmName.DoorLeftOpen, 'Door Left Open'],
    [RoomStatusAlarmName.UnauthorizedDoorOpening, 'Unauthorized Door Opening'],
    [RoomStatusAlarmName.NoBoxOpened, 'No Box Opened'],
    [RoomStatusAlarmName.BoxLeftOpen, 'Box Left Open'],
    [RoomStatusAlarmName.UnauthorizedMotionDetected, 'Unauthorized Motion'],
]);
