import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App';
import './index.scss';
import { HomePage } from './components/pages/homePage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { BoxesPage } from './components/pages/boxes';
import { CustomersPageAdmin } from './components/pages/customers';
import { ManualOverridePage } from './components/pages/manualOverride';
import { EmployeesPage } from './components/pages/employees';
import { BoxDetailPage } from './components/pages/boxes/detail';
import { ThemeProvider } from '@material-ui/styles';
import { UI_THEME } from './constants';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import ReportsPage from './components/pages/events';
import { OrganizationsPage } from './components/pages/orgs';
import AlarmsPageContainer from './components/pages/alarms';
import { SitesPage } from './components/pages/sites';
import { DealersPage } from './components/pages/dealers';
import { SiteConfigPage } from './components/pages/sites/SiteConfigPage';
import { PasswordResetPage } from './auth/passwordResetPage';
import { ScheduledJobsPage } from './components/pages/ScheduledJobsPage';

const theme = createMuiTheme({
    ...UI_THEME,
});

const OrgBasedRoute = ({
    resource,
    component,
}: {
    resource: string;
    component: () => JSX.Element;
}) => (
    <>
        <Route exact path={`/${resource}`} component={component} />
        <Route exact path={`/dealers/:dealerId/${resource}`} component={component} />
        <Route exact path={`/dealers/:dealerId/sites/:siteId/${resource}`} component={component} />
        <Route exact path={`/dealers/:dealerId/organizations/:orgId/${resource}`} component={component} />
        <Route
            exact
            path={`/dealers/:dealerId/organizations/:orgId/sites/:siteId/${resource}`}
            component={component}
        />
        <Route exact path={`/organizations/:orgId/${resource}`} component={component} />
        <Route exact path={`/organizations/:orgId/sites/:siteId/${resource}`} component={component} />
    </>
);

ReactDOM.render(
    <Router>
        <ThemeProvider theme={theme}>
            <App>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/resetPassword" component={PasswordResetPage} />
                <OrgBasedRoute resource="home" component={HomePage} />
                <OrgBasedRoute resource="scheduled-jobs" component={ScheduledJobsPage} />
                <OrgBasedRoute resource="dealers" component={DealersPage} />
                <OrgBasedRoute resource="reports" component={ReportsPage} />
                <OrgBasedRoute resource="alarms" component={AlarmsPageContainer} />
                <OrgBasedRoute resource="boxes" component={BoxesPage} />
                <OrgBasedRoute resource="manual-override" component={ManualOverridePage} />
                <OrgBasedRoute resource="boxDetail/:boxId" component={BoxDetailPage} />
                <OrgBasedRoute resource="customers" component={CustomersPageAdmin} />
                <OrgBasedRoute resource="employees" component={EmployeesPage} />
                <OrgBasedRoute resource="organizations" component={OrganizationsPage} />
                <OrgBasedRoute resource="sites" component={SitesPage} />
                <OrgBasedRoute resource="sites/commission/:guid" component={SitesPage} />
                <OrgBasedRoute resource="config" component={SiteConfigPage} />
            </App>
        </ThemeProvider>
    </Router>,
    document.getElementById('root')
);
