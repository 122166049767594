import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";

import { ScheduledJob, ScheduledJobBase } from "../../../../dtos/scheduledJob";
import { ScheduledJobType } from "../../../../enums/ScheduledJobType";
import { useApi } from "../../../useApi";
import { AjaxResult } from "../../../../enums/ajaxResult";

const INIT_JOB_BASE: ScheduledJobBase = {
    name: "unnamed job",
    type: ScheduledJobType.SystemRestart,
    targets: [],
}

export function useAddScheduledJob(onJobAdded: (job: ScheduledJob) => void): {
    add: () => void;
    loading: boolean;
    jobBase: ScheduledJobBase;
    setJobBase: (jobBase: ScheduledJobBase) => void;
} {
    const { scheduledJobsApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const [jobBase, setJobBase] = useState<ScheduledJobBase>(INIT_JOB_BASE);
    const [loading, setLoading] = useState(false);

    const add = useCallback(() => {
        setLoading(true);
        scheduledJobsApi.post(jobBase).then(({ result, data: createdJob }) => {
            if (result === AjaxResult.Success && createdJob) {
                onJobAdded(createdJob);
                setJobBase(INIT_JOB_BASE);
            } else {
                enqueueSnackbar("Failed to create scheduled job", { variant: "error" });
            }
            setLoading(false);
        });
    }, [enqueueSnackbar, scheduledJobsApi, jobBase, onJobAdded]);

    return { add, loading, jobBase, setJobBase };
}