import { Icon, Paper, makeStyles } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import React, { useContext } from "react";

import SignalRContext from "../../signalr/context";

import { useSite } from "./useSite";

const useStyles = makeStyles(() => ({
    container: {
        alignSelf: "stretch",
        padding: "10px",
        minWidth: "400px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        backgroundColor: "#fff9db",
        border: "1px solid #ffe066",
        borderRadius: "4px",
    },
    message: {
        margin: 0,
    },
}));

export function SiteOfflineBanner() {
    const classes = useStyles();
    const site = useSite()
    const { serviceStatus } = useContext(SignalRContext);

    if (!site) return null;

    if (serviceStatus.get(site.id)) return null;

    return (
        <Paper className={classes.container} elevation={0}>
            <Icon>
                <WarningRounded />
            </Icon>
            <div>
                <p className={classes.message}>
                    {`It seems that Site ${site.name} ${site.uniqueSiteId} is currently offline or disconnected from the platform. `}
                    You may encounter limited functionality for certain features.
                </p>
            </div>
        </Paper>
    );
}