import { ActiveStatus } from "../enums/ActiveStatus";

export function checkIfUserCanActivate(
    status: ActiveStatus | undefined,
    isDealerAdmin: boolean,
    isGlobalAdmin: boolean,
    isOrgAdmin: boolean,
): boolean {
    switch (status) {
        case ActiveStatus.DeactivatedByAdmin:
            return isGlobalAdmin;
        case ActiveStatus.DeactivatedByDealer:
            return isGlobalAdmin || isDealerAdmin;
        case ActiveStatus.DeactivatedByOrganization:
            return isGlobalAdmin || isDealerAdmin || isOrgAdmin;
        default:
            return false;
    }
}