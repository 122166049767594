import { makeStyles } from "@material-ui/core";
import { CloudDone, CloudOff } from "@material-ui/icons";
import React, { useCallback, useContext, useEffect } from "react";
import { ValueType } from "react-select/src/types";

import SignalRContext from "../../../../signalr/context";
import { DialogSelect } from "../../dialogSelect";
import { FormField } from "../../formField";

import { useSiteOptions } from "./useSiteOptions";
import { useUserSiteOption } from "./useUserSiteOption";

const useStyles = makeStyles(theme => ({
    customOptionLabel: {
        display: "flex",
        gap: "15px",
        alignItems: "center",
        "& svg": {
            color: theme.fontThemeColor2,
        },
    },
}));

interface Props {
    selectedSiteId: number | null;
    handleChange: (value: ValueType<{ value: number; label: string }>) => void;
    organizationId: number | null;
    showOptionAll: boolean;
    showLabel: boolean;
    fullWidth: boolean;
    disabled: boolean;
    className?: string | undefined;
}

export function SiteSelect({
    selectedSiteId,
    handleChange,
    organizationId,
    showLabel,
    showOptionAll,
    fullWidth,
    disabled,
    className,
}: Props) {
    const classes = useStyles();
    const { serviceStatus } = useContext(SignalRContext);
    const {
        selectedSiteOption,
        siteOptions,
        isLoading,
    } = useSiteOptions(selectedSiteId, organizationId, showOptionAll);
    const userSiteOption = useUserSiteOption();

    const formatOptionLabel = useCallback(({ label, value }: { label: string; value: number; }) => {
        if (value === 0) return label;

        return (
            <div className={classes.customOptionLabel}>
                {serviceStatus.get(value)
                    ? <CloudDone fontSize="small" />
                    : <CloudOff fontSize="small" />}
                <div>{label}</div>
            </div>
        );
    }, [serviceStatus, classes.customOptionLabel]);

    useEffect(() => {
        if (userSiteOption) {
            handleChange(userSiteOption);
        }
    }, [userSiteOption]);

    return (
        <FormField label={showLabel ? "Site" : undefined}>
            <DialogSelect
                key={JSON.stringify(selectedSiteOption)} // this fixes Dialog rerendering on second pass of Undefined option
                options={siteOptions}
                value={selectedSiteOption}
                isDisabled={disabled}
                isClearable
                onChange={handleChange}
                placeholder="Select site..."
                fullWidth={fullWidth}
                className={className}
                formatOptionLabel={formatOptionLabel}
                isLoading={isLoading}
            />
        </FormField>
    );
}
