import { AxiosApi } from './axiosApi';
import { Lease } from '../dtos/lease';

export class LeaseApi extends AxiosApi<Lease> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, 'leases', logout, enqueueSnackbar);
    }

    public changeLeaseEndDate = (boxId: number, endDate: Date | null | undefined) =>
        this.genericRequest({
            method: 'PUT',
            data: { endDate: endDate ? endDate.toISOString() : null },
            overridePath: `boxes/${boxId}/changeLeaseEndDate`,
        });
}
