import { Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";

import { Dealer, DealerPostDto } from "../../../dtos/dealer";
import { AjaxResult } from "../../../enums/ajaxResult";
import { FormSection } from "../../shared/formSection";
import { FormField } from "../../shared/formField";
import { TextField } from "../../shared/TextField";
import { useApi } from "../../useApi";

interface Props {
    dealer?: Dealer;
    onClose: () => void;
    onLoadDealers: () => void;
}

export const DealerEditForm = ({ onClose, onLoadDealers, dealer }: Props) => {
    const { dealerApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [dealerData, setDealerData] = useState<DealerPostDto>({
        name: dealer && dealer.name ? dealer.name : "",
    });
    const [loading, setLoading] = useState(false);

    const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setDealerData(prev => ({ ...prev, name: e.target.value }));
    }, []);

    const handleSave = useCallback(() => {
        const apiMethod = dealer
            ? () => dealerApi.updateDealer(dealer.id, dealerData)
            : () => dealerApi.createDealer(dealerData);
        setLoading(true);
        apiMethod().then(r => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar(
                    `Successfully ${dealer ? "updated" : "created new"} dealer: "${dealerData.name}"`,
                    { variant: "success" },
                );
                onLoadDealers();
                onClose();
            } else {
                enqueueSnackbar(
                    `Failed to ${dealer ? "update" : "create new"} dealer: "${dealerData.name}"`,
                    { variant: "error" },
                );
            }
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealerApi, dealerData, dealer]);

    return (
        <Dialog
            open={true}
            onClose={onClose}
            maxWidth="sm"
        >
            <DialogContent>
                <FormSection headerText={dealer ? `Edit Dealer "${dealer.name}"` : "Create New Dealer"}>
                    <FormField label="Dealer Name" required>
                        <TextField
                            placeholder="Dealer Name"
                            value={dealerData.name}
                            onChange={handleChangeName}
                            variant="outlined"
                            stylesProps={{ width: 300 }}
                        />
                    </FormField>
                </FormSection>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={loading || dealerData.name === ""}
                >
                    {dealer
                        ? loading
                            ? "Saving..."
                            : "Save"
                        : loading
                            ? "Creating..."
                            : "Create"
                    }
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
