import { Button } from "@material-ui/core";
import React, { useState } from "react";

import { ScheduledJob } from "../../../../dtos/scheduledJob";
import { SCHEDULED_JOB_TYPES, ScheduledJobType } from "../../../../enums/ScheduledJobType";

import { AddScheduledJobDialog } from "./Dialog";

interface Props {
    onJobAdded: (job: ScheduledJob) => void;
    addedJobTypes: ScheduledJobType[];
}

export function AddScheduledJobButton({ onJobAdded, addedJobTypes }: Props) {
    const [openDialog, setOpenDialog] = useState(false);

    return <>
        <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
            style={{ marginBottom: "10px" }}
            disabled={addedJobTypes.length === SCHEDULED_JOB_TYPES.length}
        >
            Add New Job
        </Button>

        <AddScheduledJobDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            onJobAdded={(job) => {
                setOpenDialog(false);
                onJobAdded(job);
            }}
            addedJobTypes={addedJobTypes}
        />
    </>;
}