import { MenuItem, Select } from "@material-ui/core";
import { InputBaseComponentProps } from "@material-ui/core/InputBase";
import React, { ChangeEvent } from "react";

import { TIME_ZONE_LABELS, TimeZone } from "../../../enums/timeZone";

const TIME_ZONES_FOR_SELECT = [
    TimeZone.UTC,
    TimeZone.NewfoundlandStandardTime,
    TimeZone.AtlanticStandardTime,
    TimeZone.EasternStandardTime,
    TimeZone.CanadaCentralStandardTime,
    TimeZone.CentralStandardTime,
    TimeZone.USMountainStandardTime,
    TimeZone.MountainStandardTime,
    TimeZone.PacificStandardTime,
    TimeZone.AlaskanStandardTime,
    TimeZone.HawaiianStandardTime,
] as const;

interface Props {
    value: TimeZone;
    onChange: (value: TimeZone) => void;
    inputProps?: InputBaseComponentProps | undefined;
}

export function TimeZoneSelect({
    value,
    onChange,
    inputProps,
}: Props) {
    function handleChange(event: ChangeEvent<{ value: unknown; }>) {
        const newValue = event.target.value as TimeZone;
        onChange(newValue);
    }

    return (
        <Select
            inputProps={inputProps}
            value={value}
            onChange={handleChange}
        >
            {TIME_ZONES_FOR_SELECT.map((tz) => (
                <MenuItem key={tz} value={tz}>
                    {TIME_ZONE_LABELS[tz]}
                </MenuItem>
            ))}
        </Select>
    );
}
