import { Link } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { navLinkUrl } from "../../../utils";

interface Props {
    dealerId: number | null;
    orgId: number | null;
    siteId: number | null;
}

export function ReportsLink({ dealerId, orgId, siteId }: Props) {
    return (
        <div>
            <Link
                component={RouterLink}
                to={navLinkUrl("reports", dealerId, orgId, siteId)}
            >
                Latest Events
            </Link>
        </div>
    );
}
