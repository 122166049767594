import { Chip, Link } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { FullSite } from "../../../../dtos/site";
import { hubConnection } from "../../../../signalr/provider";


interface Props {
    site: FullSite;
    dealerId: number | null;
}

export function SiteRoomAlarmIndicator({
    site,
    dealerId,
}: Props) {
    const [isAlarmActive, setIsAlarmActive] = useState(site.activeAlarm);

    useEffect(() => {
        const callback = (
            eventSiteId: number,
            eventAlarmActive: boolean,
        ) => {
            if (eventSiteId !== site.id) return;
            setIsAlarmActive(eventAlarmActive);
        };

        hubConnection.on("alarmStateChanged", callback);

        return () => {
            hubConnection.off("alarmStateChanged", callback);
        };
    }, [site.id]);

    return (
        <Link
            component={RouterLink}
            to={`/dealers/${dealerId}/organizations/${site.organizationId}/sites/${site.id}/alarms`}
        >
            {isAlarmActive
                ? (
                    <Chip
                        variant="outlined"
                        color="secondary"
                        label="Active Alarm"
                    />
                )
                : (
                    <Chip
                        variant="outlined"
                        color="primary"
                        label="No alarms"
                    />
                )
            }
        </Link>
    );
}
