import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { ScheduledJob } from "../../../../dtos/scheduledJob";
import { AjaxResult } from "../../../../enums/ajaxResult";
import { useConfirmDialog } from "../../../../hooks/useConfirmDialog";
import { useApi } from "../../../useApi";

interface Props {
    job: ScheduledJob;
    onJobDeleted: (id: number) => void;
}

export function DeleteScheduledJobButton({ job, onJobDeleted }: Props) {
    const { showConfirmDialog } = useConfirmDialog();
    const { scheduledJobsApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const deleteWithConfirm = () => {
        showConfirmDialog(`Are you sure you want to delete job "${job.name}"?`)
            .then((confirmed) => {
                if (confirmed) {
                    setLoading(true);
                    scheduledJobsApi.delete(job.id).then((r) => {
                        if (r.result === AjaxResult.Success) {
                            onJobDeleted(job.id);
                        } else {
                            enqueueSnackbar("Failed to delete scheduled job", { variant: "error" });
                        }
                        setLoading(false);
                    });
                }
            });
    };
    return <>
        <Button
            variant="outlined"
            color="secondary"
            onClick={deleteWithConfirm}
            disabled={loading}
        >
            {loading ? "Deleting..." : "Delete"}
        </Button>
    </>;
}