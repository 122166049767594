import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { useApi } from "../components/useApi";
import { FullSite } from "../dtos/site";
import { AjaxResult } from "../enums/ajaxResult";

interface Props {
    dealerId: number | undefined | null,
    orgId: number | undefined | null,
    siteId: number | undefined | null,
    loadAll?: boolean,
    disabled?: boolean,
}

export function useFullSitesLoader({ dealerId, siteId, orgId, disabled = false }: Props): {
    sites: FullSite[],
    setSites: (value: React.SetStateAction<FullSite[]>) => void,
    loading: boolean;
 } {
    const { siteApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();

    const [sites, setSites] = useState<FullSite[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (disabled) return;
        let cancel = false;
        setLoading(true);
        if (orgId && siteId) {
            siteApi.getFullSiteById(siteId).then((r) => {
                if (cancel) return;
                if (r.result === AjaxResult.Success && r.data) {
                    setSites([r.data]);
                }
                setLoading(false);
            });
        } else if (orgId) {
            siteApi.getForOrgFull(orgId).then((r) => {
                if (cancel) return;
                if (r.result === AjaxResult.Success && r.data) {
                    setSites(r.data);
                } else {
                    setSites([]);
                    enqueueSnackbar('ERROR: Failed to get sites from the server');
                }
                setLoading(false);
            });
        } else if (dealerId) {
            siteApi.getForDealerFull(dealerId).then((r) => {
                if (cancel) return;
                if (r.result === AjaxResult.Success && r.data) {
                    setSites(r.data);
                } else {
                    setSites([]);
                    enqueueSnackbar('ERROR: Failed to get sites from the server');
                }
                setLoading(false);
            });
        } else {
            siteApi.getAllFull().then((r) => {
                if (cancel) return;
                if (r.result === AjaxResult.Success && r.data) {
                    setSites(r.data);
                } else {
                    setSites([]);
                    enqueueSnackbar('ERROR: Failed to get sites from the server');
                }
                setLoading(false);
            });
        }

        return () => {
            cancel = true;
        };

    }, [dealerId, orgId, siteId, siteApi, enqueueSnackbar, disabled]);

    return { sites, setSites, loading };
}
