import { AxiosApi, ApiResult } from './axiosApi';
import { FullSite, Site, SiteDto } from '../dtos/site';
import { SiteAggregate } from '../dtos/siteAggregate';
import { Service } from '../dtos/service';

export class SiteApi extends AxiosApi<Site> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, 'sites', logout, enqueueSnackbar);
    }

    public getSitesAggregateForOrg = (orgId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/sites/aggregate`,
        }) as Promise<ApiResult<SiteAggregate[]>>;

    public getSitesAggregateForSite = (orgId: number, siteId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/sites/${siteId}/aggregate`,
        }) as Promise<ApiResult<SiteAggregate[]>>;

    public getForDealerAggregate = (dealerId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `dealers/${dealerId}/sites/aggregate`,
        });

    public getAvailableServices = () =>
        this.genericRequest({
            method: 'GET',
            overridePath: `services`,
        }) as Promise<ApiResult<Service[]>>;

    public manualOverride = (siteId: number, customerId: number) =>
        this.genericRequest({
            method: 'POST',
            extraPath: `${siteId}/manualoverride/${customerId}`,
        }) as Promise<ApiResult<{ requestReference?: string; temporaryCode?: string; }>>;

    public endSession = (siteId: number) =>
        this.genericRequest({ method: 'PUT', extraPath: `${siteId}/endsession` }) as Promise<
            ApiResult<any>
        >;

    public post = (site: SiteDto) =>
        this.genericRequest({ method: 'POST', data: site }) as Promise<ApiResult<Site>>;

    public updateSite = (siteId: number, siteDto: SiteDto) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${siteId}`,
            data: siteDto,
        }) as Promise<ApiResult<any>>;

    public getAllFull = () =>
        this.genericRequest({ method: 'GET', extraPath: `full` }) as Promise<ApiResult<FullSite[]>>;

    public getFullSiteById = (siteId: number) =>
        this.genericRequest({ method: 'GET', overridePath: `sites/${siteId}/full` }) as Promise<
            ApiResult<FullSite>
        >;

    public getForDealerFull = (dealerId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `dealers/${dealerId}/sites/full`,
        }) as Promise<ApiResult<FullSite[]>>;

    public getForOrgFull = (orgId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/sites/full`,
        }) as Promise<ApiResult<FullSite[]>>;

    public resetSensor = (siteId: number) =>
        this.genericRequest({
            method: 'POST',
            overridePath: `/Sites/${siteId}/sensorreset`,
        }) as Promise<ApiResult<unknown>>;

    public restartSystem = (siteId: number) =>
        this.genericRequest({
            method: 'POST',
            overridePath: `/Sites/${siteId}/systemrestart`,
        }) as Promise<ApiResult<unknown>>;

    public activate = (siteId: number) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${siteId}/activate`,
        }) as Promise<ApiResult<unknown>>;

    public deactivate = (siteId: number) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${siteId}/deactivate`,
        }) as Promise<ApiResult<unknown>>;
}
