
import { AxiosApi, ApiResult } from './axiosApi';
import { ExportResult } from '../dtos/export';
import { CreateBiometricsEnrollmentDto } from '../dtos/biometrics';
import { Employee, EmployeeCreateDto, EmployeeForView } from '../dtos/employee';
import { EmployeesRequest } from './requestObjects/employeesRequest';

export class EmployeeApi extends AxiosApi<Employee> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, 'employees', logout, enqueueSnackbar);
    }

    public create = (employee: EmployeeCreateDto) =>
        this.genericRequest({
            method: 'POST',
            overridePath: 'auth/createEmployee',
            data: employee,
        });

    public getAllEmployees = (options: EmployeesRequest) =>
        this.genericRequest({
            method: 'POST',
            data: options,
        }) as Promise<ApiResult<{ employees: Employee[]; numberOfPages: number }>>;

    public exportAllEmployees = (options: EmployeesRequest) =>
        this.genericRequest({
            method: "POST",
            overridePath: "employees/export",
            data: options,
        }) as Promise<ApiResult<ExportResult>>;

    public getEmployee = (employeeId: number) =>
        this.genericRequest({
            method: 'GET',
            extraPath: `${employeeId}`,
        }) as Promise<ApiResult<EmployeeForView>>;

    public getEmployeesForDealer = (dealerId: number, options: EmployeesRequest) =>
        this.genericRequest({
            method: 'POST',
            overridePath: `dealers/${dealerId}/employees`,
            data: options,
        }) as Promise<ApiResult<{ employees: Employee[]; numberOfPages: number }>>;

    public exportEmployeesForDealer = (dealerId: number, options: EmployeesRequest) =>
        this.genericRequest({
            method: "POST",
            overridePath: `dealers/${dealerId}/employees/export`,
            data: options,
        }) as Promise<ApiResult<ExportResult>>;

    public getEmployeesForOrg = (orgId: number, options: EmployeesRequest) =>
        this.genericRequest({
            method: 'POST',
            overridePath: `organizations/${orgId}/employees`,
            data: options,
        }) as Promise<ApiResult<{ employees: Employee[]; numberOfPages: number }>>;

    public exportEmployeesForOrg = (orgId: number, options: EmployeesRequest) =>
        this.genericRequest({
            method: "POST",
            overridePath: `organizations/${orgId}/employees/export`,
            data: options,
        }) as Promise<ApiResult<ExportResult>>;

    public getEmployeesForSite = (orgId: number, siteId: number, options: EmployeesRequest) =>
        this.genericRequest({
            method: 'POST',
            overridePath: `organizations/${orgId}/sites/${siteId}/employees`,
            data: options,
        }) as Promise<ApiResult<{ employees: Employee[]; numberOfPages: number }>>;

    public exportEmployeesForSite = (orgId: number, siteId: number, options: EmployeesRequest) =>
        this.genericRequest({
            method: "POST",
            overridePath: `organizations/${orgId}/sites/${siteId}/employees/export`,
            data: options,
        }) as Promise<ApiResult<ExportResult>>;

    public editEmployee = (employee: EmployeeCreateDto) =>
        this.genericRequest({
            method: 'PUT',
            overridePath: `auth/editEmployee`,
            data: employee,
        });

    public restoreEmployee = (employee: EmployeeCreateDto) =>
        this.genericRequest({ method: 'PUT', overridePath: `auth/restoreEmployee`, data: employee });

    public getNameMatches = (searchText: string, orgId?: number, siteId?: number) => {
        const overridePath = `${orgId ? `organizations/${orgId}/` : ''}${siteId ? `sites/${siteId}/` : ''
            }employees/filter/${searchText}`;
        return this.genericRequest({ method: 'GET', overridePath }) as Promise<ApiResult<string[]>>;
    };

    public acceptEula = (accept: boolean) =>
        this.genericRequest({
            data: { accept },
            method: 'POST',
            extraPath: 'acceptEula',
        });

    public createFingerprintEnrollment = (
        employeeId: number,
        biometricsEnrollmentDto: CreateBiometricsEnrollmentDto
    ) =>
        this.genericRequest({
            method: 'POST',
            extraPath: `${employeeId}/fingerprint`,
            data: biometricsEnrollmentDto,
        }) as Promise<ApiResult<{ requestReference?: string; temporaryCode?: string; }>>;
}
