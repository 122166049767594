import React, { useMemo } from "react";

import { EmployeesRequest } from "../../../rest/requestObjects/employeesRequest";
import { ApiResult } from "../../../rest/baseApi";
import { ExportReportButton } from "../../shared/ExportReportButton";
import { useApi } from "../../useApi";

interface Props {
    orgId: number | undefined,
    siteId: number | undefined,
    dealerId: number | undefined,
    options: EmployeesRequest,
}

export function EmployeesExportButton({
    orgId,
    siteId,
    dealerId,
    options,
}: Props) {
    const { employeeApi } = useApi();

    const apiRequest: () => Promise<ApiResult<{ csvData: string; }>> = useMemo(() => {
        let apiMethod = () => employeeApi.exportAllEmployees(options);
        if (orgId && siteId) {
            apiMethod = () => employeeApi.exportEmployeesForSite(orgId, siteId, options);
        } else if (orgId) {
            apiMethod = () => employeeApi.exportEmployeesForOrg(orgId, options);
        } else if (dealerId) {
            apiMethod = () => employeeApi.exportEmployeesForDealer(dealerId, options);
        }
        return apiMethod;
    }, [
        employeeApi,
        orgId,
        siteId,
        dealerId,
        options,
    ]);

    return (
        <ExportReportButton
            reportRequest={apiRequest}
            reportTarget="Employees"
        />
    );
}