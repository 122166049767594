import { Button, MenuItem, MenuList, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { ScheduledJobTarget } from "../../../../dtos/scheduledJob";
import { OrgSiteContext } from "../../../orgSiteContext";
import { FormSection } from "../../../shared/formSection";
import { OrgSelect } from "../../../shared/OrgSelect";

import { getTargetLabel } from "./utils";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        gap: "20px",
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "stretch",
        justifyContent: "center",
    },
    targetContainer: {
        borderRadius: "4px",
        border: "1px solid grey",
        flexGrow: 1,
        padding: 0,
        overflow: "auto",
        height: "142px",
        maxHeight: "142px",
    },
    targetItem: {
        lineHeight: "16px",
        minHeight: "24px",
    },
}));

interface Props {
    targetIndexLabelMap: {
        target: ScheduledJobTarget;
        index: number;
        label: string;
    }[];
    isExclusion: boolean;
    onAddTarget: (target: ScheduledJobTarget) => void;
    onRemoveTargetByIndex: (targetIndex: number) => void;
}

export function ScheduledJobTargetsFormSection({
    targetIndexLabelMap,
    isExclusion,
    onAddTarget,
    onRemoveTargetByIndex,
}: Props) {
    const classes = useStyles();
    const orgSiteContext = useContext(OrgSiteContext);
    const [dealerId, setDealerId] = useState<number | null | undefined>(orgSiteContext.dealerId);
    const [organizationId, setOrganizationId] = useState<number | null | undefined>(orgSiteContext.orgId);
    const [siteId, setSiteId] = useState<number | null | undefined>(orgSiteContext.siteId);
    const [targetIndexToRemove, setTargetIndexToRemove] = useState<number>();
    const targetsToShow = targetIndexLabelMap.filter(it => it.target.isExclusion === isExclusion);

    const handleOrgSelectChange = (val: {
        dealerId?: number | null | undefined;
        orgId?: number | null | undefined;
        siteId?: number | null | undefined;
    }) => {
        setDealerId(val.dealerId);
        setOrganizationId(val.orgId);
        setSiteId(val.siteId);
    };

    const labels = targetsToShow.map(it => it.label);
    const canAddTarget = Boolean(dealerId || organizationId || siteId)
        && !labels.includes(getTargetLabel({ dealerId, organizationId, siteId }));

    const addTarget = () => {
        if (!canAddTarget) return;
        onAddTarget({
            isExclusion: isExclusion,
            siteId: siteId ? siteId : null,
            organizationId: organizationId ? organizationId : null,
            dealerId: dealerId ? dealerId : null,
        });
    };

    const removeTarget = () => {
        if (targetIndexToRemove === undefined) return;
        onRemoveTargetByIndex(targetIndexToRemove);
        setTargetIndexToRemove(undefined);
    };

    return (
        <FormSection headerText={isExclusion ? "Exclude" : "Scope"}>
            <div className={classes.container}>
                <OrgSelect
                    selectedDealerId={dealerId}
                    selectedOrgId={organizationId}
                    selectedSiteId={siteId}
                    descriptive={true}
                    onChange={handleOrgSelectChange}
                    ignoreOrgSiteContext={true}
                />

                <div className={classes.buttonContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={!canAddTarget}
                        onClick={addTarget}
                    >
                        Add &#8594;
                    </Button>

                    <Button
                        variant="outlined"
                        color="secondary"
                        disabled={targetIndexToRemove === undefined}
                        onClick={removeTarget}
                    >
                        &#8592; Remove
                    </Button>
                </div>

                <div className={classes.targetContainer}>
                    <MenuList>
                        {targetsToShow.map(it => (
                            <MenuItem
                                className={classes.targetItem}
                                key={it.index}
                                onClick={() => setTargetIndexToRemove(it.index)}
                                selected={it.index === targetIndexToRemove}
                            >
                                {getTargetLabel(it.target)}
                            </MenuItem>
                        ))}
                    </MenuList>
                </div>
            </div>
        </FormSection>
    );
}
