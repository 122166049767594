import React, { createContext, useState, useMemo, useEffect } from 'react';
import useRouter from 'use-react-router';

// since this service exists outside of individual routes, we can't use match.params to get the parameters
// from the URL route. Instead, we use regular expressions to get the org id and site id from any given url
export const OrgSiteService = ({ children }: { children?: any }) => {
    const { location } = useRouter();
    const { dealerIdFromRoute, orgIdFromRoute, siteIdFromRoute } = useMemo(
        () => getParamsFromRoute(location.pathname),
        [location.pathname]
    );
    const [dealerId, setDealerIdLocal] = useState<number | null>(
        !isNaN(dealerIdFromRoute) ? dealerIdFromRoute : null
    );
    const [orgId, setOrgIdLocal] = useState<number | null>(
        !isNaN(orgIdFromRoute) ? orgIdFromRoute : null
    );
    const [siteId, setSiteIdLocal] = useState<number | null>(
        !isNaN(siteIdFromRoute) ? siteIdFromRoute : null
    );
    const setDealerId = (id: number | null) => {
        setDealerIdLocal(id);
        setOrgIdLocal(null);
        setSiteIdLocal(null);
    };
    const setOrgId = (id: number | null) => {
        setOrgIdLocal(id);
        setSiteIdLocal(null);
    };
    const setSiteId = (id: number | null) => setSiteIdLocal(id);
    useEffect(() => {
        if (dealerId !== dealerIdFromRoute) {
            setDealerId(dealerIdFromRoute);
        }
    }, [dealerId, dealerIdFromRoute]);
    useEffect(() => {
        if (orgId !== orgIdFromRoute) {
            setOrgId(orgIdFromRoute);
        }
    }, [orgId, orgIdFromRoute]);
    useEffect(() => {
        if (siteId !== siteIdFromRoute) {
            setSiteId(siteIdFromRoute);
        }
    }, [siteId, siteIdFromRoute]);
    const contextValue = { orgId, siteId, setOrgId, setSiteId, dealerId, setDealerId };
    return <OrgSiteContext.Provider value={contextValue}>{children}</OrgSiteContext.Provider>;
};

export const OrgSiteContext = createContext<{
    orgId: number | null;
    siteId: number | null;
    setOrgId: (id: number | null) => void;
    setSiteId: (id: number | null) => void;
    dealerId: number | null;
    setDealerId: (id: number | null) => void;
}>({
    orgId: null,
    siteId: null,
    setOrgId: id => {},
    setSiteId: id => {},
    dealerId: null,
    setDealerId: id => {}
});

function getParamsFromRoute(pathname: string) {
    // the dealers, organizations/ and sites parts of the path are now all optional as far as the regex is concerned
    const pathRegex = /(\/dealers\/(?<dealerId>\d+))*(\/organizations\/(?<orgId>\d+))*(\/sites\/(?<siteId>\d+))*/;
    const matches = pathname.match(pathRegex);
    const groups = matches && matches.groups;
    const dealerIdFromRoute = parseInt((groups && groups.dealerId) || '');
    const orgIdFromRoute = parseInt((groups && groups.orgId) || '');
    const siteIdFromRoute = parseInt((groups && groups.siteId) || '');
    return { dealerIdFromRoute, orgIdFromRoute, siteIdFromRoute };
}
