import { CloudDone, CloudOff } from "@material-ui/icons";
import React, { useContext } from "react";

import SignalRContext from "../../../../signalr/context";

interface Props {
    siteId: number;
}

export function SiteServiceStatusIndicator({ siteId }: Props) {
    const { serviceStatus } = useContext(SignalRContext);

    const isSiteServiceOnline = serviceStatus.get(siteId);

    return isSiteServiceOnline ? <CloudDone /> : <CloudOff />;
}
