export enum HelpVideo {
    Login,
    Customer,
    Boxes,
    ManualOverride,
    Employee,
    Report,
    Alarm,
    Site,
}
