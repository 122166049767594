import React from "react";

import { WHATS_NEW_PAGE_URL } from "../constants";

const containerStyle: React.CSSProperties = {
    padding: "5px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 40,
};

export function FooterLinks() {
    return (
        <div style={containerStyle}>
            <Link href={WHATS_NEW_PAGE_URL} label="What's New" />
        </div>
    );
}

function Link({
    href,
    label,
}: {
    href: string;
    label: string;
}) {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit" }}
        >
            {label}
        </a>
    );
}
