import { CircularProgress, InputAdornment, TextField, Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { AjaxResult } from "../../../enums/ajaxResult";
import { useApi } from "../../useApi";

interface Props {
    alarmId: number;
    notes: string | null;
    notesInfo: string | undefined;
    onAlarmNotesUpdated: () => void;
    alarmAcknowledged: boolean;
}

export function AlarmNotes({
    notes,
    notesInfo,
    alarmId,
    onAlarmNotesUpdated,
    alarmAcknowledged,
}: Props) {
    const { eventApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [editedNotes, setEditedNotes] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setEditedNotes(e.target.value);
    }, []);

    const handleSubmit = useCallback((e: React.FormEvent) => {
        if (editedNotes === notes) return;
        e.preventDefault();
        setIsLoading(true);
        eventApi
            .updateAlarmNote({ id: alarmId, notes: editedNotes })
            .then((r) => {
                if (r.result === AjaxResult.Success) {
                    setIsLoading(false);
                    enqueueSnackbar("The note has been updated", { variant: "success" });
                    onAlarmNotesUpdated();
                } else if (r.result !== AjaxResult.Cancelled) {
                    enqueueSnackbar("Unable to update note", { variant: "error" });
                    setIsLoading(false);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedNotes, notes, alarmId, onAlarmNotesUpdated]);

    useEffect(() => {
        setEditedNotes(notes || "");
    }, [notes]);

    return (
        <form onSubmit={handleSubmit}>
            <Tooltip title={notesInfo} enterDelay={700}>
                <TextField
                    disabled={
                        isLoading
                        || !alarmAcknowledged
                    }
                    value={editedNotes}
                    onChange={handleChange}
                    onBlur={handleSubmit}
                    style={{ minWidth: "150px" }}
                    InputProps={{
                        endAdornment: isLoading
                            ? (
                                <InputAdornment position="end">
                                    <CircularProgress size={24} color="inherit" />
                                </InputAdornment>
                            )
                            : <div style={{ width: 28 }}></div>
                    }}
                />
            </Tooltip>
        </form>
    );
}