import { Link } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Dealer } from "../../../dtos/dealer";
import { StandardTable } from "../../shared/table";

import { ActivationButton } from "./ActivationButton";
import { AddAdminUserButton } from "./AddAdminUserButton";
import { DeleteDealerButton } from "./DeleteDealerButton";
import { EditDealerButton } from "./EditDealerButton";

interface Props {
    dealers: Dealer[];
    onLoadDealers: () => void;
    dealersAreLoading: boolean;
}

export const DealersTable = ({
    dealers,
    onLoadDealers,
    dealersAreLoading,
}: Props) => {
    return (
        <StandardTable>
            <thead>
                <tr>
                    <th>Name</th>
                    <th># of Organizations</th>
                    <th># of Sites</th>
                    <th># of Customers</th>
                    <th># of Employees</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {dealers.map(d => (
                    <tr key={d.id}>
                        <td>{d.name}</td>
                        <td>{d.numberOfOrgs}</td>
                        <td>{d.numberOfSites}</td>
                        <td>{d.numberOfCustomers}</td>
                        <td>{d.numberOfEmployees}</td>
                        <td>
                            <Link component={RouterLink} to={`/dealers/${d.id}/organizations`}>
                                Orgs
                            </Link>
                        </td>
                        <td>
                            <AddAdminUserButton
                                dealer={d}
                                onEmployeeCreated={onLoadDealers}
                            />
                        </td>
                        <td>
                            <EditDealerButton
                                dealer={d}
                                onLoadDealers={onLoadDealers}
                                dealersAreLoading={dealersAreLoading}
                            />
                        </td>
                        <td>
                            <DeleteDealerButton
                                dealer={d}
                                onDealerDelete={onLoadDealers}
                                dealersAreLoading={dealersAreLoading}
                            />
                        </td>
                        <td>
                            <ActivationButton
                                dealer={d}
                                onDealerActivationChange={onLoadDealers}
                                dealersAreLoading={dealersAreLoading}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </StandardTable>
    );
};
