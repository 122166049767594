import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

export const ConfirmDialog = ({
    isVisible,
    message,
    close,
    confirm,
    cancel
}: {
    isVisible: boolean;
    message: string;
    close: () => void;
    confirm: () => void;
    cancel: () => void;
}) => (
    <Dialog open={isVisible}>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    close();
                    cancel();
                }}
            >
                Cancel
            </Button>
            <Button
                onClick={() => {
                    close();
                    confirm();
                }}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>
);
