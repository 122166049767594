import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";

import { Organization } from "../../../dtos/organization";
import { AjaxResult } from "../../../enums/ajaxResult";
import { FormSection } from "../../shared/formSection";
import { FormField } from "../../shared/formField";
import { TextField } from "../../shared/TextField";
import { useApi } from "../../useApi";

interface Props {
    org?: Organization
    dealerId: number | undefined;
    onClose: () => void;
    onLoadOrgs: () => void;
}

export function OrgEditForm({ dealerId, onLoadOrgs, onClose, org }: Props) {
    const { orgApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [orgData, setOrgData] = useState<{ name: string; }>({
        name: org && org.name ? org.name : "",
    });
    const [loading, setLoading] = useState(false);

    const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setOrgData(prev => ({ ...prev, name: e.target.value }));
    }, []);

    const handleSave = useCallback(() => {
        const apiMethod = org
            ? () => orgApi.updateOrg(org.id, orgData)
            : () => orgApi.postOne({ id: 0, ...orgData, dealerId });

        setLoading(true);
        apiMethod().then((r) => {
            if (r.result === AjaxResult.Success) {
                enqueueSnackbar(
                    `Successfully ${org ? "updated" : "created new"} organization: "${orgData.name}"`,
                    { variant: "success" },
                );
                onLoadOrgs();
                onClose();
            } else {
                enqueueSnackbar(
                    `Failed to ${org ? "update" : "create new"} organization: "${orgData.name}"`,
                    { variant: "error" },
                );
            }
            setLoading(false);
        });
    }, [orgApi, orgData, dealerId, enqueueSnackbar, onLoadOrgs, onClose, org]);

    return (
        <Dialog
            open={true}
            onClose={onClose}
            maxWidth="sm"
        >
            <DialogContent>
                <FormSection headerText={org ? `Edit Organization "${org.name}"` : "Create New Organization"}>
                    <FormField label="Organization Name" required>
                        <TextField
                            placeholder="Organization Name"
                            value={orgData.name}
                            onChange={handleChangeName}
                            variant="outlined"
                            stylesProps={{ width: 300 }}
                        />
                    </FormField>
                </FormSection>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={loading || orgData.name === ""}
                >
                    {org
                        ? loading
                            ? "Saving..."
                            : "Save"
                        : loading
                            ? "Creating..."
                            : "Create"
                    }
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>

    );
}