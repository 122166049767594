import { useContext, useEffect, useMemo, useState } from "react";

import { AuthContext } from "../../../../auth/authContext";
import { Dealer } from "../../../../dtos/dealer";
import { AjaxResult } from "../../../../enums/ajaxResult";
import { useApi } from "../../../useApi";

export function useDealerOptions(
    selectedDealerId: number | null,
    canGetOptions: boolean,
) {
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { dealerApi } = useApi();
    const { isGlobalAdmin } = useContext(AuthContext);

    useEffect(() => {
        let cancel = false;
        if (canGetOptions && isGlobalAdmin) {
            setIsLoading(true);
            dealerApi.getAll().then((r) => {
                if (!cancel && r.result === AjaxResult.Success && r.data) {
                    setDealers(r.data);
                }
                setIsLoading(false);
            });
        }
        return () => {
            cancel = true;
        };
    }, [canGetOptions, dealerApi, isGlobalAdmin]);

    const dealerOptions = useMemo(() => {
        return dealers
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(it => ({ label: it.name, value: it.id }));
    }, [dealers]);

    const selectedDealerOption = useMemo(() => {
        return dealerOptions.find(it => it.value === selectedDealerId);
    }, [dealerOptions, selectedDealerId]);

    return { dealerOptions, selectedDealerOption, isLoading };
}
