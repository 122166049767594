export enum PasswordComplexityLevel {
    Length = "length",
    Case = "case",
    Mix = "mix",
}


export function validatePasswordComplexity(password: string): PasswordComplexityLevel | null {
    if (password.length < 8) {
        return PasswordComplexityLevel.Length;
    }

    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[^a-zA-Z0-9]/.test(password);

    if (!(hasLowercase && hasUppercase)) {
        return PasswordComplexityLevel.Case;
    }
    
    if (!(hasNumber && hasSymbol)) {
        return PasswordComplexityLevel.Mix;
    }

    return null;
}