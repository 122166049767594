import { useState, useCallback, useMemo, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { AuthApi } from '../rest/authApi';
import { AjaxResult } from '../enums/ajaxResult';
import {
    BASE_SERVER_URL,
    ISSUE_COLLECTOR_ELEMENT_IDS,
    ISSUE_COLLECTOR_SCRIPT,
    REFRESH_TOKEN_THROTTLE_SECONDS,
} from '../constants';
import useRouter from 'use-react-router';
import { useCookies } from 'react-cookie';
import { UserLevel } from '../enums/userLevel';

interface UserDataFromToken {
    username: string | null;
    roles: string[];
    dealerId: number | null;
    orgId: number | null;
    siteId: number | null;
    email: string | null;
    eulaAccepted: boolean | null;
    isDealerActive: boolean | null;
    isOrgActive: boolean | null;
    isSiteActive: boolean | null;
}

const EMPTY_USER_DATA: UserDataFromToken = {
    username: null,
    email: null,
    siteId: null,
    roles: [],
    orgId: null,
    dealerId: null,
    eulaAccepted: null,
    isDealerActive: null,
    isOrgActive: null,
    isSiteActive: null,
};

export const useAuth = (enqueueSnackbar?: any) => {
    const authApi = useMemo(() => new AuthApi(BASE_SERVER_URL, enqueueSnackbar), [enqueueSnackbar]);
    const [cookies, setCookie, removeCookie] = useCookies(['refreshToken']);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { history } = useRouter();
    const token = localStorage.getItem('token');
    const [loggedInUser, setLoggedInUser] = useState<UserDataFromToken>(
        token !== null
            ? getUserDataFromToken(token)
            : EMPTY_USER_DATA
    );
    const [loginInProgress, setLoginInProgress] = useState(false);
    const [changePasswordDialogIsVisible, setChangePasswordDialogIsVisible] = useState(false);

    const logout = useCallback(() => {
        console.debug('logout');
        //await publicFetch.delete('/token/invalidate'); TODO, send request to API to expire token
        localStorage.removeItem('sessionId');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshTokenExpDate');
        removeCookie('refreshToken');

        setLoggedInUser(EMPTY_USER_DATA);
        setIsAuthenticated(false);
        history.push('/');
        // eslint-disable-next-line
    }, []); // all the objects used in this callback should have a static reference, so I feel pretty safe ignoring the dependencies warning here

    const [loginErrors, setLoginErrors] = useState<string[]>([]);
    const login = (creds: { username: string; password: string }) => {
        setLoginInProgress(true);
        setLoginErrors([]);
        let succeeded = false;
        authApi.getToken(creds.username, creds.password).then((r) => {
            if (r.result === AjaxResult.Success && r.data) {
                var { sessionId, accessToken, refreshToken, refreshTokenExpiration } = r.data;

                localStorage.setItem('sessionId', sessionId);
                localStorage.setItem('token', accessToken);
                localStorage.setItem('refreshTokenExpDate', refreshTokenExpiration);
                setCookie('refreshToken', refreshToken, {
                    path: '/',
                });

                const newUserData = getUserDataFromToken(accessToken);
                if (newUserData.orgId) {
                    history.push(`/organizations/${newUserData.orgId}/home`);
                }
                setLoggedInUser(newUserData);
                succeeded = true;
                setLoginErrors([]);
            } else if (r.result === AjaxResult.Failure && r.messages) {
                setLoginErrors(r.messages);
            } else {
                setLoginErrors(['Login failed']);
            }
            setIsAuthenticated(succeeded);
            setLoginInProgress(false);
        });
    };

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isAuthenticated) {
            interval = setInterval(() => {
                const expDate = localStorage.getItem('refreshTokenExpDate');
                if (expDate == null || new Date(expDate) < new Date()) {
                    console.debug('token expired: ' + expDate);
                    logout();
                    clearInterval(interval);
                }
            }, 2000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [logout, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            const script = document.createElement('script');
            script.src = ISSUE_COLLECTOR_SCRIPT;
            script.async = true;
            document.body.appendChild(script);
            document.body.removeChild(script);
        } else {
            ISSUE_COLLECTOR_ELEMENT_IDS
                .map(it => document.getElementById(it))
                .forEach(it => it ? it.remove() : undefined);
        }
    }, [isAuthenticated])

    const refreshToken = (force = false, expire?: Date) => {
        console.debug('refreshToken: force=' + force);
        const expDate = localStorage.getItem('refreshTokenExpDate');
        if (
            !force &&
            expDate &&
            new Date(expDate).getTime() - new Date().getTime() >
            REFRESH_TOKEN_THROTTLE_SECONDS * 1000
        ) {
            return;
        }

        const accessToken = localStorage.getItem('token');
        const refreshToken = cookies.refreshToken;
        if (accessToken && refreshToken) {
            authApi.refreshToken(accessToken, refreshToken).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    var { accessToken, refreshToken, refreshTokenExpiration } = r.data;

                    if (expire != null) {
                        refreshTokenExpiration = expire.toISOString();
                    }

                    localStorage.setItem('token', accessToken);
                    localStorage.setItem('refreshTokenExpDate', refreshTokenExpiration);
                    setCookie('refreshToken', refreshToken, {
                        path: '/',
                    });
                    return;
                }
                logout();
            });
        } else {
            logout();
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const expDate = localStorage.getItem('refreshTokenExpDate');
        if (
            token != null &&
            loggedInUser.username &&
            expDate != null &&
            new Date(expDate) >= new Date()
        ) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [isAuthenticated, loggedInUser.username]);

    const sendPasswordResetEmail = authApi.sendPasswordResetEmail;

    const isGlobalAdmin = loggedInUser.roles.indexOf(UserLevel.GlobalAdmin) !== -1;
    const isDealerAdmin = loggedInUser.roles.indexOf(UserLevel.DealerAdmin) !== -1;
    const isOrgAdmin = loggedInUser.roles.indexOf(UserLevel.OrgAdmin) !== -1;
    const isSiteEmployee = loggedInUser.roles.indexOf(UserLevel.SiteEmployee) !== -1;

    return {
        ...loggedInUser,
        isGlobalAdmin,
        isDealerAdmin,
        isOrgAdmin,
        isSiteEmployee,
        loginErrors,
        logout,
        login,
        isAuthenticated,
        refreshToken,
        loginInProgress,
        changePasswordDialogIsVisible,
        setChangePasswordDialogIsVisible,
        authApi,
        sendPasswordResetEmail,
    };
};

function getUserDataFromToken(jwt: string): UserDataFromToken {
    const decoded: any = jwt_decode(jwt);

    const usernameKey = Object.keys(decoded).find((k) => k.endsWith('nameidentifier'));
    const rolesKey = Object.keys(decoded).find((k) => k.endsWith('role'));
    const emailKey = Object.keys(decoded).find((k) => k.endsWith('emailaddress'));

    return {
        username: usernameKey ? decoded[usernameKey] as string : null,
        email: emailKey ? decoded[emailKey] as string : null,
        dealerId: decoded['dealerId'] ? parseInt(decoded['dealerId']) : null,
        orgId: decoded['organizationId'] ? parseInt(decoded['organizationId']) : null,
        siteId: decoded['siteId'] ? parseInt(decoded['siteId']) : null,
        eulaAccepted: decoded['eulaAccepted'] === '1',
        isDealerActive: decoded['isDealerActive'] === "False" ? false : true,
        isOrgActive: decoded['isOrgActive'] === "False" ? false : true,
        isSiteActive: decoded['isSiteActive'] === "False" ? false : true,
        roles: rolesKey
            ? Array.isArray(decoded[rolesKey])
                ? (decoded[rolesKey] as string[])
                : ([decoded[rolesKey]] as string[])
            : [],
    };
}
