import { Button, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";

import { Dealer } from "../../../dtos/dealer";
import { AjaxResult } from "../../../enums/ajaxResult";
import { PageHeader } from "../../PageHeader";
import { useApi } from "../../useApi";

import { DealerEditForm } from "./DealerEditForm";
import { DealersTable } from "./table";

export const DealersPage = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { dealerApi } = useApi();
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [dealersAreLoading, setDealersAreLoading] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);

    const loadDealers = () => {
        setDealersAreLoading(true);
        dealerApi.getAll().then(r => {
            if (r.result === AjaxResult.Success && r.data) {
                setDealers(r.data);
            } else if (r.result === AjaxResult.Forbidden) {
                enqueueSnackbar("You do not have permission to get all dealers", { variant: "error" });
            } else {
                enqueueSnackbar("Failed to fetch dealers", { variant: "error" });
            }
            setDealersAreLoading(false);
        });
    };

    useEffect(() => {
        loadDealers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PageHeader>Dealers</PageHeader>
            <Button
                onClick={() => setShowCreateForm(true)}
                className={classes.addButton}
                variant="contained"
                color="primary"
            >
                Add New
            </Button>

            <DealersTable
                dealers={dealers}
                onLoadDealers={loadDealers}
                dealersAreLoading={dealersAreLoading}
            />

            {showCreateForm && (
                <DealerEditForm
                    onClose={() => setShowCreateForm(false)}
                    onLoadDealers={loadDealers}
                />
            )}
        </>
    );
};

const useStyles = makeStyles({
    addButton: {
        display: "flex",
        marginBottom: "20px"
    }
});
