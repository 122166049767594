import { Box } from '../../../../dtos/box';
import { BoxCustomerDto, Customer } from '../../../../dtos/customer';
import { createAction, ActionsUnion } from 'redux-typescript-create-action';

export enum ActionType {
    LOAD_BOX = 'LOAD_BOX',
    LOAD_CUSTOMERS = 'LOAD_CUSTOMERS',
    LOAD_OWNERS = 'LOAD_OWNERS',
    ADD_OWNER = 'ADD_OWNER',
    ADD_CUSTOMER = 'ADD_CUSTOMER',
    DELETE_OWNER = 'DELETE_OWNER',
    SET_NOTES = 'SET_NOTES'
}

export const Actions = {
    loadBox: (box: Box | null) => createAction(ActionType.LOAD_BOX, { box }),
    loadOwners: (owners: BoxCustomerDto[]) => createAction(ActionType.LOAD_OWNERS, { owners }),
    loadCustomers: (customers: Customer[]) =>
        createAction(ActionType.LOAD_CUSTOMERS, { customers }),
    addOwner: (customer: BoxCustomerDto) => createAction(ActionType.ADD_OWNER, { customer }),
    addCustomer: (customer: Customer) => createAction(ActionType.ADD_CUSTOMER, { customer }),
    deleteOwner: (id: number) => createAction(ActionType.DELETE_OWNER, { id }),
    setNotes: (notes: string) => createAction(ActionType.SET_NOTES, { notes })
};

export type Actions = ActionsUnion<typeof Actions>;

export interface State {
    box: Box | null;
    customers: Customer[];
    owners: BoxCustomerDto[];
}

export const reducer = (state: State, action: Actions): State => {
    switch (action.type) {
        case ActionType.LOAD_BOX:
            return { ...state, box: action.payload.box };
        case ActionType.LOAD_CUSTOMERS:
            return { ...state, customers: action.payload.customers };
        case ActionType.LOAD_OWNERS:
            return { ...state, owners: action.payload.owners };
        case ActionType.ADD_OWNER:
            return { ...state, owners: [...state.owners, action.payload.customer] };
        case ActionType.ADD_CUSTOMER:
            return { ...state, customers: [...state.customers, action.payload.customer] };
        case ActionType.DELETE_OWNER:
            return { ...state, owners: state.owners.filter(i => i.id !== action.payload.id) };
        case ActionType.SET_NOTES:
            if (!state.box) {
                return state;
            }
            return { ...state, box: { ...state.box, notes: action.payload.notes } };
        default:
            return state;
    }
};
