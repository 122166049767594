import moment from "moment";
import momentTimezone from "moment-timezone";

const DATE_FORMAT = "M/DD/YYYY h:mm:ss A Z";

export function getTimestamp(
    filterByLocalTime: boolean | null,
    localTime: string | Date | undefined,
    utcTime: string | Date | undefined,
): string | undefined {
    if (!localTime || !utcTime) return;

    if (filterByLocalTime) {
        return moment(localTime)
            .utcOffset(momentTimezone.parseZone(localTime).utcOffset())
            .format(DATE_FORMAT);
    }
    return moment.utc(utcTime).format(DATE_FORMAT);
}
