import React from 'react';
import { makeStyles } from '@material-ui/styles';

interface Props {
    headerText?: string;
    children?: any;
    labelMinWidth?: number;
    labelMaxWidth?: number;
}
export const FormSection = ({
    headerText,
    children,
    labelMinWidth = 100,
    labelMaxWidth = undefined,
}: Props) => {
    const classes = useStyles(labelMinWidth, labelMaxWidth)();
    return (
        <section className={classes['form-section']}>
            <header>{headerText}</header>
            {children}
        </section>
    );
};

const useStyles = (
    labelMinWidth: number,
    labelMaxWidth: number | undefined,
) => makeStyles({
    'form-section': {
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        '& header': {
            borderBottom: '1px solid black',
            display: 'block',
            fontWeight: 'bold',
            margin: '10px 0'
        },
        '& label': {
            display: 'inline-block',
            minWidth: `${labelMinWidth}px`,
            maxWidth: labelMaxWidth ? `${labelMaxWidth}px` : undefined,
        }
    }
});
