export enum ManagementEventName {
    UserAdded = 0,
    UserModified = 1,
    UserRemoved = 2,
    LeaseCreated = 10,
    LeaseEdited = 11,
    LeaseRemoved = 12,
    AlarmAcknowledged = 13,
    AlarmNotesUpdated = 14,
    ManualOverrideIssued = 15,
    EnrollmentIssued = 16,
    EmployeeAdded = 17,
    EmployeeModified = 18,
    EmployeeRemoved = 19,
}

export const ManagementEventNameDisplay = new Map([
    [ManagementEventName.UserAdded, 'User Added'],
    [ManagementEventName.UserModified, 'User Modified'],
    [ManagementEventName.UserRemoved, 'User Removed'],
    [ManagementEventName.LeaseCreated, 'Lease Created'],
    [ManagementEventName.LeaseEdited, 'Lease Edited'],
    [ManagementEventName.LeaseRemoved, 'Lease Removed'],
    [ManagementEventName.AlarmAcknowledged, 'Alarm Acknowledged'],
    [ManagementEventName.AlarmNotesUpdated, 'Alarm Notes Updated'],
    [ManagementEventName.ManualOverrideIssued, 'Manual Override Issued'],
    [ManagementEventName.EnrollmentIssued, 'Enrollment Issued'],
    [ManagementEventName.EmployeeAdded, 'Employee Added'],
    [ManagementEventName.EmployeeModified, 'Employee Modified'],
    [ManagementEventName.EmployeeRemoved, 'Employee Removed'],
]);
