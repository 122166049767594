import { Button } from "@material-ui/core";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import { ExportResult } from "../../dtos/export";
import { AjaxResult } from "../../enums/ajaxResult";
import { ApiResult } from "../../rest/baseApi";

interface Props {
    reportRequest: (() => Promise<ApiResult<ExportResult>>) | undefined;
    reportTarget: string;
}

export function ExportReportButton(
    { reportTarget, reportRequest }: Props
) {
    const [loading, setLoading] = useState(false);
    const [exported, setExported] = useState(false);

    const handleReportResult = useCallback((reportData: ExportResult) => {
        console.log(reportData);
        var exportFileLink = document.createElement('a');
        var file = new Blob([reportData.csvData], { type: 'text/plain' });
        exportFileLink.href = URL.createObjectURL(file);
        exportFileLink.download = `${reportTarget} Report ${moment().format('YYYY-MM-DDTHH_mm_ss')}.csv`;
        exportFileLink.click();
        exportFileLink.remove();
        setExported(true);
    }, [reportTarget]);

    const exportBoxes = useCallback(() => {
        if (!reportRequest) return;
        setLoading(true);
        reportRequest().then((result) => {
            setLoading(false);
            if (result.result === AjaxResult.Success && result.data) {
                handleReportResult(result.data);
            } else {
                alert(`ERROR: failed to export ${reportTarget} Report`);
            }
        });
    }, [reportRequest, handleReportResult, reportTarget]);

    useEffect(() => {
        if (exported) setExported(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportRequest]);

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={exportBoxes}
            disabled={loading || exported || !reportTarget}
        >
            {loading
                ? "Exporting..."
                : exported
                    ? "Report Exported"
                    : "Export Report"}
        </Button>
    );
}