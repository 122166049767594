import moment from "moment";
import React from "react";

import { ScheduledJob } from "../../../../dtos/scheduledJob";
import { SCHEDULED_JOB_TYPE_LABELS, ScheduledJobType } from "../../../../enums/ScheduledJobType";
import { StandardTable } from "../../../shared/table";
import { EditScheduledJobButton } from "../EditScheduledJobButton";
import { DeleteScheduledJobButton } from "../DeleteScheduledJobButton";

interface Props {
    jobs: ScheduledJob[];
    setJobs: (jobs: ScheduledJob[]) => void;
    loadJobs: () => void;
    addedJobTypes: ScheduledJobType[];
}

export function ScheduledJobsTable({ jobs, setJobs, loadJobs, addedJobTypes }: Props) {
    const handleJobDeleted = (id: number) => {
        setJobs(jobs.filter(it => it.id !== id));
    }

    const handleJobSaved = (job: ScheduledJob) => {
        setJobs(jobs.map((prev) => (prev.id === job.id ? { ...prev, ...job } : prev)));
        loadJobs();
    }

    return (
        <StandardTable>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Added</th>
                    <th>Modified</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                {jobs.map((it) => (
                    <tr key={it.id}>
                        <td>{SCHEDULED_JOB_TYPE_LABELS[it.type]}</td>
                        <td>{it.name}</td>
                        <td>
                            {moment.utc(it.addedOn).format("M/DD/YYYY h:mm:ss A Z z")}
                            {` by ${it.addedByName}`}
                        </td>
                        <td>
                            {it.modifiedOn ? moment.utc(it.modifiedOn).format("M/DD/YYYY h:mm:ss A Z z") : ""}
                            {it.modifiedByName ? ` by ${it.modifiedByName}` : ""}
                        </td>
                        <td>
                            <EditScheduledJobButton
                                job={it}
                                onJobSaved={handleJobSaved}
                                addedJobTypes={addedJobTypes}
                            />
                        </td>
                        <td><DeleteScheduledJobButton job={it} onJobDeleted={handleJobDeleted} /></td>
                    </tr>
                ))}
            </tbody>
        </StandardTable>
    );
}