import React, { useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';

export const SnackBarService = ({ children }: { children?: any }) => {
    const notistackRef = useRef();
    const onClickDismiss = (key: any) => () => {
        if (notistackRef && notistackRef.current !== undefined) {
            (notistackRef.current as any).handleDismissSnack(key);
        }
    };
    return (
        <SnackbarProvider
            maxSnack={4}
            autoHideDuration={3000}
            ref={notistackRef}
            action={key => <Button onClick={onClickDismiss(key)}>&times;</Button>}
        >
            {children}
        </SnackbarProvider>
    );
};
