import { ExportResult } from '../dtos/export';
import { CustomerImportResult } from '../dtos/import';
import { AxiosApi, ApiResult } from './axiosApi';

export class ImportApi extends AxiosApi<{}> {
    constructor(baseUri: string, enqueueSnackbar?: any) {
        super(baseUri, 'import', undefined, enqueueSnackbar);
    }

    public importCustomersForOrg = (orgId: number, file: FormData, review: boolean) =>
        this.genericRequest({
            method: 'POST',
            overridePath: `organizations/${orgId}/import?review=${review}`,
            data: file,
            sendDataRaw: true,
        }) as Promise<ApiResult<CustomerImportResult>>;

    public exportCustomersForOrg = (orgId: number, siteId?: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/${siteId ? `sites/${siteId}/` : ''}export`,
        }) as Promise<ApiResult<ExportResult>>;
}
