export enum ScheduledJobType {
    SystemRestart = 0,
    ServiceRestart = 1,
    SensorReset = 2,
};

export const SCHEDULED_JOB_TYPE_LABELS: { [key in ScheduledJobType]: string } = {
    [ScheduledJobType.SystemRestart]: "System Restart",
    [ScheduledJobType.ServiceRestart]: "Service Restart",
    [ScheduledJobType.SensorReset]: "Sensor Reset",
};

export const SCHEDULED_JOB_TYPES = [
    ScheduledJobType.SensorReset,
    ScheduledJobType.ServiceRestart,
    ScheduledJobType.SystemRestart,
] as const;