import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";

import { ScheduledJob } from "../../../../dtos/scheduledJob";
import { ScheduledJobType } from "../../../../enums/ScheduledJobType";
import { ScheduledJobForm } from "../ScheduledJobForm";

import { useEditScheduledJob } from "./useEdit";


interface Props {
    job: ScheduledJob;
    open: boolean;
    onClose: () => void;
    onJobSaved: (job: ScheduledJob) => void;
    addedJobTypes: ScheduledJobType[];
}

export function EditScheduledJobDialog({ job, open, onClose, onJobSaved, addedJobTypes }: Props) {
    const { save, loading, jobBase, setJobBase } = useEditScheduledJob(job, onJobSaved);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={onClose}
        >
            <DialogContent>
                <ScheduledJobForm
                    jobBase={jobBase}
                    setJobBase={setJobBase}
                    addedJobTypes={addedJobTypes}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    onClick={save}
                >
                    Save
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}