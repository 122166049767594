import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const ItemsPerPageInput = ({
    value,
    onChange
}: {
    value: number;
    onChange: (val: number) => void;
}) => {
    const classes = useStyles();
    const [localVal, setLocalVal] = useState(`${value}`);
    const [localNum, setLocalNum] = useState(value);
    return (
        <div>
            <TextField
                label="Items per page"
                type="number"
                value={localVal}
                onChange={e => {
                    setLocalVal(e.currentTarget.value);
                }}
                onBlur={e => {
                    const newVal = parseInt(localVal);
                    if (!isNaN(newVal)) {
                        setLocalNum(newVal);
                        setLocalVal(newVal.toString());
                    } else {
                        setLocalVal(localNum.toString());
                    }
                }}
            />
            <Button
                className={classes.setButton}
                variant="contained"
                color="primary"
                onClick={() => onChange(localNum)}
            >
                Set
            </Button>
        </div>
    );
};

const useStyles = makeStyles({
    setButton: {
        verticalAlign: 'bottom'
    }
});
