import { useContext, useEffect, useState } from "react";

import { Site } from "../../dtos/site";
import { AjaxResult } from "../../enums/ajaxResult";
import { OrgSiteContext } from "../orgSiteContext";
import { useApi } from "../useApi";


export function useSite() {
    const [site, setSite] = useState<Site>();

    const { siteId } = useContext(OrgSiteContext);
    const { siteApi } = useApi();

    useEffect(() => {
        if (!siteId) {
            setSite(undefined);
            return;
        };

        let cancel = false;
        siteApi.getById(siteId).then((r) => {
            if (!cancel && r.result === AjaxResult.Success && r.data) {
                setSite(r.data);
            }
        });
        return () => {
            cancel = true;
        };
    }, [siteId, siteApi]);


    return site;
}
