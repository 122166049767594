import { CircularProgress, makeStyles } from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import React from "react";

import HelpModal from "../pages/help/helpModal";
import { HelpVideo } from "../../enums/helpVideo";

import { UserMenu } from "./UserMenu";

interface Props {
    children?: any;
    helpVideoNum?: HelpVideo;
    isLoading?: boolean;
}

export function PageHeader({ children, helpVideoNum, isLoading }: Props) {
    const classes = useStyles();
    return (
        <header className={classes.header}>
            <div className={classes.heading}>
                <h1>{children}</h1>
                {helpVideoNum != null && <HelpModal videoToFocus={helpVideoNum} />}
                {isLoading ? <CircularProgress size={28} /> : <></>}
            </div>
            <UserMenu />
        </header>
    );
};

const useStyles = makeStyles((theme: ThemeOptions) => ({
    header: {
        color: theme.fontThemeColor3,
        alignItems: "baseline",
        display: "flex",
        justifyContent: "space-between",
    },
    heading: {
        alignItems: "center",
        gap: "25px",
        display: "flex",
    },
})); 
