import { IconButton, makeStyles } from "@material-ui/core";
import { AutorenewOutlined } from "@material-ui/icons";
import React, { useState } from "react";

import { EmployeeForView } from "../../../../dtos/employee";
import { AjaxResult } from "../../../../enums/ajaxResult";
import { useApi } from "../../../useApi";

const useStyles = makeStyles({
    spinning: {
        animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
});


interface Props {
    setEmployee: (employee: EmployeeForView) => void;
    setError: (error: string) => void;
}

export function RefreshPinCodeButton({
    setEmployee,
    setError
}: Props) {
    const classes = useStyles();
    const { authApi } = useApi();
    const [isLoading, setIsLoading] = useState(false);

    function handleClick() {
        setError("");
        setIsLoading(true);
        setTimeout(() => {
            authApi.regenerateUserPinCode().then((r) => {
                if (r.result === AjaxResult.Success) {
                    if (r.data) {
                        setEmployee(r.data);
                    }
                } else {
                    setError("Failed to regenerate User Pin Code");
                }
                setIsLoading(false);
            });
        }, 3000);
    }

    return (
        <IconButton
            size="small"
            onClick={handleClick}
            disabled={isLoading}
        >
            <AutorenewOutlined className={isLoading ? classes.spinning : undefined} />
        </IconButton>
    );
}