import React, { useCallback, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, PropTypes } from '@material-ui/core';

interface Props {
    confirmedCallback: () => void;
    confirmDialogContent: React.ReactNode;
    disabled?: boolean;
    color?: PropTypes.Color;
    variant?: "text" | "outlined" | "contained",
}

export function ButtonWithConfirm({
    disabled = false,
    color = "primary",
    variant = "contained",
    confirmDialogContent,
    confirmedCallback,
    children,
}: React.PropsWithChildren<Props>) {
    const [dialogOpened, setDialogOpened] = useState(false);

    const openConfirmationDialog = useCallback(() => {
        setDialogOpened(true);
    }, []);

    const closeConfirmationDialog = useCallback(() => {
        setDialogOpened(false);
    }, []);

    const handleConfirm = useCallback(() => {
        closeConfirmationDialog();
        confirmedCallback();
    }, [closeConfirmationDialog, confirmedCallback]);

    return (
        <>
            <Button
                color={color}
                variant={variant}
                onClick={openConfirmationDialog}
                disabled={disabled}
            >
                {children}
            </Button>

            <Dialog open={dialogOpened}>
                <DialogContent>
                    {confirmDialogContent}
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeConfirmationDialog}>
                        Cancel
                    </Button>
            
                    <Button
                        onClick={handleConfirm}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
