import React, { useCallback, useEffect, useState } from "react";

import { SearchTarget } from "../../enums/searchTarget";

import { SearchInput } from "./SearchInput";
import { SearchResultPopper } from "./SearchResultPopper";
import { useSearch } from "./useSearch";

export function Search() {
    const [searchTarget] = useState<SearchTarget>(SearchTarget.Sites);
    const { search, result, isLoading, errorMsg, clearResult } = useSearch(searchTarget);
    const [searchValue, setSearchValue] = useState("");
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [resultsShown, setResultsShown] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
        setInputFocused(true);
        setResultsShown(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setInputFocused(false);
    }, []);

    const handleResultsHide = useCallback(() => {
        if (inputFocused) return;
        setResultsShown(false);
    }, [inputFocused]);

    useEffect(() => {
        if (searchValue) {
            search(searchValue);
        } else {
            clearResult();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const isWhatToShow = (Boolean(result) || Boolean(errorMsg));

    return (
        <>
            <SearchInput
                onChange={setSearchValue}
                onRef={setMenuAnchorEl}
                showLoading={isLoading}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
            />

            <SearchResultPopper
                searchTarget={searchTarget}
                anchorEl={menuAnchorEl}
                foundItems={result}
                onClose={handleResultsHide}
                isOpen={isWhatToShow && resultsShown}
                errorMsg={errorMsg}
            />
        </>
    );
}
