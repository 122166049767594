import { createContext } from 'react';
import { AuthApi } from '../rest/authApi';
import { ApiResult } from '../rest/axiosApi';

export const AuthContext = createContext<{
    authApi: AuthApi;
    username: string | null;
    email: string | null;
    roles: string[];
    dealerId: number | null;
    orgId: number | null;
    siteId: number | null;
    eulaAccepted: boolean | null;
    isDealerActive: boolean | null;
    isOrgActive: boolean | null;
    isSiteActive: boolean | null;
    isGlobalAdmin: boolean;
    isDealerAdmin: boolean;
    isOrgAdmin: boolean;
    isSiteEmployee: boolean;
    loginInProgress: boolean;
    loginErrors: string[];
    logout: () => void;
    login: (creds: { username: string; password: string }) => void;
    isAuthenticated: boolean;
    refreshToken: (force?: boolean, expire?: Date) => void;
    changePasswordDialogIsVisible: boolean;
    setChangePasswordDialogIsVisible: (isVisible: boolean) => void;
    sendPasswordResetEmail: (email: string) => Promise<ApiResult<any>>;
}>({
    authApi: new AuthApi(''),
    username: null,
    email: null,
    loginInProgress: false,
    loginErrors: [],
    roles: [],
    dealerId: null,
    orgId: null,
    siteId: null,
    eulaAccepted: null,
    isDealerActive: null,
    isOrgActive: null,
    isSiteActive: null,
    isGlobalAdmin: false,
    isDealerAdmin: false,
    isOrgAdmin: false,
    isSiteEmployee: false,
    logout: () => { },
    login: () => { },
    isAuthenticated: false,
    refreshToken: () => { },
    changePasswordDialogIsVisible: false,
    setChangePasswordDialogIsVisible: (isVisible: boolean) => { },
    sendPasswordResetEmail: (email: string) => new Promise((r) => { }),
});
