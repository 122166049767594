import { hubConnection } from './provider';
import { SignalRState } from './state';

export const subscribeToEvents = (setValue: Function, logout: Function) => {
    hubConnection.on('serviceConnectionStatusChanged', (siteId: number, status: boolean) => {
        let date = new Date();
        console.log(`${date.getHours()}:${date.getMinutes()} ${status}`);
        setValue((state: SignalRState): SignalRState => {
            return { ...state, serviceStatus: state.serviceStatus.set(siteId, status) };
        });
    });

    hubConnection.on('userSessionChanged', (userName: string, sessionId: string) => {
        try {
            const currentSessionId = localStorage.getItem('sessionId');
            console.debug(
                'userSessionChanged:' + sessionId + ' Current Session Id:' + currentSessionId
            );

            if (sessionId !== currentSessionId) {
                console.debug('logout:' + sessionId);
                logout();
            }
        } catch (error) {
            console.debug(error);
        }
    });

    hubConnection.on('sensorResetCompleted', (siteId: number) => {
        setValue((state: SignalRState): SignalRState => {
            return { ...state, sensorResetCompleted: siteId };
        });
    });

    hubConnection.on('SystemRestartTriggered', (siteId: number) => {
        setValue((state: SignalRState): SignalRState => {
            return { ...state, systemRestartTriggered: siteId };
        });
    });

    hubConnection.on('FingerprintEnrollmentAccepted', (
        userId: number,
        siteId: number,
        code: string,
        requestReference: string,
    ) => {
        setValue((state: SignalRState): SignalRState => {
            return { ...state, enrollmentAcceptedData: { code, requestReference } };
        });
    });

    hubConnection.on('ManualOverrideAccepted', (
        userId: number,
        siteId: number,
        code: string,
        requestReference: string,
    ) => {
        setValue((state: SignalRState): SignalRState => {
            return { ...state, manualOverrideAcceptedData: { code, requestReference } };
        });
    });
};

export const unsubscribeToEvents = () => {
    hubConnection.off('serviceConnectionStatusChanged');
    hubConnection.off('roomStateChanged');
    hubConnection.off('userSessionChanged');
};
