import { useCallback, useState } from "react";

import { AjaxResult } from "../../enums/ajaxResult";
import { SearchTarget } from "../../enums/searchTarget";
import { FoundSiteDto } from "../../dtos/search";
import { useApi } from "../useApi";

type FoundItemDto<T extends SearchTarget> =
  T extends SearchTarget.Sites ? FoundSiteDto[] : never;

export function useSearch<T extends SearchTarget>(target: T): {
    search: (searchValue: string) => void;
    isLoading: boolean;
    errorMsg: string | undefined;
    result: FoundItemDto<T> | undefined;
    clearResult: () => void;
} {
    const { searchApi } = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const [result, setResult] = useState<FoundItemDto<T> | undefined>();

    const search = useCallback(async (searchValue: string) => {
        setIsLoading(true);
        setErrorMsg(undefined);

        const response = await searchApi.getSearchResult<FoundItemDto<T>>(searchValue, target);

        if (response.result === AjaxResult.Success && response.data) {
            setResult(response.data);
        } else {
            setErrorMsg(response.messages ? response.messages.join(". "): `Can't get search results for ${target}`);
        }
        setIsLoading(false);

    }, [searchApi, target]);

    const clearResult = useCallback(() => {
        setResult(undefined);
    }, []);

    return { search, isLoading, result, errorMsg, clearResult };
}
