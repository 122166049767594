import { makeStyles, Typography } from "@material-ui/core";
import { CloudDone, CloudOff } from "@material-ui/icons";
import React, { useContext } from "react";

import SignalRContext from "../../../signalr/context";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        gap: "10px",
    },
}));

export function ServerStatus() {
    const { hubConnected } = useContext(SignalRContext);

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography>Server Status:</Typography>
            <div>{hubConnected ? <CloudDone /> : <CloudOff />}</div>
        </div>
    );
}
