import { TextField as MuiTextField, makeStyles } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import React from "react";

interface StylesProps {
    width?: number;
}

const useStyles = ({
    width = 200,
}: StylesProps) => makeStyles({
    input: {
        padding: "5px",
        width: `${width}px`,
    },
});

export function TextField({
    stylesProps = {},
    ...props
}: TextFieldProps & { stylesProps?: StylesProps }) {
    const classes = useStyles(stylesProps)();
    return (
        <MuiTextField
            placeholder="First Name"
            InputProps={{
                inputProps: { className: classes.input },
            }}
            {...props}
        />
    );
}
