import { useRouterWithTypedParams } from "./useRouterWithTypedParams";
import { useSiteChangeCallback } from "./useSiteChangeCallback";

export const useOrgSiteContext = <TRouteState>(uriResource: string, stateToSave?: TRouteState) => {
    const { match } = useRouterWithTypedParams<{
        dealerId?: number;
        orgId?: number;
        siteId?: number;
    }>();
    const { orgId, dealerId, siteId } = match.params;
    const siteChangeCallback = useSiteChangeCallback(uriResource, stateToSave);
    return { orgId, dealerId, siteId, siteChangeCallback };
};
