import { AxiosApi, ApiResult } from './axiosApi';
import { Box } from '../dtos/box';
import { ExportResult } from '../dtos/export';
import { BoxCustomerDto } from '../dtos/customer';
import { BoxesFilterRequest } from './requestObjects/boxesRequest';

export class BoxApi extends AxiosApi<Box> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, 'boxes', logout, enqueueSnackbar);
    }

    public getBoxesForSite = (
        orgId: number,
        siteId: number,
        options: BoxesFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: options,
            abortController,
            overridePath: `organizations/${orgId}/sites/${siteId}/${this._resourceName}`,
        }) as Promise<ApiResult<{ boxes: Box[]; numberOfPages: number }>>;

    public getBoxesReportForSite = (
        orgId: number,
        siteId: number,
        request: BoxesFilterRequest,
        abortController?: AbortController
    ) =>
        this.genericRequest({
            method: 'POST',
            data: request,
            overridePath: `organizations/${orgId}/sites/${siteId}/boxesreport`,
            abortController,
        }) as Promise<ApiResult<ExportResult>>;

    public getOwners = (boxId: number) =>
        this.genericRequest({ method: 'GET', extraPath: `${boxId}/owners` }) as Promise<
            ApiResult<BoxCustomerDto[]>
        >;

    public addOwner = (boxId: number, customerId: number) =>
        this.genericRequest({
            method: 'POST',
            extraPath: `${boxId}/addOwner/${customerId}`,
        }) as Promise<ApiResult<null>>;

    public removeOwner = (boxId: number, customerId: number) =>
        this.genericRequest({
            method: 'DELETE',
            extraPath: `${boxId}/owner/${customerId}`,
        }) as Promise<ApiResult<null>>;

    public setNotes = (boxId: number, notes: string) =>
        this.genericRequest({
            method: 'PUT',
            extraPath: `${boxId}/notes`,
            data: { notes },
        }) as Promise<ApiResult<null>>;

    public deleteCurrentLease = (boxId: number) =>
        this.genericRequest({
            method: 'DELETE',
            extraPath: `${boxId}/lease`,
        });

    public getBoxFilterOptions = (availableOnly: boolean, orgId: number, siteId: number) =>
        this.genericRequest({
            method: 'GET',
            overridePath: `organizations/${orgId}/sites/${siteId}/boxes/filterOptions${!!availableOnly ? '?availableOnly=true' : ''
                }`,
        }) as Promise<ApiResult<{ locations: string[]; sizes: string[] }>>;
}
