import { Button, Tooltip } from "@material-ui/core";
import React, { useMemo } from "react";

import { Event } from "../../../dtos/event";
import { EventType } from "../../../enums/eventType";
import { RoomStatusAlarmNameDisplay } from "../../../enums/roomStatusAlarmName";
import { SystemErrorNameDisplay } from "../../../enums/systemErrorName";
import { getTimestamp } from "../../../utils/getTimestamp";
import { StandardTable } from "../../shared/table";

import { AlarmNotes } from "./AlarmNotes";

export interface AlarmForDisplay {
    id: number;
    user: string;
    userId: number | null;
    accountType: string | null;
    timestamp: string | undefined;
    eventName: string | undefined;
    description: string;
    acknowledgeInfo: string | undefined;
    acknowledged: boolean;
    notesInfo: string | undefined;
    notes: string | null;
}

interface Props {
    events: Event[];
    filterByLocalTime: boolean | null;
    showAcknowledgeButtons: boolean;
    onAcknowledgeAlarm: (id: number) => void;
    onAlarmNotesUpdated: () => void;
}

export function AlarmTable({
    events,
    filterByLocalTime,
    showAcknowledgeButtons,
    onAcknowledgeAlarm,
    onAlarmNotesUpdated,
}: Props) {
    const alarmsForDisplay: AlarmForDisplay[] = useMemo(
        () => {
            return events.map((e) => {
                let specificAlarmName = undefined;
                switch (e.eventType) {
                    case EventType.RoomStatusAlarm:
                        specificAlarmName = RoomStatusAlarmNameDisplay.get(e.eventName);
                        break;
                    case EventType.SystemError:
                        specificAlarmName = SystemErrorNameDisplay.get(e.eventName);
                        break;
                    default:
                        specificAlarmName = '';
                        break;
                }

                const timestamp = getTimestamp(filterByLocalTime, e.localTimestamp, e.timestamp);
                const acknowledgedTimestamp = getTimestamp(filterByLocalTime, e.acknowledgedOnLocal, e.acknowledgedOnUtc);
                const notesEditedTimestamp = getTimestamp(filterByLocalTime, e.notesEditedOnLocal, e.notesEditedOnUtc);

                const acknowledgeInfo = e.acknowledged
                    ? `${e.acknowledgedByName ? ` by ${e.acknowledgedByName}` : ""
                    }${acknowledgedTimestamp ? ` ${acknowledgedTimestamp}` : ""
                    }`
                    : "No information available";

                const notesInfo = e.notes
                    ? `${e.notesEditedByName ? `last updated by ${e.notesEditedByName}` : ""
                    }${notesEditedTimestamp ? ` ${notesEditedTimestamp}` : ""
                    }`
                    : "No information available";

                return {
                    accountType: e.accountType,
                    description: e.description,
                    eventName: specificAlarmName,
                    timestamp: timestamp,
                    user: e.employeeName || e.customerName,
                    userId: e.employeeId || e.customerId,
                    id: e.id,
                    acknowledgeInfo: acknowledgeInfo,
                    acknowledged: e.acknowledged,
                    notesInfo: notesInfo,
                    notes: e.notes,
                };
            });
        },
        [events, filterByLocalTime]
    );

    return (
        <StandardTable>
            <thead>
                <tr>
                    <th>User</th>
                    <th>User Id</th>
                    <th>Account Type</th>
                    <th>Timestamp</th>
                    <th>Alarm</th>
                    <th>Description</th>
                    <th>Acknowledged</th>
                    <th>Notes</th>
                </tr>
            </thead>

            <tbody>
                {alarmsForDisplay.map((alarm) => (
                    <tr key={`${alarm.id}_${alarm.eventName}`}>
                        <td>{alarm.user}</td>
                        <td>{alarm.userId}</td>
                        <td>{alarm.accountType}</td>
                        <td>{alarm.timestamp}</td>
                        <td>{alarm.eventName}</td>
                        <td>{alarm.description}</td>
                        <td>
                            {alarm.acknowledged
                                ? (
                                    <Tooltip title={alarm.acknowledgeInfo} enterDelay={700}>
                                        <p>ACKNOWLEDGED</p>
                                    </Tooltip>
                                )
                                : (
                                    <>
                                        {showAcknowledgeButtons && (
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                onClick={() => onAcknowledgeAlarm(alarm.id)}
                                            >
                                                Acknowledge
                                            </Button>
                                        )}
                                    </>
                                )
                            }
                        </td>
                        <td>
                            <AlarmNotes
                                alarmId={alarm.id}
                                notes={alarm.notes}
                                notesInfo={alarm.notesInfo}
                                onAlarmNotesUpdated={onAlarmNotesUpdated}
                                alarmAcknowledged={alarm.acknowledged}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </StandardTable>
    )
}
