import { FormControl, TextField } from "@material-ui/core";
import React from "react";

import { ScheduledJobBase, ScheduledJobTarget } from "../../../../dtos/scheduledJob";
import { ScheduledJobType } from "../../../../enums/ScheduledJobType";
import { FormSection } from "../../../shared/formSection";
import { FormField } from "../../../shared/formField";

import { ScheduledJobTypeSelector } from "./TypeSelector";
import { ScheduledJobTargetsFormSection } from "./TargetsFormSection";
import { getTargetLabel } from "./utils";

interface Props {
    jobBase: ScheduledJobBase;
    setJobBase: (jobBase: ScheduledJobBase) => void;
    addedJobTypes: ScheduledJobType[];
}

export function ScheduledJobForm({ jobBase, setJobBase, addedJobTypes }: Props) {
    const handleTypeChange = (type: ScheduledJobType) => {
        setJobBase({ ...jobBase, type });
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setJobBase({ ...jobBase, name: e.target.value });
    };

    const handleAddTarget = (target: ScheduledJobTarget) => {
        setJobBase({ ...jobBase, targets: [...jobBase.targets, target] })
    };

    const handleRemoveTargetByIndex = (indexToRemove: number) => {
        const newTargets = [...jobBase.targets];
        newTargets.splice(indexToRemove, 1)
        setJobBase({ ...jobBase, targets: newTargets })
    }

    const jobTargetIndexLabelMap = jobBase.targets
        .map((it, i) => ({ target: it, index: i, label: getTargetLabel(it) }));

    return (
        <>
            <FormSection headerText="Job Settings">
                <FormField label="Job Name">
                    <FormControl style={{ minWidth: "300px" }}>
                        <TextField
                            value={jobBase.name}
                            onChange={handleNameChange}
                            variant="outlined"
                            InputProps={{
                                inputProps: { style: { padding: "10px" } },
                            }}
                        />
                    </FormControl>
                </FormField>

                <FormField label="Job Type">
                    <FormControl style={{ minWidth: "300px" }}>
                        <ScheduledJobTypeSelector
                            selectedType={jobBase.type}
                            onTypeChange={handleTypeChange}
                            addedTypes={addedJobTypes}
                        />
                    </FormControl>
                </FormField>
            </FormSection>

            <ScheduledJobTargetsFormSection
                targetIndexLabelMap={jobTargetIndexLabelMap}
                isExclusion={false}
                onAddTarget={handleAddTarget}
                onRemoveTargetByIndex={handleRemoveTargetByIndex}
            />

            <ScheduledJobTargetsFormSection
                targetIndexLabelMap={jobTargetIndexLabelMap}
                isExclusion={true}
                onAddTarget={handleAddTarget}
                onRemoveTargetByIndex={handleRemoveTargetByIndex}
            />
        </>
    );
}