import React, { useState } from 'react';
import { Box } from '../../../dtos/box';
import { DatePicker } from '@material-ui/pickers';
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';

export const EndDateEditForm = ({
    box,
    saveDate,
    close
}: {
    box: Box;
    close: () => void;
    saveDate: (date: Date | null | undefined) => void;
}) => {
    const [endDate, setEndDate] = useState<Date | null>(
        box.leaseEndDate ? new Date(box.leaseEndDate) : new Date()
    );
    const [saveInProgress, setSaveInProgress] = useState(false);
    return (
        <Dialog open={true} onClose={close}>
            <DialogContent>
                <div>
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={date => setEndDate(date && date.toDate())}
                        clearable
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        setSaveInProgress(true);
                        saveDate(endDate as Date);
                    }}
                    disabled={saveInProgress}
                >
                    {saveInProgress ? 'Saving...' : 'Save End Date'}
                </Button>
                <Button onClick={close} color="secondary" variant="contained">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
