import React, { useMemo, useContext, useState } from 'react';
import useRouter from 'use-react-router';
import queryString from 'query-string';
import { AuthContext } from './authContext';
import { TextField, Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AjaxResult } from '../enums/ajaxResult';
import { ValidationErrors } from '../components/shared/validationErrors';
import { Link } from 'react-router-dom';

export const PasswordResetPage = () => {
    const { mainContainer, passwordField, passwordFieldLabel, fieldContainer } = useStyles();
    const { authApi } = useContext(AuthContext);
    const { location } = useRouter();
    const { search } = location;
    const params = useMemo(() => queryString.parse(search), [search]);

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const [resetSucceeded, setResetSucceeded] = useState(false);
    const reset = () => {
        if (confirmPassword !== newPassword) {
            setErrors(['Confirm password does not match']);
            return;
        }
        setErrors([]);
        authApi
            .resetPassword(params.email as string, params.code as string, newPassword)
            .then((r) => {
                if (r.result === AjaxResult.Success) {
                    setResetSucceeded(true);
                } else {
                    if (r.messages) {
                        setErrors(r.messages);
                    }
                }
            });
    };
    return (
        <Container className={mainContainer}>
            {resetSucceeded ? null : (
                <>
                    <div className={fieldContainer}>
                        <label className={passwordFieldLabel}>Enter a new password:</label>
                        <TextField
                            type="password"
                            variant="outlined"
                            inputProps={{ className: passwordField }}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.currentTarget.value)}
                        />
                    </div>
                    <div className={fieldContainer}>
                        <label className={passwordFieldLabel}>Confirm new password:</label>
                        <TextField
                            type="password"
                            variant="outlined"
                            inputProps={{ className: passwordField }}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                        />
                    </div>
                    <ValidationErrors errors={errors} />
                    <Button onClick={reset} variant="contained" color="primary">
                        Reset
                    </Button>
                </>
            )}
            {resetSucceeded ? (
                <div>
                    Your password has been reset. You can now go to the{' '}
                    <Link to="/">Home page</Link> and log in.
                </div>
            ) : null}
        </Container>
    );
};

const useStyles = makeStyles({
    passwordField: {
        padding: '5px',
    },
    passwordFieldLabel: {
        marginRight: '10px',
    },
    mainContainer: {
        paddingTop: '40px',
    },
    fieldContainer: {
        marginBottom: '10px',
    },
});
