import React, { useMemo, useContext } from 'react';
import { NavBar } from './navBar';
import { AuthContext } from '../auth/authContext';
import { useAuth } from '../auth/useAuth';
import { LoginForm } from '../auth/loginForm';
import { PageBody } from './shared/pageBody';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useSnackbar } from 'notistack';
import { ConfirmDialog } from './confirmDialog';
import { ConfirmDialogContext } from './confirmContext';
import { useConfirmDialogGlobal } from '../hooks/useConfirmDialog';
import { ApiContext, buildApiContextValue } from './apiContext';
import { OrgSiteService } from './orgSiteContext';
import { SnackBarService } from './snackbarService';
import { ChangePasswordDialog } from '../auth/ChangePasswordDialog';
import useRouter from 'use-react-router';
import { EulaPopup } from './shared/eulaPopup';
import { IdlePopup } from './shared/idlePopup';
import SignalRProvider from '../signalr/provider';

export const App = ({ children }: { children?: any }) => {
    const {
        confirmMessage,
        resolveWithFalse,
        resolveWithTrue,
        showConfirmDialog,
        confirmDialogIsVisible,
        setConfirmDialogIsVisible,
    } = useConfirmDialogGlobal();

    return (
        <SnackBarService>
            <AuthService>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ApiService>
                        <ConfirmDialogContext.Provider value={{ showConfirmDialog }}>
                            <OrgSiteService>
                                <AppContent>
                                    {children}
                                    <ChangePasswordDialog />
                                    <ConfirmDialog
                                        isVisible={confirmDialogIsVisible}
                                        message={confirmMessage}
                                        close={() => setConfirmDialogIsVisible(false)}
                                        confirm={resolveWithTrue}
                                        cancel={resolveWithFalse}
                                    />
                                </AppContent>
                            </OrgSiteService>
                        </ConfirmDialogContext.Provider>
                    </ApiService>
                </MuiPickersUtilsProvider>
            </AuthService>
        </SnackBarService>
    );
};

const AppContent = ({ children }: { children?: any }) => {
    const { username, eulaAccepted, isAuthenticated } = useContext(AuthContext);
    const { location } = useRouter();
    const { pathname } = location;
    const isResetPasswordPage = useMemo(
        () => pathname.toLowerCase().indexOf('resetpassword') !== -1,
        [pathname]
    );

    return (
        <>
            {isResetPasswordPage ? children : null}
            {username && isAuthenticated ? (
                <>
                    <SignalRProvider>
                        {<IdlePopup />}
                        {!eulaAccepted && <EulaPopup />}
                        <NavBar />
                        <PageBody>{children}</PageBody>
                    </SignalRProvider>
                </>
            ) : null}
            {!isResetPasswordPage && (!username || !isAuthenticated) ? <LoginForm /> : null}
        </>
    );
};

const AuthService = ({ children }: { children?: any }) => {
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAuth(enqueueSnackbar);
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const ApiService = ({ children }: { children?: any }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { logout } = useContext(AuthContext);
    const api = useMemo(
        () => buildApiContextValue(logout, enqueueSnackbar),
        [logout, enqueueSnackbar]
    );
    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
