import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const BASE_SERVER_URL: string =
    process.env.REACT_APP_ENV === 'production'
        ? 'https://paznic.app'
        : process.env.REACT_APP_ENV === 'staging'
            ? 'https://staging.paznic.app'
            : process.env.REACT_APP_ENV === 'test'
                ? 'https://paznic-test.devtestops.eu'
                : 'https://localhost:8001';

export const BLOB_STORAGE_URL: string =
    process.env.REACT_APP_ENV === 'production'
        ? 'https://paznic.app/content'
        : process.env.REACT_APP_ENV === 'staging'
            ? 'https://staging.paznic.app/content'
            : process.env.REACT_APP_ENV === 'test'
                ? 'https://paznic-test.devtestops.eu/content'
                : 'https://ssamanagementuidev.blob.core.windows.net';

export const ISSUE_COLLECTOR_SCRIPT: string =
    'https://balticsolutions.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/278rlr/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=b973add0';
export const ISSUE_COLLECTOR_ELEMENT_IDS = ["atlwdg-trigger", "atlwdg-blanket", "atlwdg-container"];

export const WHATS_NEW_PAGE_URL = "https://paznic.com/product-updates-3.html";

export const UI_THEME: ThemeOptions = {
    backgroundThemeColor1: '#fff',
    backgroundThemeColor2: '#eef2f8',
    fontThemeColor1: '#94a8ba',
    fontThemeColor2: '#465564',
    fontThemeColor3: '#192b3d',
    trimThemeColor1: '#3f51b5',
    dangerColor: '#f00',
};

export const IDLE_TIMEOUT_ENABLED: boolean = true; //show idle popup
export const IDLE_TIMEOUT_SECONDS: number = 600; //show idle popup in 10 min
export const IDLE_PROMPT_TIMEOUT_SECONDS: number = 30; //show idle popup for 30 seconds

export const REFRESH_TOKEN_THROTTLE_SECONDS: number = 180; //show idle popup

