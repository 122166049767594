import { Site } from '../dtos/site';
import { SearchTarget } from '../enums/searchTarget';

import { AxiosApi, ApiResult } from './axiosApi';

export class SearchApi extends AxiosApi<Site> {
    constructor(baseUri: string, logout?: () => void, enqueueSnackbar?: any) {
        super(baseUri, 'search', logout, enqueueSnackbar);
    }

    public getSearchResult = <T>(q: string, target: SearchTarget): Promise<ApiResult<T>> => 
        this.genericRequest({
            method: 'GET',
            overridePath: `Search/${target}?q=${q}`,
        });
}
