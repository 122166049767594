import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { OrganizationAggregate } from "../../../dtos/organizationAggregate";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useApi } from "../../useApi";

interface Props {
    onDeleteOrg: (id: number) => void;
    org: OrganizationAggregate;
}

export function DeleteOrgButton({ org, onDeleteOrg }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showConfirmDialog } = useConfirmDialog();
    const { enqueueSnackbar } = useSnackbar();
    const { orgApi } = useApi();

    const deleteOrg = () => {
        showConfirmDialog(`Are you sure you want to delete "${org.name}" organization?`).then((r) => {
            if (r) {
                setIsLoading(true);
                // confirm button was clicked
                orgApi.delete(org.id).then((r) => {
                    if (r.result === AjaxResult.Success) {
                        enqueueSnackbar("Organization deleted!");
                        onDeleteOrg(org.id);
                    } else {
                        enqueueSnackbar("Failed to delete organization", { variant: "error" });
                    }
                    setIsLoading(false);
                });
            }
        });
    };

    return (
        <Button
            color="secondary"
            variant="outlined"
            disabled={isLoading}
            onClick={deleteOrg}
        >
            Delete
        </Button>
    );
}