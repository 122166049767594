import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseApi } from './baseApi';
import { AjaxResult } from '../enums/ajaxResult';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import { BASE_SERVER_URL } from '../constants';

export interface ApiResult<TDataType> {
    result: AjaxResult;
    data?: TDataType;
    messages?: string[];
}

export const axios = Axios.create();

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) => {
    const axiosAuthRefreshOptions: AxiosAuthRefreshRequestConfig = {
        skipAuthRefresh: true,
    };

    return Axios.post(
        `${BASE_SERVER_URL}/api/auth/refreshToken`,
        {
            accessToken: localStorage.getItem('token'),
            refreshToken: decodeURIComponent(getCookie('refreshToken') || ''),
        },
        axiosAuthRefreshOptions
    ).then((tokenRefreshResponse) => {
        localStorage.setItem('token', tokenRefreshResponse.data.accessToken);
        localStorage.setItem(
            'refreshTokenExpDate',
            tokenRefreshResponse.data.refreshTokenExpiration
        );
        document.cookie =
            'refreshToken=' +
            encodeURIComponent(tokenRefreshResponse.data.refreshToken) +
            '; path=/';
        failedRequest.response.config.headers['Authorization'] =
            'Bearer ' + tokenRefreshResponse.data.accessToken;
        return Promise.resolve();
    });
};

function getCookie(name: string) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

createAuthRefreshInterceptor(axios, refreshAuthLogic);

export class AxiosApi<TEntity> extends BaseApi<TEntity> {
    protected genericRequest = async (options: {
        method: 'GET' | 'POST' | 'PUT' | 'DELETE';
        data?: any;
        extraPath?: string;
        overridePath?: string;
        abortController?: AbortController;
        sendDataRaw?: boolean;
        skipTokenRefresh?: boolean;
    }): Promise<ApiResult<any>> => {
        let { method, data, extraPath, overridePath, skipTokenRefresh = false } = options;

        var axiosResult: AxiosResponse<any, any>;

        const axiosAuthRefreshOptions: AxiosAuthRefreshRequestConfig = {
            skipAuthRefresh: skipTokenRefresh,
        };

        const fullResource = overridePath || `${this._resourceName}/${extraPath || ''}`;
        const uri = `${this._fullBaseUri}/${fullResource}`;

        axios.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                config.headers!.Authorization = this._basicHeaders.Authorization;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        try {
            switch (options.method) {
                case 'GET':
                    axiosResult = await axios.get(uri, axiosAuthRefreshOptions);
                    break;
                case 'POST':
                    axiosResult = await axios.post(uri, data, axiosAuthRefreshOptions);
                    break;
                case 'PUT':
                    axiosResult = await axios.put(uri, data, axiosAuthRefreshOptions);
                    break;
                // case "PATCH":
                //     axiosResult = await Axios.patch(uri, data, axiosAuthRefreshOptions);
                //     break;
                case 'DELETE':
                    axiosResult = await axios.delete(uri, axiosAuthRefreshOptions);
                    break;
            }

            return new Promise<ApiResult<any>>((resolve) => {
                if (axiosResult.data) {
                    resolve({ result: AjaxResult.Success, data: axiosResult.data });
                } else {
                    resolve({ result: AjaxResult.Success });
                }
            });
        } catch (error) {
            if (Axios.isAxiosError(error) && error.response) {
                return new Promise<ApiResult<any>>((resolve) => {
                    let err = error as AxiosError;
                    let statusCode =
                        err.response === null || err.response === undefined
                            ? -1
                            : err.response.status;
                    //err.response?.status - TODO: update typescript
                    switch (statusCode) {
                        case 400:
                            resolve({
                                result: AjaxResult.Unauthorized,
                                messages: 
                                    error.response.data && error.response.data.errors
                                        ? error.response.data.errors
                                        : undefined,
                            });
                            if (
                                err &&
                                err.config &&
                                err.config.url &&
                                err.config.url.indexOf('/api/auth/refreshToken') > -1
                            ) {
                                this._logout && this._logout();
                            }
                            break;
                        case 401:
                            resolve({ result: AjaxResult.Unauthorized });
                            //this._logout && this._logout();
                            break;
                        case 403:
                            resolve({ result: AjaxResult.Forbidden });
                            this._enqueueSnackbar(
                                `The ${method} request to ${uri} failed because you have insufficient permission to access it`
                            );
                            break;
                        case 409:
                            resolve({ result: AjaxResult.Duplicate });
                            break;
                        case 500:
                        default:
                            if (axiosResult && axiosResult.data && axiosResult.data.errors) {
                                resolve({
                                    result: AjaxResult.Failure,
                                    messages: axiosResult.data.errors,
                                });
                            } else {
                                resolve({
                                    result: AjaxResult.Failure,
                                    messages:
                                        error.response.data && error.response.data.errors
                                            ? error.response.data.errors
                                            : undefined,
                                });
                            }
                            break;
                    }
                });
            }

            return new Promise<ApiResult<any>>((resolve) => {
                console.log(error);
                resolve({ result: AjaxResult.Failure });
                this._enqueueSnackbar(
                    `Request to ${uri} failed. It's likely the request couldn't reach the server because the server is down`
                );
            });
        }
    };
}

