import React, { useState, useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid,
} from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { AuthContext } from '../../auth/authContext';
import { useApi } from '../useApi';
import { AjaxResult } from '../../enums/ajaxResult';
import { useSnackbar } from 'notistack';
import { BLOB_STORAGE_URL } from '../../constants';

export const EulaPopup = ({ children }: { children?: any }) => {
    const { employeeApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { logout, refreshToken, eulaAccepted } = useContext(AuthContext);
    const [numPages, setNumPages] = useState(null);

    const [eulaPopupOpen, setEulaPopupOpen] = React.useState(eulaAccepted !== true);

    const handleClose = () => {
        logout();
    };

    const handleConfirm = () => {
        employeeApi.acceptEula(true).then((r) => {
            if (r.result === AjaxResult.Success) {
                refreshToken(true);
                setEulaPopupOpen(false);
            } else {
                enqueueSnackbar('ERROR: cannot update EULA settings');
                logout();
            }
        });
    };

    function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
        setNumPages(numPages);
    }

    return (
        <Dialog
            open={eulaPopupOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            disableBackdropClick
        >
            <DialogTitle id="alert-dialog-title">{'Terms and Conditions'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    By accessing or using the Service you agree to be bound by these Terms. If you
                    disagree with any part of the terms then you may not access the Service.
                </DialogContentText>
                <Grid container justify="center">
                    <Document
                        file={{
                            url: `${BLOB_STORAGE_URL}/eula/eula.pdf`,
                        }}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map((page) => (
                                <Page key={page} pageNumber={page} width={800} />
                            ))}
                    </Document>
                </Grid>
            </DialogContent>
            <DialogActions>
                {' '}
                <Button color="primary" variant="contained" onClick={handleConfirm}>
                    Accept
                </Button>
                {/* <div style={{ flex: '1 0 0' }} /> */}
                <Button onClick={handleClose} autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
