import { useCallback } from "react";
import useRouter from "use-react-router";

import { FoundSiteDto } from "../../../dtos/search";
import { navLinkUrl } from "../../../utils";

export function useFoundSite(site: FoundSiteDto): {
    updateCurrentUrl: () => void;
} {
     const { history } = useRouter();

     const updateCurrentUrl = useCallback(() => {
        history.push(navLinkUrl(
            getNavResource(history.location.pathname),
            site.dealerId,
            site.organizationId,
            site.siteId
        ));
     }, [site, history]);

     return { updateCurrentUrl };
}

function getNavResource(pathname: string): string {
    const currentResource = pathname.slice(pathname.lastIndexOf("/") + 1);
    return shouldRedirectToHome(currentResource) ? "home" : currentResource;
}

function shouldRedirectToHome(pagePath: string): boolean {
    return pagePath === "" || pagePath === "config" || isNumber(pagePath)
}

function isNumber(input: string): boolean {
    const digitRegex = /^\d+$/;
    return digitRegex.test(input);
}