export enum EventType {
    AcsEvent = 0,
    ManagementEvent = 1,
    SystemError = 2,
    RoomStatusAlarm = 3,
    SiteEvent = 4,
}

export const ManagementEventDisplays = new Map([
    [EventType.AcsEvent, 'Room Event'],
    [EventType.ManagementEvent, 'Management Event'],
    [EventType.SiteEvent, 'Site Event']
]);

export const AlarmTypeDisplays = new Map([
    [EventType.SystemError, 'System Error'],
    [EventType.RoomStatusAlarm, 'Room Status Alarm']
]);
