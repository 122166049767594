export const navLinkUrl = (
    resource: string,
    dealerId?: number | null,
    orgId?: number | null,
    siteId?: number | null
) => {
    return `${
        dealerId ? `/dealers/${dealerId}` : ''
    }${
        orgId ? `/organizations/${orgId}` : ''
    }${
        siteId ? `/sites/${siteId}` : ''
    }/${resource}`;
};
