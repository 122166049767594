import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";

import { AuthContext } from "../../../auth/authContext";
import { SiteAggregate } from "../../../dtos/siteAggregate";
import { ActiveStatus } from "../../../enums/ActiveStatus";
import { AjaxResult } from "../../../enums/ajaxResult";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { checkIfUserCanActivate } from "../../../utils/activation";
import { useApi } from "../../useApi";

interface Props {
    site: SiteAggregate;
    sitesAreLoading: boolean;
    onSiteActivationChange: () => void;
}

export function ActivationButton({ site, onSiteActivationChange, sitesAreLoading }: Props) {
    const { siteApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const { showConfirmDialog } = useConfirmDialog();
    const auth = useContext(AuthContext);

    const activateSite = () => {
        showConfirmDialog(`Are you sure you want do activate site "${site.name}"?`).then(
            (confirmed) => {
                if (confirmed) {
                    setIsLoading(true);
                    siteApi.activate(site.id).then(r => {
                        if (r.result === AjaxResult.Success) {
                            onSiteActivationChange()
                        } else {
                            enqueueSnackbar(`Failed to activate site "${site.name}"`, { variant: "error" })
                        }
                        setIsLoading(false);
                    });
                }
            }
        );
    }

    const deactivateSite = () => {
        showConfirmDialog(`Are you sure you want do deactivate site "${site.name}"?`).then(
            (confirmed) => {
                if (confirmed) {
                    setIsLoading(true);
                    siteApi.deactivate(site.id).then(r => {
                        if (r.result === AjaxResult.Success) {
                            onSiteActivationChange()
                        } else {
                            enqueueSnackbar(`Failed to deactivate site "${site.name}"`, { variant: "error" })
                        }
                        setIsLoading(false);
                    });
                }
            }
        );
    }

    const isUsersDealerDeactivated = auth.dealerId !== null && !auth.isDealerActive;
    const isUsersOrgDeactivated = auth.orgId !== null && !auth.isOrgActive;
    const isButtonDisabled = isLoading || sitesAreLoading || isUsersDealerDeactivated || isUsersOrgDeactivated;

    if (site.activeStatus === ActiveStatus.Active) {
        return (
            <Button
                disabled={isButtonDisabled}
                onClick={deactivateSite}
                variant="outlined"
                color="secondary"
            >
                Deactivate
            </Button>
        );
    }

    const userCanActivate = checkIfUserCanActivate(
        site.activeStatus,
        auth.isDealerAdmin,
        auth.isGlobalAdmin,
        auth.isOrgAdmin,
    );

    return (
        <Button
            disabled={isButtonDisabled || !userCanActivate}
            onClick={activateSite}
            variant="outlined"
            color="primary"
        >
            Activate
        </Button>
    );
}
