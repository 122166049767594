import React from "react";

import { PageHeader } from "../../PageHeader";

import { AddScheduledJobButton } from "./AddScheduledJobButton";
import { ScheduledJobsTable } from "./ScheduledJobsTable";
import { useLoadScheduledJobs } from "./useLoad";

export function ScheduledJobsPage() {
    const { jobs, setJobs, loading, load } = useLoadScheduledJobs();
    const addedJobTypes = jobs.map(it => it.type);

    return (
        <>
            <PageHeader isLoading={loading}>
                Scheduled Jobs
            </PageHeader>

            <AddScheduledJobButton
                onJobAdded={(job) => setJobs([...jobs, job])}
                addedJobTypes={addedJobTypes}
            />

            <ScheduledJobsTable
                jobs={jobs}
                setJobs={setJobs}
                loadJobs={load}
                addedJobTypes={addedJobTypes}
            />
        </>
    );
}
