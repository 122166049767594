import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useEffect } from "react";

import { DialogContentsError } from ".";

interface Props {
    onClose: () => void;
    onAcceptSuccess: (code: string) => void;
    onAcceptFail: () => void;
    requestReference: string | undefined;
    acceptedData: { code: string; requestReference: string; } | undefined;
}

export function DialogContentsWaitingResponse({
    onClose,
    onAcceptSuccess,
    onAcceptFail,
    requestReference,
    acceptedData
}: Props) {

    useEffect(() => {
        if (!acceptedData || !requestReference) return;

        if (acceptedData.requestReference === requestReference) {
            onAcceptSuccess(acceptedData.code);
        }
    }, [acceptedData, onAcceptSuccess, requestReference]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onAcceptFail();
        }, 1000 * 60 * 3);
        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!requestReference) {
        return (
            <DialogContentsError
                onClose={onClose}
                error="Something went wrong"
            />
        );
    }

    return (
        <>
            <DialogTitle style={{ textAlign: "center" }}>
                Waiting for site response
            </DialogTitle>

            <DialogContent style={{ textAlign: "center" }}>
                <CircularProgress />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
}