import { useEffect, useMemo, useState } from "react";

import { Site } from "../../../../dtos/site";
import { AjaxResult } from "../../../../enums/ajaxResult";
import { useApi } from "../../../useApi";

export function useSiteOptions(
    selectedSiteId: number | null,
    organizationId: number | null,
    withOptionAll: boolean,
) {
    const [sites, setSites] = useState<Site[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { siteApi } = useApi();

    useEffect(() => {
        if (!organizationId) {
            setSites([]);
            return;
        };
        let cancel = false;
        setIsLoading(true);
        siteApi.getForOrg(organizationId).then((r) => {
            if (!cancel && r.result === AjaxResult.Success && r.data) {
                setSites(r.data);
            }
            setIsLoading(false);
        });
        return () => {
            cancel = true;
        };
    }, [organizationId, siteApi]);

    const siteOptions = useMemo(() => {
        const options = sites
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(it => ({ label: it.name, value: it.id }));
        return withOptionAll ? [{ value: 0, label: 'All' }, ...options] : options;
    }, [sites, withOptionAll]);

    const selectedSiteOption = useMemo(() => {
        return siteOptions.find(it => it.value === selectedSiteId);
    }, [siteOptions, selectedSiteId]);

    return { siteOptions, selectedSiteOption, isLoading };
}
