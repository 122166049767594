import { createContext } from 'react';

export const ConfirmDialogContext = createContext<{
    showConfirmDialog: (message: string) => Promise<boolean>;
}>({
    showConfirmDialog: (message: string) =>
        new Promise<boolean>(r => {
            r(false);
        })
});
