import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

enum TimeZoneSwitchValue {
    Utc = "utc",
    Local = "local",
}

const TIME_ZONE_SWITCH_LABELS: { [key in TimeZoneSwitchValue]: string } = {
    [TimeZoneSwitchValue.Local]: "Local",
    [TimeZoneSwitchValue.Utc]: "UTC",
};

interface Props {
    localTimeZoneSelected?: boolean | null;
    onLocalTimeZoneSelected?: (localTime: boolean) => void;
}

export function TimeZoneSwitch({
    localTimeZoneSelected,
    onLocalTimeZoneSelected,
}: Props) {
    const [timeZone, setTimeZone] = useState<TimeZoneSwitchValue>(
        localTimeZoneSelected
            ? TimeZoneSwitchValue.Local
            : TimeZoneSwitchValue.Utc
    )

    const handleChange = useCallback((ev: unknown, value: string) => {
        setTimeZone(value as unknown as TimeZoneSwitchValue);
    }, []);

    useEffect(() => {
        if (!onLocalTimeZoneSelected) return;
        onLocalTimeZoneSelected(timeZone === TimeZoneSwitchValue.Local);
    }, [timeZone, onLocalTimeZoneSelected]);

    return (
        <FormControl>
            <RadioGroup
                row={true}
                value={timeZone}
                name="radio-buttons-group"
                onChange={handleChange}
            >
                {[TimeZoneSwitchValue.Local, TimeZoneSwitchValue.Utc]
                    .map(value => (
                        <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio />}
                            label={TIME_ZONE_SWITCH_LABELS[value]}
                            checked={timeZone === value}
                        />
                    ))
                }
            </RadioGroup>
        </FormControl>
    );
}
