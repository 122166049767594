import React from 'react';
import { useAlarmPageState } from './state';
import { AlarmPage } from './alarmPage';

/**
 * you can use a query string parameter of "to" with this component
 */
export const AlarmsPageContainer = () => {
    const state = useAlarmPageState();
    return <AlarmPage {...state} />;
};

export default AlarmsPageContainer;
