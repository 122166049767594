import { Theme, Tooltip, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import { EventForDisplay } from "../../../../dtos/event";
import { ViewEmployeeDialog } from "./ViewEmployeeDialog";

interface Props {
    event: EventForDisplay;
}

export const ViewEmployeeLink = ({ event }: Props) => {
    const classes = useStyles();
    const { isDeleted, isEmployee, userId } = event;
    const [employeeIdForView, setEmployeeIdForView] = useState<number | null>(null);


    if (!isEmployee) {
        return <p>{userId}</p>
    }

    const isDeleted2 = isDeleted || event.userId === 63
    return (
        <>
            <Tooltip
                title={isDeleted2 ? "Employee is deleted" : "Click to open Employee Info"}
                enterDelay={500}
                placement="top"
            >
                <p
                    className={isDeleted2 ? classes.deletedEmployeeId : classes.employeeViewLink}
                    onClick={isDeleted2 ? undefined : () => setEmployeeIdForView(event.userId)}
                >
                    {event.userId}
                </p>
            </Tooltip>
            <ViewEmployeeDialog
                open={Boolean(employeeIdForView)}
                employeeId={employeeIdForView}
                onClose={() => setEmployeeIdForView(null)}
            />
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    employeeViewLink: {
        cursor: "pointer",
        fontWeight: "bold",
        color: theme.palette.primary.main,
    },
    deletedEmployeeId: {
        cursor: "not-allowed",
        fontWeight: "bold",
        color: theme.palette.grey[600],
    },
}));
