import { Icon, Paper, makeStyles } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../auth/authContext";


const useStyles = makeStyles(() => ({
    container: {
        alignSelf: "stretch",
        padding: "10px",
        minWidth: "400px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        backgroundColor: "#fff9db",
        border: "1px solid #ffe066",
        borderRadius: "4px",
    },
    message: {
        margin: 0,
    },
}));

export function BannerAboutDeactivation() {
    const classes = useStyles();
    const [message, setMessage] = useState<string>();
    const {
        dealerId,
        orgId,
        siteId,
        isOrgActive,
        isSiteActive,
        isDealerActive,
    } = useContext(AuthContext);

    useEffect(() => {
        if (dealerId !== null && !isDealerActive) {
            setMessage("Assigned Dealer is deactivated!")
        }
        if (orgId !== null && !isOrgActive) {
            setMessage("Assigned Organization is deactivated!")
        }
        if (siteId === null && !isSiteActive) {
            setMessage("Assigned Dealer is deactivated!")
        }
    }, [
        dealerId,
        orgId,
        siteId,
        isOrgActive,
        isSiteActive,
        isDealerActive,
    ])

    if (!message) {
        return null;
    }

    return (
        <Paper
            className={classes.container}
            elevation={0}
        >
            <Icon>
                <WarningRounded />
            </Icon>
            <p className={classes.message}>{message}</p>
        </Paper>
    );
}