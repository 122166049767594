import React from 'react';
import { ReportLogPage } from './eventLogPage';
import { useEventLogPageState } from './state';

/**
 * you can use a query string parameter of "to" with this component
 */
export const ReportsPageContainer = () => {
    const state = useEventLogPageState();
    return <ReportLogPage {...state} />;
};

export default ReportsPageContainer;
