import React from 'react';
import {
    styled,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { HelpVideo } from '../../../enums/helpVideo';
import { BLOB_STORAGE_URL } from '../../../constants';

interface Props {
    videoToFocus: HelpVideo;
}

export interface VideoItem {
    enumIndex: HelpVideo;
    title: string;
    link: string;
}

export default function HelpModal(props: Props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function VideoHtml(title: string, link: string, ref: HelpVideo) {
        return (
            <div key={title}>
                <VideoTitle ref={React.useCallback((node) => assignRef(node, ref), [ref])}>
                    {title}
                </VideoTitle>
                <video src={link} controls width="100%">
                    your browser does not support the video tag.
                </video>
            </div>
        );
    }

    const assignRef = (node: any, videoToFocus: HelpVideo) => {
        if (node !== null && props.videoToFocus === videoToFocus) {
            if (node !== null) {
                node.scrollIntoView(true);
            }
        }
    };

    //Video list! - Add in the position you want the new video to display in the modal. Make sure to also add a enum entry at ../../../enums/helpVideo
    const videos: Array<VideoItem> = [
        {
            enumIndex: HelpVideo.Login,
            title: 'Login + Home',
            link: `${BLOB_STORAGE_URL}/help-videos/paznic-1-login-home.mp4`,
        },
        {
            enumIndex: HelpVideo.Customer,
            title: 'Customers',
            link: `${BLOB_STORAGE_URL}/help-videos/paznic-2-customers.mp4`,
        },
        {
            enumIndex: HelpVideo.Boxes,
            title: 'Boxes',
            link: `${BLOB_STORAGE_URL}/help-videos/paznic-3-boxes.mp4`,
        },
        {
            enumIndex: HelpVideo.ManualOverride,
            title: 'Manual Override',
            link: `${BLOB_STORAGE_URL}/help-videos/paznic-4-manual-override.mp4`,
        },
        {
            enumIndex: HelpVideo.Employee,
            title: 'Employees',
            link: `${BLOB_STORAGE_URL}/help-videos/paznic-5-employees.mp4`,
        },
        {
            enumIndex: HelpVideo.Report,
            title: 'Reports',
            link: `${BLOB_STORAGE_URL}/help-videos/paznic-6-reports.mp4`,
        },
        {
            enumIndex: HelpVideo.Alarm,
            title: 'Alarms',
            link: `${BLOB_STORAGE_URL}/help-videos/paznic-7-alarms.mp4`,
        },
        {
            enumIndex: HelpVideo.Site,
            title: 'Sites',
            link: `${BLOB_STORAGE_URL}/help-videos/paznic-8-sites.mp4`,
        },
    ];

    //This creates the Html from the video list on render.
    const videoHtml = [];
    for (const v of videos) {
        videoHtml.push(VideoHtml(v.title, v.link, v.enumIndex));
    }

    return (
        <div>
            <Button
                onClick={handleClickOpen()}
                color="primary"
                variant="outlined"
                size="small"
            >
                Help
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle id="scroll-dialog-title" color="primary">
                    Help Videos
                </DialogTitle>
                <DialogContent dividers={true}>{videoHtml}</DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

//Styling
const VideoTitle = styled('div')({
    fontSize: 'larger',
    paddingBottom: '0.5em',
    paddingTop: '1.5em',
});
