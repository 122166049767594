import React from "react";
import { ValueType } from "react-select/src/types";

import { DialogSelect } from "../../dialogSelect";
import { FormField } from "../../formField";

import { useDealerOptions } from "./useDealerOptions";
import { useUserDealerOption } from "./useUserDealerOption";

interface Props {
    selectedDealerId: number | null;
    handleChange: (value: ValueType<{ value: number; label: string }>) => void;
    showLabel: boolean;
    fullWidth: boolean;
    canGetOptions: boolean;
    disabled: boolean;
    className?: string | undefined;
}

export function DealerSelect({
    selectedDealerId,
    handleChange,
    showLabel,
    fullWidth,
    canGetOptions,
    disabled,
    className,
}: Props) {
    const { dealerOptions, selectedDealerOption, isLoading } = useDealerOptions(selectedDealerId, canGetOptions);
    const userDealerOption = useUserDealerOption();

    return (
        <FormField label={showLabel ? "Dealer" : undefined}>
            <DialogSelect
                options={dealerOptions}
                value={userDealerOption || selectedDealerOption}
                isDisabled={disabled}
                isClearable
                onChange={handleChange}
                placeholder="Select dealer..."
                fullWidth={fullWidth}
                className={className}
                isLoading={isLoading}
            />
        </FormField>
    );
}
