import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import linq from "linq";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ValueType } from "react-select/src/types";

import { AjaxResult } from "../../../enums/ajaxResult";
import { useSitesWithUserActiveLeases } from "../../../hooks/useSitesWithUserActiveLeases";
import { useApi } from "../../useApi";
import { DialogSelect } from "../dialogSelect";

interface OptionType { value: number; label: string };

const useStyles = makeStyles(() => ({
    select: {
        minWidth: '200px',
    },
}));

interface Props {
    user: { id: number; activeLeaseSiteIds?: number[] } | undefined;
    orgId: number | undefined;
    siteId: number | undefined;
    onClose: () => void;
    onEnrollRequestSuccess: (requestReference: string) => void;
    onEnrollRequestFail: () => void;
    onCodeReceived: (code: string) => void;
}

export function DialogContentsSendingRequest({
    user,
    orgId,
    siteId,
    onClose,
    onEnrollRequestSuccess,
    onEnrollRequestFail,
    onCodeReceived,
}: Props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { customerApi, employeeApi } = useApi();
    const [selectedSiteId, setSelectedSiteId] = useState<number>();
    const [sendingRequest, setSendingRequest] = useState<boolean>(false);
    const { loading: loadingSites, sites } = useSitesWithUserActiveLeases(user, orgId);

    useEffect(() => {
        setSelectedSiteId(siteId);
    }, [siteId, user]);

    const siteOptions = useMemo(() => {
        return linq
            .from(sites)
            .orderBy((o) => o.name)
            .select((o) => ({ label: o.name, value: o.id }))
            .toArray();
    }, [sites]);

    const handleSiteChange = useCallback((value: ValueType<OptionType>) => {
        setSelectedSiteId(value ? (value as OptionType).value : undefined);
    }, []);

    const enrollBiometrics = () => {
        if (!user || !selectedSiteId) return;
        setSendingRequest(true);
        (user.activeLeaseSiteIds ? customerApi : employeeApi).createFingerprintEnrollment(user.id, { siteId: selectedSiteId }).then((r) => {
            if (r.result === AjaxResult.Success && r.data) {
                if (r.data.requestReference) {
                    onEnrollRequestSuccess(r.data.requestReference);
                } else if (r.data.temporaryCode) {
                    onCodeReceived(r.data.temporaryCode);
                }
            } else {
                enqueueSnackbar("Failed to request enrollment", { variant: "error" });
                onEnrollRequestFail()
            }
            setSendingRequest(false);
        });
    };

    const selectedSiteOption = useMemo(() => {
        return linq.from(siteOptions).firstOrDefault((s) => s.value === selectedSiteId) || null;
    }, [siteOptions, selectedSiteId]);

    const enrollUnavailable = user && siteOptions.length === 0;

    if (sendingRequest) {
        return (
            <>
                <DialogTitle style={{ textAlign: "center" }}>
                    Sending request to site
                </DialogTitle>

                <DialogContent style={{ textAlign: "center" }}>
                    <CircularProgress />
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </>
        );
    }

    if (!loadingSites && enrollUnavailable) {
        return (
            <>
                <DialogTitle style={{ textAlign: "center" }}>
                    Sending request to site
                </DialogTitle>

                <DialogContent style={{ textAlign: "center" }}>
                    <div>
                        Unable to enroll biometrics for customer with no active box lease.
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </>
        );
    }

    return (
        <>
            <DialogTitle style={{ textAlign: "center" }}>
                Select Site
            </DialogTitle>

            <DialogContent>
                <DialogSelect
                    value={selectedSiteOption}
                    options={siteOptions}
                    isDisabled={orgId === undefined}
                    isClearable
                    isLoading={loadingSites}
                    onChange={handleSiteChange}
                    placeholder="Select site..."
                    className={classes.select}
                />
            </DialogContent>

            <DialogActions>
                <Button disabled={!selectedSiteId || sendingRequest} onClick={enrollBiometrics}>
                    Enroll
                </Button>

                <Button onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </>
    )
}