import { Check, Clear, Remove } from "@material-ui/icons";
import React from "react";

import { PasswordComplexityLevel } from "./PasswordComplexityLevel";

const PASSWORD_COMPLEXITY_LEVELS_IN_ORDER = [
    PasswordComplexityLevel.Length,
    PasswordComplexityLevel.Case,
    PasswordComplexityLevel.Mix,
] as const;

const PASSWORD_COMPLEXITY_DESCRIPTIONS: { [key in PasswordComplexityLevel]: string } = {
    [PasswordComplexityLevel.Length]: "contains at least 8 characters",
    [PasswordComplexityLevel.Case]: "contains both lower (a-z) and upper case letters (A-Z)",
    [PasswordComplexityLevel.Mix]: "contains both numbers (0-9) and a symbol",
};

interface Props {
    password: string;
    failedComplexityLevel: PasswordComplexityLevel | undefined
}

export function PasswordComplexityHint({ password, failedComplexityLevel }: Props) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {PASSWORD_COMPLEXITY_LEVELS_IN_ORDER.map((complLevel, levelIndex, arr) => {
                    let icon = <Remove style={{ fontSize: 14 }} />;
                    let color = undefined;
                    const failedLevelIndex = arr.findIndex((level) => level === failedComplexityLevel);

                    if (levelIndex === failedLevelIndex) {
                        icon = <Clear style={{ fontSize: 14, color: "red" }} />;
                        color = "red";
                    }
                    if (levelIndex < failedLevelIndex || (failedLevelIndex === -1 && password.length > 0)) {
                        icon = <Check style={{ fontSize: 14, color: "green" }} />;
                        color = "green";
                    }

                    return (
                        <div
                            key={levelIndex}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "16px",
                                marginBottom: "10px",
                            }}
                        >
                            {icon}

                            <p
                                style={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color,
                                    margin: 0,
                                }}>
                                {PASSWORD_COMPLEXITY_DESCRIPTIONS[complLevel]}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
