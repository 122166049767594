import { IconButton, makeStyles } from "@material-ui/core";
import { FiberManualRecord, VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import React, { useState } from "react";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
    },
    pinDigitField: {
        minWidth: "24px",
        minHeight: "24px",
        background: "#f1f3f5",
        border: "1px solid #ced4da",
        fontSize: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));


interface Props {
    code: string;
}

export function PinCodePreview({ code }: Props) {
    const classes = useStyles();
    const [hiddenPin, setHiddenPin] = useState(true);

    return (
        <div className={classes.container}>
            {code.split("").map((digit, i) => (
                <div key={i} className={classes.pinDigitField}>
                    {hiddenPin
                        ? <FiberManualRecord fontSize="small" />
                        : digit
                    }
                </div>
            ))}
            <IconButton
                size="small"
                onClick={() => setHiddenPin(prev => !prev)}
            >
                {hiddenPin
                    ? <VisibilityOutlined />
                    : <VisibilityOffOutlined />
                }
            </IconButton>
        </div>
    );
}