export enum TimeZone {
    UTC = "UTC",
    NewfoundlandStandardTime = "Newfoundland Standard Time",
    AtlanticStandardTime = "Atlantic Standard Time",
    EasternStandardTime = "Eastern Standard Time",
    CanadaCentralStandardTime = "Canada Central Standard Time",
    CentralStandardTime = "Central Standard Time",
    USMountainStandardTime = "US Mountain Standard Time",
    MountainStandardTime = "Mountain Standard Time",
    PacificStandardTime = "Pacific Standard Time",
    AlaskanStandardTime = "Alaskan Standard Time",
    HawaiianStandardTime = "Hawaiian Standard Time",
}

export const TIME_ZONE_LABELS: { [key in TimeZone]: string } = {
    [TimeZone.UTC]: "(UTC) Coordinated Universal Time",
    [TimeZone.NewfoundlandStandardTime]: "(GMT-03:30) Newfoundland",
    [TimeZone.AtlanticStandardTime]: "(GMT-04:00) Atlantic Time (Canada)",
    [TimeZone.EasternStandardTime]: "(GMT-05:00) Eastern Time (US & Canada)",
    [TimeZone.CanadaCentralStandardTime]: "(GMT-06:00) Saskatchewan",
    [TimeZone.CentralStandardTime]: "(GMT-06:00) Central Time (US & Canada)",
    [TimeZone.USMountainStandardTime]: "(GMT-07:00) Arizona",
    [TimeZone.MountainStandardTime]: "(GMT-07:00) Mountain Time (US & Canada)",
    [TimeZone.PacificStandardTime]: "(GMT-08:00) Pacific Time (US & Canada)",
    [TimeZone.AlaskanStandardTime]: "(GMT-09:00) Alaska",
    [TimeZone.HawaiianStandardTime]: "(GMT-10:00) Hawaii",
};

