import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { makeStyles } from '@material-ui/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const Pagination = ({
    page,
    pageCount,
    setPage
}: {
    page: number;
    pageCount: number;
    setPage: (page: number) => void;
}) => {
    const classes = useStyles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setPage(0), [pageCount]);

    return (
        <ReactPaginate
            previousLabel={'Prev'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={classes.break}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={data => {
                setPage(data.selected);
            }}
            containerClassName={classes.paginationContainer}
            activeLinkClassName={classes.active}
            forcePage={page}
            pageLinkClassName={classes.pageLink}
            nextLinkClassName={classes.next}
            previousLinkClassName={classes.previous}
        />
    );
};

const useStyles = makeStyles((theme: ThemeOptions) => {
    const standardLinkStyle = {
        color: theme.fontThemeColor1,
        cursor: 'pointer'
    };

    return {
        paginationContainer: {
            listStylePosition: 'inside',
            listStyleType: 'none',
            paddingLeft: 0,
            userSelect: 'none',

            '& li': {
                display: 'inline-block',
                marginRight: '5px'
            }
        },
        pageLink: standardLinkStyle,
        previous: standardLinkStyle,
        next: standardLinkStyle,
        active: {
            color: theme.trimThemeColor1,
            fontWeight: 'bold',
            textDecoration: 'underline'
        },
        break: standardLinkStyle
    };
});
