import { ClickAwayListener, Paper, Popper, Typography } from "@material-ui/core";
import React from "react";

import { SearchTarget } from "../../enums/searchTarget";

import { isArrayOfFoundSites } from "./helpers";
import { FoundSitesTable } from "./FoundSitesTable";

interface Props {
    anchorEl:  null | HTMLElement;
    searchTarget: SearchTarget;
    foundItems: Array<unknown> | undefined;
    isOpen: boolean;
    onClose: () => void;
    errorMsg: string | undefined;
}

export function SearchResultPopper({
    anchorEl,
    searchTarget,
    foundItems,
    onClose,
    isOpen,
    errorMsg,
}: Props) {
    return (
        <ClickAwayListener onClickAway={onClose}>
            <Popper
                open={isOpen}
                anchorEl={anchorEl}
                style={{ zIndex: 2 }}
            >
                <Paper
                    elevation={5}
                    style={{
                        marginTop: "5px",
                        padding: "10px",
                        minWidth: "600px",
                        width: "800px",
                        maxHeight: "50vh",
                        overflow: "auto",
                    }}
                >
                    {errorMsg && <ResultError msg={errorMsg} />}
                    
                    {searchTarget === SearchTarget.Sites && isArrayOfFoundSites(foundItems)
                        ? <FoundSitesTable sites={foundItems} onItemClick={onClose} />
                        : <ResultError msg="Can't handle received site search results" />
                    }
                </Paper>
            </Popper>
        </ClickAwayListener>
    );
}

function ResultError({ msg }: { msg: string }) {
    return (
        <Typography color="error" align="center">{msg}</Typography>
    );
}