import React, { useState, useEffect, useContext, useCallback } from 'react';

import { HubConnectionBuilder } from '@microsoft/signalr';

import { BASE_SERVER_URL } from '../constants';

import SignalRContext from './context';
import { SignalRState, initialState } from './state';
import { subscribeToEvents, unsubscribeToEvents } from './events';
import { AuthContext } from '../auth/authContext';

export const hubConnection = new HubConnectionBuilder()
    .withUrl(`${BASE_SERVER_URL}/hub/managementHub`, {
        accessTokenFactory: () =>
            (localStorage.getItem('token') != null
                ? localStorage.getItem('token')
                : sessionStorage.getItem('token')) || '',
    })
    .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
    .build();

const SignalRProvider = (props: { children: any }) => {
    const [value, setValue] = useState<SignalRState>(initialState);
    const { logout } = useContext(AuthContext);

    const clearValuePropWithTimeout = useCallback((prop: keyof SignalRState) => {
        let timeout = setTimeout(() => {
            setValue(prev => ({ ...prev, [prop]: undefined }));
        }, 10);
        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (value.sensorResetCompleted) {
            return clearValuePropWithTimeout("sensorResetCompleted");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.sensorResetCompleted]);

    useEffect(() => {
        if (value.systemRestartTriggered) {
            return clearValuePropWithTimeout("systemRestartTriggered");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.systemRestartTriggered]);

    useEffect(() => {
        let cancel = false;

        async function startConnection() {
            try {
                if (cancel) return;
                await hubConnection.start();
                setValue((state: SignalRState) => {
                    return { ...state, hubConnected: true };
                });
            } catch (err) {
                // try to reconnect after a brief delay
                setTimeout(async () => await startConnection(), 5000);
            }
        }

        if (hubConnection) {
            subscribeToEvents(setValue, logout);

            hubConnection.onreconnecting((error) => {
                if (!cancel) {
                    setValue((state: SignalRState) => {
                        return { ...state, hubConnected: false };
                    });
                    console.log('SignalR: reconnecting');
                }
            });

            hubConnection.onreconnected((error) => {
                if (!cancel) {
                    setValue((state: SignalRState) => {
                        return { ...state, hubConnected: true };
                    });
                    console.log('SignalR: reconnected');
                }
            });

            hubConnection.onclose(async () => {
                if (!cancel) {
                    setValue((state: SignalRState) => {
                        return { ...state, hubConnected: false };
                    });
                    await startConnection();
                }
            });

            startConnection();
        }

        return () => {
            console.log('SignalR: cleanup');
            cancel = true;
            unsubscribeToEvents();
            hubConnection.stop();
        };
    }, [logout]);

    return <SignalRContext.Provider value={value}>{props.children}</SignalRContext.Provider>;
};

export default SignalRProvider;
