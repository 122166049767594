import React, { useState } from 'react';
import { Customer, CustomerWithBoxes } from '../../../dtos/customer';
import { StandardTable } from '../../shared/table';
import { CustomerForm } from './form';
import { Button, Link, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { TooltipWrapper } from '../../shared/tooltip';
import { AjaxResult } from '../../../enums/ajaxResult';
import { useApi } from '../../useApi';
import { Link as RouterLink } from 'react-router-dom';
import { Pagination } from '../../shared/pagination';
import { ItemsPerPageInput } from '../../shared/itemsPerPageInput';
import { SearchBar } from '../events/searchBar';
import { BulkImport } from './bulkImport';
import moment from 'moment';
import { useOrgSiteContext } from '../../../hooks/useOrgSiteContext';
import { EnrollBiometricsDialog } from '../../shared/EnrollBiometricsDialog';
import { EnrollBiometricsButton } from '../../shared/EnrollBiometricsButton';
import { ButtonsContainer } from '../../shared/ButtonsContainer';

import { DeleteCustomerDialog } from './DeleteCustomerDialog';

export const CustomersPage = ({
    orgId,
    siteId,
    children,
    customers,
    setCustomers,
    page,
    itemsPerPage,
    numberOfPages,
    searchText,
    isLoading,
    importAvailable,
    addCustomer,
    editCustomer,
    setPage,
    setItemsPerPage,
    search,
}: {
    orgId?: number;
    siteId?: number;
    children?: any;
    customers: Array<Customer | CustomerWithBoxes>;
    setCustomers: (customers: Array<Customer | CustomerWithBoxes>) => void;
    page: number;
    itemsPerPage: number;
    numberOfPages: number;
    searchText: string;
    isLoading: boolean;
    importAvailable: boolean;
    addCustomer: (customer: Customer) => void;
    editCustomer: (customer: Customer) => void;
    setPage: (page: number) => void;
    setItemsPerPage: (items: number) => void;
    search: (text: string) => void;
}) => {
    const classes = useStyles();
    const { importApi } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const [newCustomerFormIsVisible, setNewCustomerFormIsVisible] = useState(false);
    const [customerToEdit, setCustomerToEdit] = useState<Customer | undefined>();
    const [customerToEnroll, setCustomerToEnroll] = useState<Customer | undefined>(undefined);
    const [customerToDelete, setCustomerToDelete] = useState<Customer | undefined>(undefined);
    const [bulkImportVisible, setBulkImportVisible] = useState<boolean>(false);

    const {
        dealerId,
        orgId: contextOrgId,
        siteId: contextSiteId,
        //siteChangeCallback,
    } = useOrgSiteContext('customers');

    const onCustomerCreated = (newCustomer: Customer) => {
        setNewCustomerFormIsVisible(false);
        enqueueSnackbar('Customer registered!', { variant: 'success' });
        addCustomer(newCustomer);
    };

    const onCustomerEdited = (editedCustomer: Customer) => {
        setNewCustomerFormIsVisible(false);
        setCustomerToEdit(undefined);
        editCustomer(editedCustomer);
    };

    const exportCustomers = () => {
        if (orgId) {
            importApi.exportCustomersForOrg(orgId, siteId).then((r) => {
                if (r.result === AjaxResult.Success && r.data) {
                    console.log(r.data);
                    var exportFileLink = document.createElement('a');
                    var file = new Blob([r.data.csvData], { type: 'text/plain' });
                    exportFileLink.href = URL.createObjectURL(file);
                    exportFileLink.download = `Customers ${moment().format(
                        'YYYY-MM-DDTHH_mm_ss'
                    )}.csv`;
                    exportFileLink.click();
                    exportFileLink.remove();
                } else {
                    alert('ERROR: failed to export customers');
                }
            });
        }
    };

    const organizationSelected = !!orgId;
    const siteSelected = !!siteId;
    return (
        <>
            <div>{children}</div>
            {!newCustomerFormIsVisible ? (
                <>
                    <SearchBar
                        defaultSearchText={searchText}
                        search={search}
                        labelText="Search Customers..."
                    />

                    <ButtonsContainer>
                        <TooltipWrapper
                            title={
                                !organizationSelected
                                    ? 'You must select an organization to add a customer'
                                    : !siteSelected
                                        ? 'You must select a site to add a customer'
                                        : undefined
                            }
                        >
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setCustomerToEdit(undefined);
                                        setNewCustomerFormIsVisible(true);
                                    }}
                                    disabled={!organizationSelected || !siteSelected}
                                >
                                    Register New
                                </Button>
                            </span>
                        </TooltipWrapper>
                        <TooltipWrapper
                            title={
                                !organizationSelected
                                    ? 'You must select an organization to import customers and box assignments'
                                    : undefined
                            }
                        >
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setBulkImportVisible(true);
                                    }}
                                    disabled={!importAvailable || !organizationSelected}
                                >
                                    Bulk Import
                                </Button>
                            </span>
                        </TooltipWrapper>
                        <TooltipWrapper
                            title={
                                !organizationSelected
                                    ? 'You must select an organization to export customers and box assignments'
                                    : undefined
                            }
                        >
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={exportCustomers}
                                    disabled={!organizationSelected}
                                >
                                    Bulk Export
                                </Button>
                            </span>
                        </TooltipWrapper>
                    </ButtonsContainer>
                    {bulkImportVisible && (
                        <BulkImport
                            orgId={orgId}
                            siteId={siteId}
                            closeForm={() => setBulkImportVisible(false)}
                        />
                    )}
                    <div className={classes.paginationContainer}>
                        <Pagination {...{ setPage, page, pageCount: numberOfPages }} />
                        <ItemsPerPageInput value={itemsPerPage} onChange={setItemsPerPage} />
                    </div>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <StandardTable>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                    {!organizationSelected && <th>Organization</th>}
                                    <th>Notes</th>
                                    <th>Boxes</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map((c) => (
                                    <tr key={c.id}>
                                        <td>{c.fullName}</td>
                                        <td>{c.phone}</td>
                                        <td>{c.email}</td>
                                        <td>{c.address}</td>
                                        {!organizationSelected && <td>{c.organization}</td>}
                                        <td>{c.notes}</td>
                                        <td>
                                            <ul className={classes.boxesList}>
                                                {(c as CustomerWithBoxes).boxes &&
                                                    (c as CustomerWithBoxes).boxes.map((b) => (
                                                        <li key={b.id}>
                                                            <Link
                                                                component={RouterLink}
                                                                to={`${dealerId
                                                                    ? `/dealers/${dealerId}`
                                                                    : ''
                                                                    }${orgId
                                                                        ? `/organizations/${contextOrgId}`
                                                                        : ''
                                                                    }${siteId
                                                                        ? `/sites/${contextSiteId}`
                                                                        : ''
                                                                    }/boxDetail/${b.id}`}
                                                            >
                                                                {b.site} - {b.number}
                                                            </Link>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </td>
                                        <td>
                                            <EnrollBiometricsButton
                                                user={c}
                                                onSetUserIdToEnroll={(userId) => {
                                                    setCustomerToEnroll(
                                                        customers.find(it => it.id === userId)
                                                    );
                                                }}
                                                disabled={!organizationSelected}
                                            />
                                        </td>
                                        <td>
                                            {siteId && (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => {
                                                        setCustomerToEdit(c);
                                                        setNewCustomerFormIsVisible(true);
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => setCustomerToDelete(c)}
                                                disabled={!organizationSelected}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </StandardTable>
                    )}
                </>
            ) : (
                <CustomerForm
                    close={() => {
                        setNewCustomerFormIsVisible(false);
                    }}
                    callback={customerToEdit ? onCustomerEdited : onCustomerCreated}
                    orgId={orgId}
                    siteId={siteId}
                    dealerId={dealerId}
                    customer={customerToEdit}
                />
            )}

            <EnrollBiometricsDialog
                key={`${customerToEnroll}`}
                user={customerToEnroll}
                orgId={orgId}
                siteId={siteId}
                onClose={() => setCustomerToEnroll(undefined)}
            />

            {customerToDelete && (
                <DeleteCustomerDialog
                    customer={customerToDelete}
                    orgId={orgId}
                    onSuccess={(id: number) => setCustomers(customers.filter(c => c.id !== id))}
                    onClose={() => setCustomerToDelete(undefined)}
                />
            )}
        </>
    );
};

const useStyles = makeStyles(() => ({
    boxesList: {
        listStyleType: 'none',
        padding: 0,
        '& li': {
            marginBottom: '5px',
        },
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

