import React, { useState } from 'react';
import * as material from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { AjaxResult } from '../../../enums/ajaxResult';
import { useApi } from '../../useApi';

export const LeaseForm = ({
    leaseFormIsVisible,
    setLeaseFormIsVisible,
    selectedCustomerId,
    boxId,
    fetchBoxData,
    formCloseCallback
}: {
    leaseFormIsVisible: boolean;
    setLeaseFormIsVisible: (val: boolean) => void;
    selectedCustomerId: number | null;
    fetchBoxData: () => void;
    boxId: number;
        formCloseCallback: () => void;
}) => {
    const { leaseApi } = useApi();
    const [leaseStartDate, setLeaseStartDate] = useState<Moment | null>(
        moment().subtract(1, 'day')
    );
    const [leaseEndDate, setLeaseEndDate] = useState<Moment | null>(null);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const addLease = () => {
        if (selectedCustomerId && leaseStartDate) {
            setSubmitDisabled(true);
            leaseApi
                .postOne({
                    boxId,
                    customerIds: [selectedCustomerId],
                    endDate: leaseEndDate && leaseEndDate.toISOString(),
                    startDate: leaseStartDate.toISOString()
                })
                .then(r => {
                    if (r.result === AjaxResult.Success) {
                        setLeaseFormIsVisible(false);
                        fetchBoxData();
                    } else {
                        alert('ERROR: Creating a new lease for this box failed.');
                    }
                    setSubmitDisabled(false);
                    formCloseCallback();
                });
        }
    };
    return (
        <material.Dialog open={leaseFormIsVisible} onClose={() => { setLeaseFormIsVisible(false); formCloseCallback(); }}>
            <material.DialogTitle>New Lease</material.DialogTitle>
            <material.DialogContent>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <DatePicker
                        label="Start Date"
                        value={leaseStartDate}
                        variant="dialog"
                        onChange={date => setLeaseStartDate(date)}
                        format="YYYY/MM/DD"
                    ></DatePicker>
                    <DatePicker
                        label="End Date"
                        value={leaseEndDate}
                        variant="dialog"
                        onChange={date => setLeaseEndDate(date)}
                        format="YYYY/MM/DD"
                    />
                    <material.Button disabled={submitDisabled} onClick={addLease}>Submit</material.Button>
                </form>
            </material.DialogContent>
        </material.Dialog>
    );
};
